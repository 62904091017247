import { alpha, whiteBase } from '@mentimeter/ragnar-colors';
import { Box } from '@mentimeter/ragnar-ui/box';
import { PinIcon } from './PinIcon';

export interface CoordinatesT {
  x: number;
  y: number;
}

const MARKER = {
  ANIMATION_CURVE: 'cubic-bezier(.25, .5, .25, 1)',
  ANIMATION_DURATION: 300,
  SIZE: 42,
  ICON_SIZE: 34,
};

interface PinProps {
  isDragging: boolean;
  isMobile: boolean;
  imageRect: DOMRect | null;
  coordinates: CoordinatesT;
}

export const Pin = ({
  isDragging,
  isMobile,
  imageRect,
  coordinates,
}: PinProps) => {
  return (
    <Box
      data-testid="poi-pin"
      height={MARKER.SIZE}
      width={MARKER.SIZE}
      borderRadius="full"
      pt="space2"
      pb="space1.5"
      px="space2"
      top={-MARKER.ICON_SIZE}
      right={-MARKER.SIZE / 2}
      position="absolute"
      alignItems="center"
      justifyContent="center"
      zIndex={3}
      extend={() => ({
        transformOrigin: 'center',
        backgroundColor: isDragging ? alpha(whiteBase, 0.5) : 'none',
        transition: isDragging
          ? 'none'
          : `all ${MARKER.ANIMATION_DURATION}ms ${MARKER.ANIMATION_CURVE}`,
        transform: `${
          imageRect
            ? `translate(${
                coordinates.x * imageRect.width -
                MARKER.SIZE / 2 -
                (imageRect.width - MARKER.SIZE / 2)
              }px, ${coordinates.y * imageRect.height}px)`
            : ''
        }${isDragging ? ` scale(${isMobile ? 2 : 1.25})` : ''}`,
        pointerEvents: 'none',
        userSelect: 'none',
        '& > *': {
          transition: `opacity ${MARKER.ANIMATION_DURATION}ms ${MARKER.ANIMATION_CURVE}`,
        },
      })}
    >
      <PinIcon size={MARKER.ICON_SIZE} />
    </Box>
  );
};
