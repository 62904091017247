import React from 'react';
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import { hasEmoji } from '@mentimeter/emojis';
import {
  VotingConfirmationModal,
  type VotingConfirmationModalProps,
} from '@mentimeter/voting-ui';
import type { QuestionWithSlide } from '@mentimeter/voting-schema/api-types-overrides';
import type { SubmitVoteBody } from '@mentimeter/question-modules-types';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Form } from '@mentimeter/ragnar-ui/form';
import { Box } from '@mentimeter/ragnar-ui/box';
import {
  SubmitVoteFormButton,
  SkipButton,
  SubmitButton,
} from '../../../ui/Components/SubmitVoteFormButton';
import { Option } from './Option';
import { WaitingView } from './WaitingView';
import { MAX_LENGTH, filterEmojisAndSpecialChars } from './helpers';

const VOTE_CODE = 'voteCode';
const NO_VOTE = 'noVote';

type VoteError = typeof VOTE_CODE | typeof NO_VOTE | '';

export function getModalSettings({
  hasVoting,
  voteError,
  setWaitingState,
  setVoteError,
  skip,
  translate,
}: {
  hasVoting?: boolean;
  voteError: VoteError;
  skip: () => void;
  setWaitingState: (waitingState: boolean) => void;
  setVoteError: (error: VoteError) => void;
  translate: (key: string) => string;
}): Omit<VotingConfirmationModalProps, 'showModal'> {
  const dismissErrors = () => {
    setVoteError('');
  };
  if (voteError === VOTE_CODE) {
    return {
      id: 'vote-modal-open-vote-code',
      title: translate('messages.already_joined_presentation'),
      confirmButtonText: translate('buttons.ok'),
      onConfirm: dismissErrors,
      onDismiss: dismissErrors,
    };
  }

  const handleSkip = hasVoting
    ? () => {
        dismissErrors();
        setWaitingState(true);
      }
    : skip;

  return {
    id: 'vote-modal-open-skip',
    title: translate('messages.no_input'),
    confirmButtonText: translate('buttons.skip'),
    dismissButtonText: translate('buttons.cancel'),
    onDismiss: dismissErrors,
    onConfirm: handleSkip,
  };
}

function createSubmitVoteBody(
  question: QuestionWithSlide,
  rawVote: string,
  partial: boolean,
): SubmitVoteBody {
  if (question.isMigrated) {
    const firstICChoiceId =
      question.interactiveContents[0]!.choices[0]!.interactiveContentChoiceId;

    return {
      isMigrated: true,
      slidePublicKey: question.slidePublicKey,
      interactiveContentId:
        question.interactiveContents[0]!.interactiveContentId,
      payload: {
        type: 'open-ended',
        choices: [
          {
            interactiveContentChoiceId: firstICChoiceId,
            value: rawVote,
          },
        ],
      },
      partial,
    };
  }
  return {
    isMigrated: false,
    questionPublicKey: question.public_key,
    payload: { type: 'open', vote: rawVote },
    partial,
  };
}

export function FormView({
  hasVoting = false,
  hasResetVoting,
  disableResetVoting,
}: {
  hasVoting?: boolean;
  hasResetVoting: boolean;
  disableResetVoting: () => void;
}) {
  const { useQuestion, useActions, useTranslate, usePresentation } =
    useVotingContext();
  const { skip: skipVote, vote: submitVote, error, isLoading } = useActions();
  const question = useQuestion();
  const translate = useTranslate();
  const { emojiFilterEnabled, voteId } = usePresentation();

  const [waitingState, setWaitingState] = React.useState(false);
  const [voteError, setVoteError] = React.useState<VoteError>('');

  const showModal = Boolean(voteError);
  const { multiple_votes } = question;

  const [feedback, setFeedback] = React.useState({
    submitFeedback: false,
    emojiFilterWarning: false,
    voteAgain: false,
  });
  const [vote, setVote] = React.useState('');
  const voteIsEmpty = vote.replace(/\s/g, '').length === 0;

  const modalSettings = getModalSettings({
    hasVoting,
    voteError,
    setWaitingState,
    setVoteError,
    skip: skipVote,
    translate,
  });

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const element = e.target;
    const value = element.value;
    setVote(emojiFilterEnabled ? filterEmojisAndSpecialChars(value) : value);
    setFeedback((prev) => ({
      ...prev,
      submitFeedback: false,
      emojiFilterWarning: emojiFilterEnabled && hasEmoji(value),
    }));
  };

  const onSubmit = async () => {
    if (!showModal && voteIsEmpty) {
      setVoteError(NO_VOTE);
      return;
    }
    const value = vote.trim();
    if (value.length === 0) return;

    // User entered a vote id
    if (voteId && value.replace(/\s/g, '') === voteId) {
      setVoteError(VOTE_CODE);
      setVote('');
      return;
    }

    const res = await submitVote(
      createSubmitVoteBody(
        question,
        value,
        Boolean(multiple_votes) || hasVoting,
      ),
    );
    if (res.type === 'submitVote/rejected') {
      return;
    }

    if (multiple_votes) {
      setVote('');
      setFeedback((prev) => {
        return {
          ...prev,
          submitFeedback: true,
          voteAgain: true,
        };
      });
    } else if (hasVoting) {
      setWaitingState(true);
    }
  };

  React.useEffect(() => {
    if (hasResetVoting) {
      setWaitingState(false);
      setVote('');
    }
    disableResetVoting();
  }, [disableResetVoting, hasResetVoting]);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
      width="100%"
    >
      {waitingState ? (
        <WaitingView>{translate('open_ended.waiting_to_vote')}</WaitingView>
      ) : (
        <>
          <Option
            value={vote}
            onChange={onChange}
            maxLength={MAX_LENGTH}
            multipleVotes={Boolean(multiple_votes)}
            voteAgain={feedback.voteAgain}
            submitFeedback={feedback.submitFeedback}
            emojiFilterWarning={feedback.emojiFilterWarning}
          />
          {hasVoting ? (
            <Box
              width="100%"
              height="100%"
              mt="space6"
              flex="1 1 auto"
              flexDirection="column"
              alignItems="center"
            >
              {error === 'duplicated' && !multiple_votes ? (
                <Box width="100%">
                  <Text>{translate('messages.already_responded')}</Text>
                </Box>
              ) : (
                <SubmitButton
                  disabled={!question.active || isLoading}
                  state={isLoading ? 'loading' : undefined}
                >
                  {translate('buttons.submit')}
                </SubmitButton>
              )}
              {(feedback.voteAgain || error === 'duplicated') && (
                <SkipButton
                  onClick={() => setWaitingState(true)}
                  label={translate('buttons.next_step')}
                />
              )}
            </Box>
          ) : (
            <SubmitVoteFormButton
              allowMultipleVotes={Boolean(multiple_votes)}
              voteAgain={feedback.voteAgain}
            />
          )}
        </>
      )}
      <VotingConfirmationModal showModal={showModal} {...modalSettings} />
    </Form>
  );
}
