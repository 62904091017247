import * as React from 'react';
import { CalloutCard } from '@mentimeter/ragnar-ui/callout-card';
import { useTranslate } from '../localization/Translate';

export const CloseVotingNotification = () => {
  const translate = useTranslate();
  return (
    <CalloutCard
      theme="neutral"
      mb="space4"
      title={translate('messages.responding_is_turned_off')}
      description={translate('messages.wait_for_open')}
      compact
    />
  );
};
