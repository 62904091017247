import { Fragment, useCallback, useState } from 'react';
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import type { QuestionChoice } from '@mentimeter/http-clients';
import { VotingConfirmationModal } from '@mentimeter/voting-ui';
import type { SubmitVoteBody } from '@mentimeter/question-modules-types';
import type { QuestionWithSlide } from '@mentimeter/voting-schema/api-types-overrides';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Form } from '@mentimeter/ragnar-ui/form';
import { SubmitVoteFormButton } from '../../ui/Components/SubmitVoteFormButton';
import { Option } from './Option';
import type { Axis } from './types';

type VoteValue = Record<Axis, number>;

interface ValidatedVote {
  skipped: boolean;
  value: VoteValue;
}

type ValidatedVotes = Record<QuestionChoice['id'], ValidatedVote>;
type ResponseValues = [number | '', number | ''];

function createSubmitVoteBody(
  question: QuestionWithSlide,
  rawVotes: ValidatedVotes,
): SubmitVoteBody {
  if (question.isMigrated) {
    const interactiveContentChoiceIds = Object.entries(rawVotes).map(
      ([rawVoteChoiceId, rating]) => {
        const choice = question.interactiveContents[0]!.choices.find(
          (c) => c.legacyChoiceId?.toString() === rawVoteChoiceId,
        );
        return {
          interactiveContentChoiceId: choice!.interactiveContentChoiceId,
          value: rating.skipped
            ? ('skipped' as const)
            : ([rating.value.x, rating.value.y] satisfies [number, number]),
        };
      },
    );

    return {
      isMigrated: true,
      slidePublicKey: question.slidePublicKey,
      interactiveContentId:
        question.interactiveContents[0]!.interactiveContentId,
      payload: { type: 'rating', choices: interactiveContentChoiceIds },
      partial: false,
    };
  }

  const actualVotes: Record<QuestionChoice['id'], ResponseValues> = {};
  question.choices.map((c) => {
    const x = rawVotes[c.id]?.value?.x ?? '';
    const y = rawVotes[c.id]?.value?.y ?? '';
    const value = (
      rawVotes[c.id]?.skipped ? ['', ''] : [x, y]
    ) satisfies ResponseValues;
    actualVotes[c.id] = value;
  });

  return {
    isMigrated: false,
    questionPublicKey: question.public_key,
    payload: { type: 'rating', vote: actualVotes },
    partial: false,
  };
}

export function Interactive() {
  const { useQuestion, useActions, useTranslate, useTheme } =
    useVotingContext();
  const { skip: skipVote, vote: submitVote } = useActions();
  const theme = useTheme();
  const question = useQuestion();
  const translate = useTranslate();
  const { choices, dimensions, range, hide_skip: hideSkip } = question;
  const [values, setValues] = useState<ValidatedVotes>({});
  const [shouldShowConfirmSubmitModal, setShouldShowConfirmSubmitModal] =
    useState(false);

  const handleSubmit = useCallback(
    (votes: ValidatedVotes) => {
      // Edge case: voting on a question without choices
      if (question.choices.length === 0) {
        skipVote();
        return;
      }
      submitVote(createSubmitVoteBody(question, votes));
    },
    [question, skipVote, submitVote],
  );

  function isValidVote(voteValue: number | undefined) {
    if (voteValue === undefined) {
      return false;
    }
    return voteValue >= range.min && voteValue <= range.max;
  }

  function getVoteValueIfValid(
    voteValue: VoteValue | undefined,
  ): VoteValue | undefined {
    if (isValidVote(voteValue?.x) && isValidVote(voteValue?.y)) {
      return voteValue;
    }
    return undefined;
  }

  // The validated vote that is updated to number of choices and within range
  const votes = choices.reduce<ValidatedVotes>((prev, c) => {
    const newValue = getVoteValueIfValid(values[c.id]?.value) ?? {
      x: range.min,
      y: range.min,
    };
    return {
      ...prev,
      [c.id]: {
        value: newValue,
        skipped: values[c.id]?.skipped || false,
      },
    };
  }, {});

  function onChange(id: number, input: string, axis: Axis = 'x') {
    const currentValue = getVoteValueIfValid(votes[id]?.value);
    if (!currentValue) {
      return;
    }
    const newValue = { ...currentValue, [axis]: parseInt(input, 10) };
    const validatedNewValue = getVoteValueIfValid(newValue);
    if (validatedNewValue) {
      setValues((state) => ({
        ...state,
        [id]: { value: validatedNewValue, skipped: false },
      }));
    }
  }

  function onChangeOptionSkip(id: number) {
    setValues((state) => ({
      ...state,
      [id]: {
        value: votes[id]!.value,
        skipped: !votes[id]!.skipped,
      },
    }));
  }

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const isEmpty = Object.keys(values).length === 0;
      if (isEmpty) {
        setShouldShowConfirmSubmitModal(true);
      } else {
        handleSubmit(votes);
      }
    },
    [values, handleSubmit, votes],
  );

  return (
    <Form onSubmit={onSubmit} width="100%">
      <Box width="100%" mb={3}>
        {choices.map((c, index) => {
          return (
            <Fragment key={c.id}>
              <Option
                index={index}
                id={c.id}
                value={votes[c.id]?.value ?? { x: range.min, y: range.min }}
                subType={question.sub_type}
                label={c.label}
                labels={dimensions}
                min={range.min}
                max={range.max}
                onChange={onChange}
                onSkip={onChangeOptionSkip}
                skippable={!hideSkip}
                skipped={votes[c.id]?.skipped ?? false}
                color={
                  theme.fillColors[index % theme.fillColors.length] ??
                  theme.textColor
                }
              />
            </Fragment>
          );
        })}
      </Box>
      <SubmitVoteFormButton />
      <VotingConfirmationModal
        id="submit-vote-modal-rating"
        showModal={shouldShowConfirmSubmitModal}
        onConfirm={() => handleSubmit(votes)}
        onDismiss={() => setShouldShowConfirmSubmitModal(false)}
        title={translate('scales.confirmation_modal_title')}
        confirmButtonText={translate('buttons.ok')}
        dismissButtonText={translate('buttons.cancel')}
      />
    </Form>
  );
}
