/* eslint-disable menti-react/filename-convention--jsx */
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import { useRagnar } from '@mentimeter/ragnar-react';
import { ArrowRight } from '@mentimeter/ragnar-visuals';
import { Text } from '@mentimeter/ragnar-ui/text';
import { TitleSectionWithImage } from '../../ui/Components/VotingTitle';

export function Content() {
  const { useTranslate, usePresentation, useQuestion } = useVotingContext();
  const translate = useTranslate();
  const { theme } = useRagnar();
  const { isAudiencePace } = usePresentation();
  const question = useQuestion();

  return (
    <>
      <ArrowRight userColor={theme.colors.positive} />
      {isAudiencePace ? (
        <TitleSectionWithImage
          title={question.question}
          titleStyled={question.question_styled}
        />
      ) : (
        <>
          <Text fontSize="175" mt="space6" mb="space3">
            {translate('instructions.title')}
          </Text>
          <Text fontSize="100"> {translate('instructions.subtitle')}</Text>
        </>
      )}
    </>
  );
}
