import { fromSnakeKeys } from '@mentimeter/migration-utils/snake-keys';
import {
  voting,
  type VotingSeriesWithSlideDeck,
} from '@mentimeter/http-clients';
import { getRegionByVoteKey } from '@mentimeter/region';

export async function getSlideDeckByKey(
  key: string,
): Promise<VotingSeriesWithSlideDeck> {
  const region = getRegionByVoteKey(key);

  const { data } = await voting({ region }).slideDeck.getByKey(key);
  const series = data.series;
  const dataWithCamelKeys = fromSnakeKeys(data);
  return {
    series,
    slideDeck: dataWithCamelKeys.slideDeck,
  };
}
