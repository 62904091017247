import type { Question } from '@mentimeter/http-clients';
import { Button } from '@mentimeter/ragnar-ui/button';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalRoot,
} from '@mentimeter/ragnar-ui/modal';
import { Text } from '@mentimeter/ragnar-ui/text';
import { useTranslate } from '../localization/Translate';
import { useAppDispatch } from '../../redux-hooks';
import { skipQuestion } from '../../reducers/votingSlice';

interface Props {
  question: Question;
}

/*
 *   Modal solution to handle "inline" message of this question is
 *   not open for voting. Handled a bit differently than WaitForOpen
 *   component, as WaitForOpen component has a very special use case
 *   and this component is for a regular not open question
 */
export const WaitForOpenModal = ({ question }: Props) => {
  const dispatch = useAppDispatch();
  const translate = useTranslate();

  function handleSkip() {
    dispatch(skipQuestion());
  }

  const isVotingOpen = question.type === 'slide' || question.active;

  return (
    <ModalRoot open={!isVotingOpen}>
      <ModalContainer dismissable={false}>
        <ModalHeader dismissable={false}>
          {translate('question.not_open')}
        </ModalHeader>
        <ModalBody>
          <Text color="textWeak">{translate('question.please_wait_open')}</Text>
        </ModalBody>
        <ModalFooter>
          <Button size="large" onClick={handleSkip} variant="primary">
            {translate('buttons.next_question')}
          </Button>
        </ModalFooter>
      </ModalContainer>
    </ModalRoot>
  );
};
