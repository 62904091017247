/* eslint-disable menti-react/filename-convention--jsx */
import { type FormEvent, useState } from 'react';
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import { VotingConfirmationModal } from '@mentimeter/voting-ui';
import type { QuestionWithSlide } from '@mentimeter/voting-schema/api-types-overrides';
import type { SubmitVoteBody } from '@mentimeter/question-modules-types';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Form } from '@mentimeter/ragnar-ui/form';
import { ScreenReaderOnly } from '@mentimeter/ragnar-ui/screenreader-only';
import { SubmitVoteFormButton } from '../../ui/Components/SubmitVoteFormButton';
import { TitleSectionWithImage } from '../../ui/Components/VotingTitle';
import { PinableImage } from './PinableImage';

interface PinVoteT {
  x_percentage: number;
  y_percentage: number;
}

export function Content() {
  const { useQuestion } = useVotingContext();
  const question = useQuestion();
  return (
    <TitleSectionWithImage
      meta={question.title_meta}
      title={question.question}
      description={question.question_description}
      titleStyled={question.question_styled}
    />
  );
}

function createSubmitVoteBody(
  question: QuestionWithSlide,
  rawVote: PinVoteT,
): SubmitVoteBody {
  if (question.isMigrated) {
    const firstICChoiceId =
      question.interactiveContents[0]!.choices[0]!.interactiveContentChoiceId;

    return {
      isMigrated: true,
      slidePublicKey: question.slidePublicKey,
      interactiveContentId:
        question.interactiveContents[0]!.interactiveContentId,
      payload: {
        type: 'pin-on-image',
        choices: [
          {
            interactiveContentChoiceId: firstICChoiceId,
            value: { x: rawVote.x_percentage, y: rawVote.y_percentage },
          },
        ],
      },
      partial: false,
    };
  }
  return {
    isMigrated: false,
    questionPublicKey: question.public_key,
    payload: { type: 'pin_on_image', vote: rawVote },
    partial: false,
  };
}

export function Interactive() {
  const { useQuestion, useActions, useTranslate } = useVotingContext();
  const { vote: submitVote, skip: skipVote } = useActions();
  const translate = useTranslate();
  const question = useQuestion();

  const [vote, setVote] = useState<PinVoteT | null>(null);
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (!vote) {
      setShowModal(true);
      return;
    }

    submitVote(createSubmitVoteBody(question, vote));
  };

  return (
    <Form onSubmit={handleSubmit} width="100%">
      <ScreenReaderOnly>
        {translate('pin_on_image.keyboard_instructions')}
      </ScreenReaderOnly>
      <Box width="100%" alignItems="stretch" mb={3}>
        {question.image && (
          <Box width="100%" mt={2}>
            <PinableImage
              imageUrl={question.image?.presets?.large?.url}
              onImagePin={(coordinates) =>
                setVote({
                  x_percentage: coordinates.x,
                  y_percentage: coordinates.y,
                })
              }
            />
          </Box>
        )}

        <SubmitVoteFormButton />

        <VotingConfirmationModal
          id="skip-vote-modal-pin-on-image"
          showModal={showModal}
          onConfirm={skipVote}
          onDismiss={() => setShowModal(false)}
          title={translate('messages.not_made_choice')}
          bodyText={translate('messages.continue_without_voting')}
          confirmButtonText={translate('buttons.ok')}
          dismissButtonText={translate('buttons.cancel')}
        />
      </Box>
    </Form>
  );
}
