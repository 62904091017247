import * as React from 'react';
import { useSpring, animated } from '@react-spring/web';
import { withTheme, type ThemeProps } from '@mentimeter/ragnar-react';
import { designSystemConfig, isThemeColor } from '@mentimeter/ragnar-dsc';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';

const BAR_WIDTH = 350;
const BAR_HEIGHT = 8;
const SLOPED_BAR_HEIGHT = 20;
const SLOPED_BAR_MIN_HEIGHT = 1;

const MIN_SCORE = 500;
const MAX_SCORE = 1000;

export const ProgressBar = ({
  startAt,
  endAt,
  isTimeBasedScoring,
}: {
  startAt: number;
  endAt: number;
  isTimeBasedScoring: boolean;
}) => {
  const progress = (Date.now() - startAt) / (endAt - startAt);

  const startHeight = isTimeBasedScoring ? SLOPED_BAR_HEIGHT : BAR_HEIGHT;
  const endHeight = isTimeBasedScoring ? SLOPED_BAR_MIN_HEIGHT : BAR_HEIGHT;
  const barHeight = Math.max(startHeight, endHeight);

  const startPoints = getBarPoints({ progress, startHeight, endHeight });
  const endPoints = getBarPoints({ progress: 1, startHeight, endHeight });

  const [{ points }] = useSpring(() => ({
    config: { duration: endAt - Date.now() },
    from: { points: startPoints },
    to: { points: endPoints },
  }));

  return (
    <Box width="100%" alignItems="stretch">
      <Box width="100%" height={barHeight} overflow="hidden">
        <ProgressBarAsset
          barHeight={barHeight}
          endPoints={endPoints}
          points={points}
        />
      </Box>
      <Box
        flexDirection="row"
        justifyContent={isTimeBasedScoring ? 'space-between' : 'center'}
        mt="space2"
      >
        <Text
          fontSize="87.5"
          color="text"
          fontWeight="semiBold"
          aria-hidden="true"
        >
          {MAX_SCORE}p
        </Text>
        {isTimeBasedScoring && (
          <Text
            fontSize="87.5"
            color="text"
            fontWeight="semiBold"
            aria-hidden="true"
          >
            {MIN_SCORE}p
          </Text>
        )}
      </Box>
    </Box>
  );
};

const ProgressBarAsset = withTheme(ProgressBarUi);
interface ProgressBarUiProps extends ThemeProps {
  barHeight?: number;
  endPoints?: string;
  points?: any;
}

function ProgressBarUi({
  points,
  endPoints,
  barHeight,
  theme = designSystemConfig,
}: ProgressBarUiProps) {
  const animatedColor = 'secondary';
  const baseColor = 'neutral';
  return (
    <svg
      viewBox={`0 0 ${BAR_WIDTH} ${barHeight}`}
      preserveAspectRatio="none"
      width="100%"
      height="100%"
      style={{
        overflow: 'hidden',
      }}
    >
      <polygon
        points={endPoints}
        fill={
          isThemeColor(theme, baseColor) ? theme.colors[baseColor] : baseColor
        }
      />
      <animated.polygon
        points={points}
        fill={
          isThemeColor(theme, animatedColor)
            ? theme.colors[animatedColor]
            : animatedColor
        }
      />
    </svg>
  );
}

function getBarPoints({
  progress,
  startHeight,
  endHeight,
}: {
  progress: number;
  startHeight: number;
  endHeight: number;
}) {
  // Y coordinates are flipped, so 0 means it's at the top

  const topLeft = '0,0';
  const topRight = `${BAR_WIDTH * progress},${
    progress * (startHeight - endHeight)
  }`;
  const bottomRight = `${BAR_WIDTH * progress},${startHeight}`;
  const bottomLeft = `0,${startHeight}`;

  return `${topLeft} ${topRight} ${bottomRight} ${bottomLeft}`;
}
