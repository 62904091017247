import type { VotingSeries } from '@mentimeter/http-clients';
import type { SlideDeck as VotingSlideDeck } from '@mentimeter/voting-schema/api-types-overrides';
import { InvalidProxySetError } from '../../../utils/invalid-proxy-set-error';
import { getUnmigratedVotingSlideDeckProperty } from '../forward-compatibility/get-unmigrated-voting-slidedeck-property';

type BaseVotingSeriesCompatibilityLayerHandlerTarget =
  | VotingSeries
  | VotingSlideDeck;

export const getSeriesCompatibilityHandler = <
  TTarget extends BaseVotingSeriesCompatibilityLayerHandlerTarget,
>({
  getMigratedSeriesProperty,
}: {
  getMigratedSeriesProperty: (
    target: TTarget,
    key: keyof VotingSeries | 'slides', // Slides is nested property which needs to be manually wrapped in a proxy
  ) => any;
}): ProxyHandler<TTarget> => ({
  get(
    target: TTarget,
    key:
      | keyof BaseVotingSeriesCompatibilityLayerHandlerTarget
      | '__isProxy'
      | '__target'
      | 'isMigrated',
  ) {
    if (key === '__isProxy') {
      return true;
    }

    if ((key as any) === '__target') {
      return target;
    }

    const isMigratedSeries = Array.isArray((target as VotingSlideDeck)?.slides);
    if (key === 'isMigrated') {
      return isMigratedSeries;
    }

    if (isMigratedSeries) {
      return getMigratedSeriesProperty(
        target,
        key as Parameters<typeof getMigratedSeriesProperty>[1],
      );
    }

    return getUnmigratedVotingSlideDeckProperty(
      target as VotingSeries,
      key as Parameters<typeof getUnmigratedVotingSlideDeckProperty>[1],
    );
  },

  set(_: never, key: string) {
    throw new InvalidProxySetError(key, 'series');
  },
});
