import React, { type MouseEvent } from 'react';
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import type { OpenVotesT } from '@mentimeter/question-modules-types';
import { TrailList } from '@mentimeter/voting-ui';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';
import type { Upvotes } from './useUpvotes';
import { TabsHeader, type TabName } from './TabsHeader';
import { ResponseWithUpvoting } from './ResponseWithUpvoting';

interface ResponsesWrapperProps {
  onTabClick: (
    tabName: TabName,
  ) => (event: MouseEvent<HTMLButtonElement>) => void;
  currentTab: TabName;
  handleUpvote: (id: string, voteCount: number) => void;
  upvotes: Upvotes;
  hasMultipleUpvotes: boolean;
  responses: OpenVotesT[];
}

export const ResponsesWrapper = ({
  onTabClick,
  currentTab,
  handleUpvote,
  upvotes,
  hasMultipleUpvotes,
  responses,
}: ResponsesWrapperProps) => {
  const { useTranslate } = useVotingContext();
  const translate = useTranslate();
  const numberOfResponses = responses.length;

  const responsesWithUpvotes = responses.filter((item) =>
    Boolean(upvotes[item.id]),
  );

  const listContent = currentTab === 'all' ? responses : responsesWithUpvotes;

  const shouldShowMyVotesEmptyState =
    currentTab === 'my-votes' && listContent.length === 0;

  const numberOfResponsesWithUpvotes = responsesWithUpvotes.length;
  return (
    <>
      <TabsHeader
        onTabClick={onTabClick}
        labelAll={`${translate('open_ended.show_all')} (${numberOfResponses})`}
        labelMyVotes={`${translate('open_ended.user_voted')} (${numberOfResponsesWithUpvotes})`}
      />
      <TrailList
        items={listContent}
        renderItem={(response) => {
          return (
            <ResponseWithUpvoting
              item={response}
              onUpvote={handleUpvote}
              nrOfUpvotes={upvotes[response.id] ?? 0}
              canHaveMultipleUpvotes={hasMultipleUpvotes}
            />
          );
        }}
      />
      {shouldShowMyVotesEmptyState && (
        <Box alignItems="center" justifyContent="center" width="100%" mt={4}>
          <Text textAlign="center" py={3}>
            {translate('open_ended.your_ideas')}
          </Text>
        </Box>
      )}
    </>
  );
};
