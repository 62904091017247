import { captureException, MentiError } from '@mentimeter/errors/sentry';
import type { Question } from '@mentimeter/http-clients';
import type { StaticContentEntity as StaticContentSchema } from '@core-api/audience/types/response';
import type { Slide as VotingSlide } from '@mentimeter/voting-schema/api-types-overrides';
import { UncommonPropertyError } from '../../../../backward-compatibility/errors/uncommon-property-error';
import { isContentType } from '../../../../utils/is-content-type';
import {
  typeToModuleId,
  typeToQuestionType,
  typeToSlideType,
} from '../../../../backward-compatibility/converters/static-content';

export const COMMON_QUESTION_PROPERTIES = [
  'public_key',
  'title_meta',
  'question_styled',
  'question_description_styled',
  'skip_slide',
  'module_id',
  'type',
  'slide_type',
  'id',
] as const satisfies Array<keyof Question>;

type FreeTextStaticContent = Extract<
  StaticContentSchema,
  { type: 'free-text' }
>;

export type CommonQuestionProperty =
  (typeof COMMON_QUESTION_PROPERTIES)[number];

export const isCommonQuestionProperty = (
  prop: keyof Question,
): prop is CommonQuestionProperty => {
  return COMMON_QUESTION_PROPERTIES.includes(prop as CommonQuestionProperty);
};

export const getCommonQuestionProperty = (
  slide: VotingSlide,
  prop: CommonQuestionProperty,
) => {
  if (!isCommonQuestionProperty(prop)) {
    throw new UncommonPropertyError(prop);
  }

  const staticContents = slide.staticContent;

  if (staticContents) {
    switch (prop) {
      case 'id':
        return slide.slidePublicKey;
      case 'public_key':
        return slide.slidePublicKey;
      case 'title_meta':
        // @ts-expect-error: MX-634: title_meta is not part of the free-text slide
        return staticContents.meta;
      case 'skip_slide':
        // TODO: backend needs to filter out skipped slides
        return false;
      case 'question_styled':
        /**
         * TODO: Provide from `question.slide` when available.
         * @see https://linear.app/mentimeter/issue/MX-214/
         */
        // @ts-expect-error: FIXME: question_styled is not part of the slide
        return slide.question_styled ?? {};
      case 'question_description_styled':
        return isContentType<FreeTextStaticContent>(
          slide.staticContent,
          'free-text',
        )
          ? (slide.staticContent.styledBody ?? {}) // this is used only for free-text slide
          : {};
      case 'module_id': {
        return typeToModuleId(staticContents.type);
      }
      case 'type': {
        return typeToQuestionType(staticContents.type);
      }
      case 'slide_type': {
        return typeToSlideType(staticContents.type);
      }
      default:
        const error = new MentiError(
          `Unhandled common question property: \`${String(prop)}\``,
          {
            feature: 'compatibility-layer',
          },
        );
        captureException(error);

        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line no-console
          console.warn(error.message);
        }

        return slide[prop];
    }
  }
};
