import type { Question } from '@mentimeter/http-clients';
import type {
  Slide as VotingSlide,
  InteractiveContent as VotingInteractiveContent,
} from '@mentimeter/voting-schema/api-types-overrides';
import { NoInteractiveContentError } from '../../../../backward-compatibility/errors/no-interactive-content-error';
import {
  RESPONSE_POLICY_NO_RESTRICTION,
  RESPONSE_POLICY_SINGLE_RESPONSE_LIMITED_CHOICES,
} from '../../../../constants';
import type { CommonQuestionProperty } from './get-common-question-property';

export const getQuestionChoicesProperty = (
  target: VotingSlide,
  prop: keyof Question,
) => {
  const interactiveContent = target.interactiveContents[0];

  if (interactiveContent) {
    switch (prop as keyof Omit<Question, CommonQuestionProperty>) {
      case 'max_votes':
        return getMaxVotes(interactiveContent);
      default:
        return undefined;
    }
  }

  throw new NoInteractiveContentError(target.slidePublicKey);
};

const getMaxVotes = (interactiveContent: VotingInteractiveContent) => {
  switch (interactiveContent.responsePolicy) {
    case RESPONSE_POLICY_NO_RESTRICTION:
      return -1;
    case RESPONSE_POLICY_SINGLE_RESPONSE_LIMITED_CHOICES:
      return interactiveContent.maxEntries || -1;
    default:
      return 1;
  }
};
