import { useVotingContext } from '@mentimeter/question-modules-contexts';
import type { Player } from '@mentimeter/quiz';
import { getOrdinal } from '@mentimeter/quiz';
import { ClockIcon, LoaderIcon } from '@mentimeter/ragnar-visuals';
import React from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';
import { useLeaderboardDelay } from '../useLeaderboardDelay';
import { FadeIn } from '../ui/Transitions';
import { AvatarStatusBubble, AvatarWrapper } from '../ui/Avatar';
import { AnnouncedHeading } from '../ui/AnnouncedHeading';

export const Leaderboard = ({
  player,
  playerColor,
  points,
  position,
}: {
  player: Player | undefined;
  playerColor: string;
  points: number;
  position: number;
}) => {
  const { useTranslate } = useVotingContext();
  const translate = useTranslate();

  const ready = useLeaderboardDelay();
  const playerPositionOrdinal = getOrdinal(position, translate);

  return player ? (
    <>
      <AvatarWrapper player={player} playerColor={playerColor} />
      <FadeIn>
        {!ready && (
          <AvatarStatusBubble>
            <ClockIcon color="onNeutralWeak" size={4} />
          </AvatarStatusBubble>
        )}
      </FadeIn>
      <Box mt="space3" />
      {ready ? (
        <FadeIn>
          <Box alignItems="center">
            <AnnouncedHeading
              textAlign="center"
              fontSize="175"
              color="text"
              fontWeight="regular"
            >
              {translate('state.are_in_place', {
                playerPositionOrdinal,
              })}
            </AnnouncedHeading>
            <Text color="text" mt="space3">
              <Text fontWeight="bold" color="text" mr="space0.5">
                {points}
              </Text>
              {translate('state.points_total')}
            </Text>
          </Box>
        </FadeIn>
      ) : (
        <AnnouncedHeading
          fontSize="175"
          textAlign="center"
          color="text"
          fontWeight="regular"
        >
          {translate('state.loading_result')}
        </AnnouncedHeading>
      )}
    </>
  ) : (
    <LoaderIcon />
  );
};
