import type { PropsWithChildren } from 'react';
import type { TextT } from '@mentimeter/ragnar-ui/text';
import { Text } from '@mentimeter/ragnar-ui/text';

export const AnnouncedHeading = ({
  children,
  ...props
}: PropsWithChildren<TextT>) => {
  return (
    <Text as="h1" aria-live="polite" {...props}>
      {children}
    </Text>
  );
};
