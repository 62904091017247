import type { VotingSeries } from '@mentimeter/http-clients';
import type {
  SeriesWithSlideDeck as VotingSeriesWithSlideDeck,
  SlideDeck as VotingSlideDeck,
} from '@mentimeter/voting-schema/api-types-overrides';
import { getOrSetCache, type WithCache } from '../../../utils/get-or-set-cache';
import { isProxyInstance } from '../../../utils/is-proxy-instance';
import { createVotingSlideCompatibilityLayer } from '../slide-compatibility-layer/create-voting-slide-compatibility-layer';
import { votingSlideDeckPropertyToSeriesProperty } from '../backward-compatibility/voting-slide-deck-property-to-series-property';
import { getSeriesCompatibilityHandler } from './get-series-compatibility-handler';

export const createVotingSeriesCompatibilityLayer = (
  target: VotingSeries | VotingSlideDeck | undefined,
): VotingSeriesWithSlideDeck | undefined => {
  if (!target) {
    return undefined;
  }

  if (isProxyInstance(target)) {
    return target as VotingSeriesWithSlideDeck;
  }

  return new Proxy(
    target,
    getSeriesCompatibilityHandler<VotingSlideDeck>({
      getMigratedSeriesProperty: getMigratedVotingSeriesProperty,
    }),
  ) as VotingSeriesWithSlideDeck;
};

const getMigratedVotingSeriesProperty = (
  target: VotingSlideDeck,
  key: keyof VotingSeries | 'slides',
) => {
  switch (key) {
    case 'slides':
      const slides = target.slides.map((slide) => {
        return createVotingSlideCompatibilityLayer(slide);
      });
      const cacheKey = JSON.stringify(slides);
      return getOrSetCache(
        target as WithCache<VotingSlideDeck>,
        'slides',
        cacheKey,
        slides,
      );

    case 'questions': {
      const questions = target.slides.map((slide) => {
        return createVotingSlideCompatibilityLayer(slide);
      });

      const cacheKey = JSON.stringify(questions);
      return getOrSetCache(
        target as WithCache<VotingSlideDeck>,
        'questions',
        cacheKey,
        questions,
      );
    }

    default:
      return votingSlideDeckPropertyToSeriesProperty(target, key);
  }
};
