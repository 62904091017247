import React from 'react';
import {
  ActionCard,
  ActionCardText,
  ResponseCard,
} from '@mentimeter/voting-ui';
import { Text } from '@mentimeter/ragnar-ui/text';
import type { BoxT } from '@mentimeter/ragnar-ui/box';
import { Box } from '@mentimeter/ragnar-ui/box';

interface ResultCardProps extends Pick<BoxT, 'mb'> {
  id: string;
  text: string;
  nrOfUpvotes: number;
  label?: string | undefined;
}
export const ResponseWithResult = ({
  id,
  text,
  nrOfUpvotes,
  label,
  mb = 'space5',
}: ResultCardProps) => {
  const shouldShowNrOfUpvotes = nrOfUpvotes > 0;
  return (
    <ResponseCard id={id} hasVotes={nrOfUpvotes > 0} text={text} mb={mb}>
      <Box
        mt="space3"
        width="100%"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {shouldShowNrOfUpvotes && (
          <Text
            fontSize="150"
            fontWeight="regular"
            lineHeight="snug"
            color="onSecondaryWeak"
          >
            {nrOfUpvotes}
          </Text>
        )}
        {label && (
          <ActionCard>
            <ActionCardText>{label}</ActionCardText>
          </ActionCard>
        )}
      </Box>
    </ResponseCard>
  );
};
