import type { PropsWithChildren } from 'react';
import { useAuthenticationStore } from './authenticationStore';
import { JoinPresentationConfirmation } from './JoinPresentationConfirmation';

export const AuthenticationContainer = ({ children }: PropsWithChildren) => {
  const { authenticationNeeded, redirectUrl, identityMode } =
    useAuthenticationStore();

  if (authenticationNeeded && redirectUrl) {
    return (
      <JoinPresentationConfirmation
        redirectUrl={redirectUrl}
        identityMode={identityMode}
      />
    );
  }

  return children;
};
