import type { Slide as VotingSlide } from '@mentimeter/voting-schema/api-types-overrides';

const votingSlideObject = {
  slidePublicKey: null,
  staticContent: null,
  interactiveContents: null,
  images: null,
  design: null,
  isMigrated: null,
  title: null,
} satisfies Record<keyof VotingSlide, null>;

export const allVotingSlideKeys = Object.keys(votingSlideObject) as Array<
  keyof VotingSlide
>;
