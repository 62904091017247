/* eslint-disable menti-react/filename-convention--jsx */
import { Text } from '@mentimeter/ragnar-ui/text';
import { Box } from '@mentimeter/ragnar-ui/box';

export function Content() {
  return (
    <Box width="100%" alignItems="center">
      <Text
        color="text"
        fontSize={4}
        fontWeight="semiBold"
        mb={2}
        textAlign="center"
      >
        Oops! It looks like this slide type is no longer available.
      </Text>
      <Text color="text" fontSize={3} mb={3} textAlign="center">
        Contact the owner of this presentation for more information.
      </Text>
    </Box>
  );
}
