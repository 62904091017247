import * as React from 'react';
import { Button } from '@mentimeter/ragnar-ui/button';
import { useTranslate } from '../localization/Translate';

export const CfaButton = ({ onClick }: { onClick: () => void }) => {
  const translate = useTranslate();

  return (
    <Button size="large" onClick={onClick} width="100%">
      {translate('buttons.send_comment')}
    </Button>
  );
};
