import * as React from 'react';
import { CrossIcon } from '@mentimeter/ragnar-visuals';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { Box } from '../box';
import { IconButton } from '../icon-button';
import { DrawerTitle } from './DrawerTitle';
import { DrawerDismiss } from './DrawerDismiss';

interface DrawerHeaderProps {
  className?: 'string';
  dismissable?: boolean;
  dismissLabel?: string;
  onDismissClick?: () => void;
  children: React.ReactNode;
}

export const DrawerHeader = ({
  dismissable = true,
  dismissLabel,
  onDismissClick,
  children,
  className,
}: DrawerHeaderProps) => {
  const closeLabel = dismissLabel ? dismissLabel : 'Close drawer';

  return (
    <Box
      className={clsx(
        [
          'w-full',
          'flex',
          'flex-row',
          'justify-between',
          'items-baseline',
          'gap-2',
          'px-8',
          'pb-1',
          dismissable ? 'pt-12' : 'pt-8',
        ],
        className,
      )}
    >
      <DrawerTitle>{children}</DrawerTitle>
      {dismissable && (
        <DrawerDismiss className="absolute top-1.5 right-1.5">
          <IconButton
            variant="secondary"
            aria-label={closeLabel}
            onClick={onDismissClick}
            borderRadius="full"
          >
            <CrossIcon aria-hidden />
          </IconButton>
        </DrawerDismiss>
      )}
    </Box>
  );
};
