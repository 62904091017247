import * as React from 'react';
import type Ably from 'ably';
import throttle from 'lodash/throttle';
import { usePresentationRealtimeStore } from '@mentimeter/realtime';
import { useAppDispatch, useAppSelector } from '../../redux-hooks';
import { qfaIntercomEnabledSelector, voteKeySelector } from '../../selectors';
import { fetchAudienceQuestions, updateUpvotes } from './qfaSlice';

/**
 * Subscribes you to the `qfa_updated` websocket event
 */
export function useQfaUpdates() {
  const dispatch = useAppDispatch();
  const client = usePresentationRealtimeStore((state) => state.client);

  const voteKey = useAppSelector(voteKeySelector);
  const { shouldDoLiveUpdate, sortByUpvote } = useAppSelector(
    (state) => state.qfa,
  );
  const qfaIntercomEnabled = useAppSelector(qfaIntercomEnabledSelector);

  /** When a user posts a question to QFA all clients does a GET request to
   * retrieve the latest data. To avoid a stampede effect we'll randomize
   * the time a client waits before fetching the data and only allow clients
   * to fetch it once every 2.5 seconds (throttled). */
  const loadAudienceQuestionsThrottled = React.useMemo(() => {
    const delayedFn = () =>
      setTimeout(
        () => dispatch(fetchAudienceQuestions(voteKey)),
        // Random delay to prevent everyone from requesting at the same time
        100 + 1900 * Math.random(),
      );

    return throttle(delayedFn, 2500, {
      // Fetch is invoked on the trailing edge of the timeout only if it is invoked
      // more than once during the wait timeout.
      leading: true,
      trailing: true,
    });
  }, [dispatch, voteKey]);

  React.useEffect(() => {
    function messageHandler(message: Ably.Message) {
      const { payload } = message.data;

      if (!qfaIntercomEnabled || !shouldDoLiveUpdate) return;

      // If an upvote change and we are in recent => update only upvote
      if ('upvotes' in payload && !sortByUpvote) {
        dispatch(
          updateUpvotes({
            id: parseInt(payload.qfa_data_id, 10),
            upvotes: payload.upvotes,
          }),
        );
      } else {
        loadAudienceQuestionsThrottled();
      }
    }

    if (!client) {
      return;
    }

    client.channels
      .get(`vote_keys_${voteKey}`)
      .subscribe('qfa_updated', messageHandler)
      .catch(() => {
        // Previously we crashed here. Should we act on subscription setup failure?
      });
    return () => {
      client.channels
        .get(`vote_keys_${voteKey}`)
        .unsubscribe('qfa_updated', messageHandler);
    };
  }, [
    client,
    dispatch,
    loadAudienceQuestionsThrottled,
    qfaIntercomEnabled,
    shouldDoLiveUpdate,
    sortByUpvote,
    voteKey,
  ]);

  return null;
}
