import { Form, TextInput, Button } from '@mentimeter/ragnar-form';
import { type InferType, object, string } from 'yup';
import * as React from 'react';
import { useState } from 'react';
import { hasEmoji } from '@mentimeter/emojis';
import { isAxiosError } from '@mentimeter/http-clients';
import { Text } from '@mentimeter/ragnar-ui/text';
import { TextInputItem } from '@mentimeter/ragnar-ui/input/text-input';
import { useTranslate } from '../localization/Translate';
import { hasVisibleCharacters } from '../../utils/hasVisibleCharacters';
import type { SubmitResult } from './useIdentity';

interface IdentificationFormProps {
  onSubmit: (name: string) => Promise<SubmitResult>;
}

export const IdentificationForm = ({ onSubmit }: IdentificationFormProps) => {
  const translate = useTranslate();

  const validationSchema = object().shape({
    name: string()
      .trim()
      .max(
        50,
        translate('identified_responses.enter_a_shorter_name', { limit: 50 }),
      )
      .required(translate('identified_responses.you_must_enter_a_name'))
      .test(
        'should-have-visible-characters',
        translate('identified_responses.you_must_enter_a_name'),
        (value) => hasVisibleCharacters(value),
      )
      .test(
        'no-emojis',
        translate('identified_responses.emojis_are_not_allowed'),
        (value) => !hasEmoji(value),
      ),
  });

  type FormValues = InferType<typeof validationSchema>;

  const initialValues: FormValues = { name: '' };

  const [error, setError] = useState<string | undefined>(undefined);

  return (
    <>
      <Form
        alignItems="center"
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount={true}
        onSubmit={async (values, formikActions) => {
          try {
            const result = await onSubmit(values.name);
            if (result.status === 'error') {
              formikActions.setFieldError('name', result.message);
            }
          } catch (e) {
            if (
              isAxiosError(e) &&
              e.response?.status === 409 &&
              e.response?.data &&
              (
                e.response?.data as {
                  code: string;
                  message: string;
                  status: number;
                }
              ).code === 'name_already_exists'
            ) {
              formikActions.setFieldError(
                'name',
                translate('identified_responses.name_is_taken'),
              );
              return;
            }
            setError(translate('messages.something_went_wrong'));
          }
        }}
      >
        <TextInput
          width="100%"
          mt="space5"
          mb="space3"
          type="text"
          key="name"
          label="Your name"
          name="name"
          renderTextInputItem={(props) => (
            <TextInputItem {...props} inputSize="default" />
          )}
        />

        <Button type="submit" variant="primary" size="large">
          {translate('buttons.join_session')}
        </Button>
      </Form>
      {error && (
        <Text mt="space4" color="negative">
          {error}
        </Text>
      )}
    </>
  );
};
