import type { Question } from '@mentimeter/http-clients';
import type {
  QuestionWithSlide as VotingQuestionWithSlide,
  Slide as VotingSlide,
} from '@mentimeter/voting-schema/api-types-overrides';
import { isProxyInstance } from '../../../utils/is-proxy-instance';
import { getVotingSlideProperty } from '../forward-compatibility/get-voting-slide-property';
import { getVotingSlideCompatibilityHandler } from './get-voting-slide-compatibility-handler';
import { allVotingSlideKeys } from './constants';

export function createVotingSlideCompatibilityLayer(
  target: Question | VotingSlide,
): VotingQuestionWithSlide {
  if (isProxyInstance(target)) {
    return target as VotingQuestionWithSlide;
  }

  return new Proxy(
    target,
    getVotingSlideCompatibilityHandler(
      allVotingSlideKeys,
      getVotingSlideProperty,
    ),
  ) as VotingQuestionWithSlide;
}
