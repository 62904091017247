import React from 'react';
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import type { OpenVotesT } from '@mentimeter/question-modules-types';
import { TrailList } from '@mentimeter/voting-ui';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Box } from '@mentimeter/ragnar-ui/box';
import { ResponseWithResult } from './ResponseWithResult';

interface ResultListProps {
  resultsWithUpvotes: OpenVotesT[];
  resultsWithoutUpvotes: OpenVotesT[];
  maxVoteCount: number;
}

export const ResultsView = ({
  resultsWithUpvotes,
  resultsWithoutUpvotes,
  maxVoteCount,
}: ResultListProps) => {
  const { useTranslate } = useVotingContext();
  const translate = useTranslate();

  return (
    <Box alignItems="stretch" width="100%">
      <Text color="text" fontSize="125" fontWeight="regular" my="space4">
        {translate('open_ended.responses_with_votes')}
      </Text>
      <TrailList
        items={resultsWithUpvotes}
        renderItem={(item) => (
          <ResponseWithResult
            id={item.id}
            text={item.answer}
            nrOfUpvotes={item.upvotes}
            label={
              item.upvotes === maxVoteCount
                ? translate('open_ended.top_voted')
                : undefined
            }
          />
        )}
      />
      <Text color="text" fontSize="125" fontWeight="regular" my="space4">
        {translate('open_ended.responses_without_votes')}
      </Text>
      <TrailList
        items={resultsWithoutUpvotes}
        animationDelay={600}
        renderItem={(item) => (
          <ResponseWithResult
            id={item.id}
            text={item.answer}
            nrOfUpvotes={item.upvotes}
          />
        )}
      />
    </Box>
  );
};
