import { createSlice } from '@reduxjs/toolkit';
import type { SeriesOrSlideDeck } from '../types';

export const seriesSlice = createSlice({
  name: 'series',
  initialState: {} as SeriesOrSlideDeck,
  reducers: {
    refreshSeriesSuccess: (state, action) => action.payload,
  },
});

export const { refreshSeriesSuccess } = seriesSlice.actions;
