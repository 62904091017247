import type { Question } from '@mentimeter/http-clients';
import type { NumericalQuestionInteractiveContentStylingSchema as InteractiveContentStylingSchema } from '@mentimeter/schema/interactive-content-styling';
import type {
  Slide as VotingSlide,
  InteractiveContent as VotingInteractiveContent,
} from '@mentimeter/voting-schema/api-types-overrides';
import {
  getOrSetCache,
  type WithCache,
} from '../../../../utils/get-or-set-cache';
import type { CommonQuestionProperty } from './get-common-question-property';

export const getQuestionGuessTheNumberProperty = (
  target: VotingSlide,
  prop: keyof Omit<Question, CommonQuestionProperty>,
) => {
  const interactiveContent = target.interactiveContents?.[0];

  if (interactiveContent) {
    switch (prop) {
      case 'module_custom_data': {
        const moduleCustomData = getModuleCustomData(interactiveContent);
        const cacheKey = JSON.stringify(moduleCustomData);

        return getOrSetCache(
          target as WithCache<VotingSlide>,
          'module_custom_data',
          cacheKey,
          moduleCustomData,
        );
      }
      case 'range': {
        const range = getRange(interactiveContent);
        const cacheKey = JSON.stringify(range);

        return getOrSetCache(
          target as WithCache<VotingSlide>,
          'range',
          cacheKey,
          range,
        );
      }
      default:
        return undefined;
    }
  }
};

const getModuleCustomData = (interactiveContent: VotingInteractiveContent) => {
  return {
    display_min: interactiveContent?.responseRange?.min,
    scale: (interactiveContent.styling as InteractiveContentStylingSchema)
      .responseRangeScale,
  };
};

const getRange = (interactiveContent: VotingInteractiveContent) => {
  const responseRange = interactiveContent.responseRange;

  if (!responseRange) {
    return undefined;
  }

  const min = responseRange.min || 0;
  const max = responseRange.max || 0;

  const scale = (interactiveContent.styling as InteractiveContentStylingSchema)
    .responseRangeScale;

  return {
    min: 0,
    max: Math.ceil((max - min) / scale),
  };
};
