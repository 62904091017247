import { useVotingContext } from '@mentimeter/question-modules-contexts';
import { TitleSectionWithImage } from '../../ui/Components/VotingTitle';

export function Content() {
  const { useQuestion } = useVotingContext();
  const question = useQuestion();

  return (
    <TitleSectionWithImage
      description={question.question_description}
      title={question.question}
      meta={question.title_meta}
      image={question.image}
    />
  );
}
