/* eslint-disable menti-react/filename-convention--jsx */
import React from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';

export function Content() {
  return (
    <Box width="100%" alignItems="center" mb={3}>
      <Text color="text" fontSize={3} my={4} textAlign="center">
        Look at the presentation to follow the speaker.
      </Text>
    </Box>
  );
}
