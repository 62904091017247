import { useCallback, useState } from 'react';
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Form } from '@mentimeter/ragnar-ui/form';
import type { SubmitVoteBody } from '@mentimeter/question-modules-types';
import type { QuestionWithSlide } from '@mentimeter/voting-schema/api-types-overrides';
import { SubmitVoteFormButton } from '../../ui/Components/SubmitVoteFormButton';
import { SliderInput } from './SliderInput';

function displayValueToStep({
  displayValue,
  min,
  scale,
}: {
  displayValue: number;
  min: number;
  scale: number;
}) {
  return (displayValue - min) / scale;
}

function stepToDisplayValue({
  step,
  min,
  scale,
}: {
  step: number;
  min: number;
  scale: number;
}) {
  return step * scale + min;
}

function createSubmitVoteBody(
  question: QuestionWithSlide,
  rawVote: Record<
    QuestionWithSlide['choices'][0]['id'],
    [number, number] | 'other'
  >,
): SubmitVoteBody {
  if (question.isMigrated) {
    const interactiveContentChoiceIds = Object.entries(rawVote).map(
      ([rawVoteChoiceId, points]) => {
        const choice = question.interactiveContents[0]!.choices.find(
          (c) => c.legacyChoiceId?.toString() === rawVoteChoiceId,
        );
        return {
          interactiveContentChoiceId: choice!.interactiveContentChoiceId,
          value: points === 'other' ? ('skipped' as const) : points[0],
        };
      },
    );
    return {
      isMigrated: true,
      slidePublicKey: question.slidePublicKey,
      interactiveContentId:
        question.interactiveContents[0]!.interactiveContentId,
      payload: {
        type: 'guess-the-number',
        choices: interactiveContentChoiceIds,
      },
      partial: false,
    };
  }
  return {
    isMigrated: false,
    questionPublicKey: question.public_key,
    payload: { type: 'scales', vote: rawVote },
    partial: false,
  };
}

export function Interactive() {
  const { useQuestion, useActions } = useVotingContext();

  const question = useQuestion();
  const { error, vote: submitVote } = useActions();

  const range = question.range;
  const scale = question.module_custom_data?.scale || 1;
  const min = question.module_custom_data?.display_min;
  const max = stepToDisplayValue({ min, scale, step: range.max || 10 });
  const [vote, setVote] = useState<number>(min);
  const choice = question.choices[0];

  function isValidVote(voteValue: number) {
    return voteValue >= min && voteValue <= max;
  }

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const rawValue = e.target.value;
    if (rawValue.length > 0) {
      const voteValue = parseInt(rawValue, 10);
      if (isValidVote(voteValue)) {
        setVote(voteValue);
      }
    }
  }

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!choice) return;

      const votePayload = {
        [choice.id]: [
          displayValueToStep({ displayValue: vote, min, scale }),
          min,
        ],
      } satisfies { [choiceId: string]: [number, number] };

      submitVote(createSubmitVoteBody(question, votePayload));
    },
    [choice, min, question, scale, submitVote, vote],
  );

  const percentage = Math.min(Math.max((vote - min) / (max - min), 0), 1) || 0;

  if (!choice) return null;

  return (
    <Form onSubmit={handleSubmit} width="100%">
      <Box width="100%" py="space6">
        <Box
          width="100%"
          flexDirection="row"
          alignItems="center"
          gap="space1"
          pb="space6"
        >
          <Text as="h2" fontSize="100" fontWeight="regular" color="text">
            Your answer:
          </Text>
          <Text fontSize="112.5" fontWeight="semiBold" color="text">
            {vote}
          </Text>
        </Box>
        <SliderInput
          choiceId={choice.id}
          label={question.question}
          min={min}
          max={max}
          scale={scale}
          percentage={percentage}
          error={error}
          value={vote}
          onChange={onChange}
        />
      </Box>

      <SubmitVoteFormButton />
    </Form>
  );
}
