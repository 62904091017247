import * as React from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';
import { PageLayout } from '../../ui';
import { useTranslate } from '../localization/Translate';

const NoActive = () => {
  const translate = useTranslate();
  return (
    <PageLayout>
      <Box
        height="100%"
        width="100%"
        justifyContent="center"
        alignItems="center"
        bg="bg"
        p={3}
      >
        <Text>{translate('state.no_active_question')}</Text>
      </Box>
    </PageLayout>
  );
};

export default NoActive;
