import { useRagnar } from '@mentimeter/ragnar-react';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Range } from '@mentimeter/ragnar-ui/range';

export const SliderInput = ({
  choiceId,
  label,
  min,
  max,
  scale,
  percentage,
  error,
  value,
  onChange,
}: {
  choiceId: number;
  label: string;
  min: number;
  max: number;
  scale: number;
  percentage: number;
  error: string;
  value: number;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}) => {
  const { theme } = useRagnar();
  return (
    <Box
      width="100%"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Text data-testid="voting-range-min-value">{min}</Text>
      <Box
        width="100%"
        flex="1 1 auto"
        mx="space2"
        alignItems="center"
        justifyContent="center"
      >
        <div
          style={{
            width: '40px',
            position: 'absolute',
            zIndex: 2,
            left: `${percentage * 100}%`,
            transform: `translate(-${percentage * 100}%, 0)`,
            textAlign: 'center',
            pointerEvents: 'none',
          }}
        />
        <Range
          data-testid="voting-slider"
          disabled={error === 'duplicated'}
          name={`range-${choiceId}`}
          aria-label={label}
          id={choiceId.toString()}
          min={min}
          max={max}
          step={scale}
          value={value}
          onChange={onChange}
          thumbColor={theme.colors.primary}
        />
      </Box>
      <Text data-testid="voting-range-max-value">{max}</Text>
    </Box>
  );
};
