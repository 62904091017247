import type { BoxT } from '@mentimeter/ragnar-ui/box';
import { Box } from '@mentimeter/ragnar-ui/box';

export function TitleSectionContainer({ children, ...props }: BoxT) {
  return (
    <Box
      width="100%"
      mb="space6"
      {...props}
      extend={() => ({
        '> :last-child': {
          marginBottom: 0,
        },
      })}
    >
      {children}
    </Box>
  );
}
