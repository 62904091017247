import { useVotingContext } from '@mentimeter/question-modules-contexts';
import { VotingConfirmationModal } from '@mentimeter/voting-ui';
import React, { useCallback, useMemo, useState } from 'react';
import type { QuestionChoice } from '@mentimeter/http-clients';
import type { QuestionWithSlide } from '@mentimeter/voting-schema/api-types-overrides';
import type { SubmitVoteBody } from '@mentimeter/question-modules-types';
import { Box } from '@mentimeter/ragnar-ui/box';
import { RankingListWrapper } from './components/RankingListWrapper';
import type { RankableChoice } from './components/types';

function createSubmitVoteBody(
  question: QuestionWithSlide,
  rawVote: Array<QuestionChoice['id']>,
): SubmitVoteBody {
  if (question.isMigrated) {
    const interactiveContentChoiceIds = rawVote.map((rawVoteChoiceId) => {
      const choice = question.interactiveContents[0]!.choices.find(
        (c) => c.legacyChoiceId === rawVoteChoiceId,
      );
      return { interactiveContentChoiceId: choice!.interactiveContentChoiceId };
    });
    return {
      isMigrated: true,
      slidePublicKey: question.slidePublicKey,
      interactiveContentId:
        question.interactiveContents[0]!.interactiveContentId,
      payload: { type: 'ranking', choices: interactiveContentChoiceIds },
      partial: false,
    };
  }
  return {
    isMigrated: false,
    questionPublicKey: question.public_key,
    payload: { type: 'ranking', vote: rawVote },
    partial: false,
  };
}

export function Interactive() {
  const { useQuestion, useActions, useTranslate, gaTrackEvent } =
    useVotingContext();
  const question = useQuestion();
  const [showModal, setShowModal] = useState(false);
  const { vote: submitVote, skip: skipVote } = useActions();
  const translate = useTranslate();

  const onError = useCallback(() => void setShowModal(true), []);

  const rankingChoices = useMemo<RankableChoice[]>(() => {
    return [...question.choices]
      .sort(() => 0.5 - Math.random())
      .map(({ id, label }) => ({
        id,
        label,
      }));
  }, [question.choices]);

  const handleSubmit = useCallback(
    (rankedIds: Array<QuestionChoice['id']>) => {
      submitVote(createSubmitVoteBody(question, rankedIds));
    },
    [question, submitVote],
  );

  return (
    <Box width="100%">
      <RankingListWrapper
        rankableChoices={rankingChoices}
        onSubmit={handleSubmit}
        onError={onError}
        trackEvent={gaTrackEvent}
        translate={translate}
      />

      <VotingConfirmationModal
        id="skip-vote-modal-ranking"
        showModal={showModal}
        onConfirm={skipVote}
        onDismiss={() => setShowModal(false)}
        title={translate('messages.not_made_choice')}
        bodyText={translate('messages.continue_without_voting')}
        confirmButtonText={translate('buttons.ok')}
        dismissButtonText={translate('buttons.cancel')}
      />
    </Box>
  );
}
