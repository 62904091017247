import React from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Button } from '@mentimeter/ragnar-ui/button';
import { Link } from '@mentimeter/ragnar-ui/link';
import { useTranslate } from '../localization/Translate';
import { ErrorLayout } from '../../ui';

export const Error = () => {
  const translate = useTranslate();

  const mailToData = [
    'mailto:hello@mentimeter.com',
    '?subject=Could not join identified menti',
  ];

  return (
    <ErrorLayout>
      <Box alignItems="center" gap="space6">
        <Text
          fontFamily="heading"
          lineHeight="heading"
          fontSize="175"
          as="h1"
          textAlign="center"
        >
          {translate('messages.sorry_for_the_inconvenience')}
        </Text>
        <Text>{translate('messages.there_was_an_issue_please_try_again')}</Text>
        <Button
          size="large"
          variant="primary"
          onClick={(e) => {
            e.preventDefault();
            window.location.reload();
          }}
        >
          {translate('buttons.try_again')}
        </Button>
        <Text>
          {translate('messages.contact_us_if_the_problem_persists')}{' '}
          <Link href={mailToData.join('')}>hello@mentimeter.com</Link>
        </Text>
        <Text fontStyle="italic" textAlign="center">
          /{translate('messages.the_mentimeter_team')}
        </Text>
      </Box>
    </ErrorLayout>
  );
};
