import React from 'react';
import type { ImageResource } from '@mentimeter/http-clients';
import { Box } from '@mentimeter/ragnar-ui/box';
import { OptionImage } from '../OptionImage';
import { MultipleChoiceLabel } from './MultipleChoiceLabel';
import { MultipleChoiceCheckItem } from './MultipleChoiceCheckItem';

interface BaseProps {
  id: number | string;
  index: number;
  selected?: boolean;
  disabled?: boolean;
  hideCheckItem?: boolean;
  value: string | number;
  type: 'radio' | 'checkbox';
  name: string;
  onChange: (value: string) => void;
  children: React.ReactNode;
}

interface WithImage extends BaseProps {
  image: ImageResource | null | undefined;
  imageAlt?: string | undefined | null;
  dismissButtonText: string;
  showImageText: string;
}

interface WithoutImage extends BaseProps {
  image?: never;
  imageAlt?: never;
  dismissButtonText?: never;
  showImageText?: never;
}

export type MultipleChoiceProps = WithImage | WithoutImage;

export const MultipleChoice = ({
  id,
  index,
  selected = false,
  disabled = false,
  hideCheckItem = false,
  value,
  type,
  name,
  image,
  imageAlt,
  dismissButtonText,
  showImageText,
  onChange,
  children,
}: MultipleChoiceProps) => {
  return (
    <MultipleChoiceLabel
      id={id}
      index={index}
      selected={selected}
      disabled={disabled}
    >
      <Box
        width="100%"
        alignItems="stretch"
        flexDirection="row-reverse"
        gap="space5"
      >
        <MultipleChoiceCheckItem
          type={type}
          id={id}
          selected={selected}
          disabled={disabled}
          name={name}
          value={value}
          onChange={(value) => onChange(value)}
          hideCheckItem={hideCheckItem}
        >
          {children}
        </MultipleChoiceCheckItem>

        {image && (
          <Box ml="-space2" justifyContent="center">
            <OptionImage
              largeSrc={image.presets.medium.url}
              smallSrc={image.presets.preview.url}
              altDescription={imageAlt || image?.alt_description || undefined}
              dismissButtonText={dismissButtonText}
              showImageText={showImageText}
            />
          </Box>
        )}
      </Box>
    </MultipleChoiceLabel>
  );
};
