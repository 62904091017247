import React from 'react';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { Box } from '../box';

interface DrawerFooterT {
  children: React.ReactNode;
  className?: string;
}

export const DrawerFooter = ({ children, className }: DrawerFooterT) => (
  <Box
    className={clsx(
      [
        'w-full',
        'flex-col-reverse',
        'sm:flex-row',
        'items-stretch',
        'sm:items-center',
        'justify-end',
        'flex-wrap',
        'pt-1.5',
        'px-8',
        'pb-8',
        'gap-y-2',
        'gap-x-3',
      ],
      className,
    )}
  >
    {children}
  </Box>
);
