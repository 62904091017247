'use client';
import * as React from 'react';
import { useSearchParams } from 'next/navigation';
import { themes } from '@mentimeter/ragnar-colors';
import type { ParticipantIdentity } from '@mentimeter/question-modules-types';
import { Box } from '@mentimeter/ragnar-ui/box';
import {
  ModalBody,
  ModalContainer,
  ModalHeader,
  ModalRoot,
} from '@mentimeter/ragnar-ui/modal';
import { Theme } from '@mentimeter/ragnar-ui/themes';
import { Text } from '@mentimeter/ragnar-ui/text';
import type { Translation } from '../languages';
import { usePubSub } from '../realtime/usePubSub';
import { useAppSelector } from '../redux-hooks';
import {
  getDSCWithTheme,
  languageSelector,
  paceModeSelector,
  participationIdentityModeSelector,
  voteKeySelector,
} from '../selectors';
import { trackEvent, useTrack } from '../utils/tracker';
import { useAudienceConnect } from '../realtime/useAudienceConnect';
import { useAudienceConnectStore } from '../realtime/useAudienceConnectStore';
import { TranslationsProvider } from './localization/Translate';
import { AudiencePace } from './audience-pace/AudiencePace';
import { PresenterPace } from './presenter-pace/PresenterPace';
import { useShouldRequestDesktopExperience } from './desktop-experience/useShouldRequestDesktopExperience';
import { IdentificationContainer } from './identified-responses/IdentificationContainer';
import { getJoinedPresTrackProps } from './utils/getJoinedPresTrackProps';
import { useIdentity } from './identified-responses/useIdentity';
import { AuthenticationContainer } from './auth/AuthenticationContainer';

const THEME_OVERRIDE_STATUSES: Array<ParticipantIdentity['status']> = [
  'loading',
  'error',
  'needs_identification',
];

export const Series = ({
  translations,
}: {
  translations?: Translation | undefined;
}) => {
  const trackVoter = useTrack();
  const source = useSearchParams()?.get('source');
  const voteKey = useAppSelector(voteKeySelector);
  const paceMode = useAppSelector(paceModeSelector);
  const { initialized, shouldRequestDesktopExperience } =
    useShouldRequestDesktopExperience();
  useAudienceConnect({
    shouldRequestDesktopExperience,
    readyToConnect: initialized,
  });
  const status = useAudienceConnectStore((state) => state.status);
  const desktopExperienceLimitReached = useAudienceConnectStore(
    (state) => state.desktopExperienceLimitReached,
  );
  usePubSub(status === 'connected');

  const presentationTheme = useAppSelector(getDSCWithTheme);
  const language = useAppSelector(languageSelector);
  const participationIdentityMode = useAppSelector(
    participationIdentityModeSelector,
  );

  const { identity } = useIdentity();
  const theme = THEME_OVERRIDE_STATUSES.includes(identity.status)
    ? themes.light
    : presentationTheme;

  React.useEffect(() => {
    trackEvent('event status', 'started');
  }, []);

  React.useEffect(() => {
    trackVoter({
      event: 'Joined presentation',
      properties: getJoinedPresTrackProps({
        source,
        voteKey,
        participationIdentityMode,
      }),
    });
  }, [trackVoter, participationIdentityMode, voteKey, source]);

  React.useEffect(() => {
    // We want to load the translation in case the user refreshes the page
    if (language) window.document.documentElement.lang = language;
  }, [language]);

  return (
    <TranslationsProvider
      locale={language}
      serverSideTranslation={translations}
    >
      <Theme theme={theme}>
        <Box
          flexDirection="column"
          flex="1 0 auto"
          bg="bg"
          data-testid="voting-background"
        >
          <ModalRoot open={status === 'audienceLimitReached'}>
            <ModalContainer dismissable={false}>
              <ModalHeader dismissable={false}>
                Wow, this is a popular presentation!
              </ModalHeader>
              <ModalBody>
                <Text color="textWeak">
                  All the spots are filled, so you won’t be able to vote. Just
                  sit back, relax and enjoy the presentation.
                </Text>
              </ModalBody>
            </ModalContainer>
          </ModalRoot>

          <AuthenticationContainer>
            <IdentificationContainer>
              {paceMode === 'audience' ? (
                <AudiencePace voteKey={voteKey} />
              ) : (
                <PresenterPace
                  voteKey={voteKey}
                  shouldRequestDesktopExperience={
                    shouldRequestDesktopExperience &&
                    !desktopExperienceLimitReached &&
                    status === 'connected'
                  }
                />
              )}
            </IdentificationContainer>
          </AuthenticationContainer>
        </Box>
      </Theme>
    </TranslationsProvider>
  );
};
