import React from 'react';
import { designSystemConfig } from '@mentimeter/ragnar-dsc';
import type { IconT } from '@mentimeter/ragnar-visuals';
import { Svg } from '@mentimeter/ragnar-ui/svg';

const Shape = ({ color, size, children }: React.PropsWithChildren<IconT>) => (
  <Svg
    aria-hidden
    viewBox="0 0 120 120"
    height={
      typeof size === 'number'
        ? designSystemConfig.fontSizes[size] || size
        : size
    }
    width={
      typeof size === 'number'
        ? designSystemConfig.fontSizes[size] || size
        : size
    }
  >
    <g fill={color}>{children}</g>
  </Svg>
);

export const ArcUpShape = (props: IconT) => {
  return (
    <Shape {...props}>
      <path d="M101.25 19.6881C101.25 18.5127 100.237 17.5892 99.069 17.7235C57.3117 22.5269 24.2654 56.5072 20.1808 99.1027C20.0699 100.259 20.9887 101.25 22.1502 101.25L59.5312 101.25C60.5342 101.25 61.378 100.505 61.544 99.5158C64.9497 79.2246 80.3383 63.4529 99.5927 59.4388C100.546 59.24 101.25 58.412 101.25 57.4378L101.25 19.6881Z" />
    </Shape>
  );
};

export const SemiCircleShape = (props: IconT) => {
  return (
    <Shape {...props}>
      <path d="M43.2191 17.2237C41.9327 15.9166 39.8381 15.9097 38.6378 17.2987C35.21 21.2653 32.4027 25.7409 30.3313 30.5541C27.8227 36.3832 26.4425 42.5933 26.2694 48.8298C26.0964 55.0663 27.1339 61.2071 29.3228 66.9016C31.5117 72.5961 34.809 77.7327 39.0265 82.0183C43.244 86.3038 48.2992 89.6544 53.9033 91.8785C59.5074 94.1027 65.5507 95.157 71.6882 94.9811C77.8257 94.8053 83.9372 93.4028 89.6738 90.8538C94.4106 88.749 98.8152 85.8964 102.719 82.4133C104.086 81.1936 104.079 79.0652 102.793 77.7581L43.2191 17.2237Z" />
    </Shape>
  );
};

export const CircleShape = (props: IconT) => {
  return (
    <Shape {...props}>
      <rect x="20" y="21.25" width="79.2614" height="77.5" rx="38.75" />
    </Shape>
  );
};

const QuarterCircleShape = (props: IconT) => {
  return (
    <Shape {...props}>
      <path d="M94.0694 22.7283C95.2368 22.5893 96.25 23.5133 96.25 24.6889V94.25C96.25 95.3546 95.3546 96.25 94.25 96.25H27.1496C25.9883 96.25 25.0693 95.2599 25.1833 94.1042C28.8631 56.8056 57.6612 27.063 94.0694 22.7283Z" />
    </Shape>
  );
};

const SquareShape = (props: IconT) => {
  return (
    <Shape {...props}>
      <path d="M23.75 35.35C23.75 28.9435 28.9435 23.75 35.35 23.75H84.65C91.0565 23.75 96.25 28.9435 96.25 35.35V84.65C96.25 91.0565 91.0565 96.25 84.65 96.25H35.35C28.9435 96.25 23.75 91.0565 23.75 84.65V35.35Z" />
    </Shape>
  );
};

const ParallelogramShape = (props: IconT) => {
  return (
    <Shape {...props}>
      <path d="M31.99 97.5562C31.7052 98.8079 32.6565 100 33.9402 100H70.2015C71.1352 100 71.9446 99.3541 72.1517 98.4437L89.444 22.4437C89.7288 21.1921 88.7775 20 87.4938 20H51.2341C50.3005 20 49.4911 20.6459 49.284 21.5562L31.99 97.5562Z" />
    </Shape>
  );
};

const DiamondShape = (props: IconT) => {
  return (
    <Shape {...props}>
      <path d="M51.7976 16.9372C56.3276 12.4071 63.6724 12.4071 68.2024 16.9372L103.063 51.7976C107.593 56.3277 107.593 63.6724 103.063 68.2025L68.2024 103.063C63.6724 107.593 56.3276 107.593 51.7976 103.063L16.9372 68.2025C12.4071 63.6724 12.4071 56.3277 16.9372 51.7976L51.7976 16.9372Z" />
    </Shape>
  );
};
const TrapeziumShape = (props: IconT) => {
  return (
    <Shape {...props}>
      <path d="M69.0989 18.5108C69.0989 17.2257 67.9042 16.2741 66.6516 16.5615L28.5528 25.3025C27.6441 25.511 27 26.3196 27 27.2519V78C27 79.1046 27.8954 80 29 80H67.0989C68.2034 80 69.0989 79.1046 69.0989 78V18.5108Z" />
    </Shape>
  );
};

const ArcBottomShape = (props: IconT) => {
  return (
    <Shape {...props}>
      <path d="M16.1881 15C15.0127 15 14.0886 16.0122 14.2301 17.179C18.2405 50.2399 45.1261 76.3811 78.8544 79.8131C80.01 79.9307 81 79.0113 81 77.8497L81 48.7256C81 47.7225 80.2553 46.8799 79.2676 46.7053C63.3167 43.8858 50.9191 31.7958 47.6213 16.6555C47.414 15.7037 46.5871 15 45.613 15L16.1881 15Z" />
    </Shape>
  );
};

const TriangleShape = (props: IconT) => {
  return (
    <Shape {...props}>
      <path d="M36.6885 24.9611C39.5866 18.3086 48.5961 17.2812 52.9176 23.1104L80.1391 59.8293C84.5324 65.7554 80.731 74.2043 73.3821 74.8473L28.4518 78.7782C21.3224 79.4019 16.1214 72.1719 18.9797 65.6109L36.6885 24.9611Z" />
    </Shape>
  );
};

export const allShapes = [
  ArcUpShape,
  SemiCircleShape,
  CircleShape,
  QuarterCircleShape,
  SquareShape,
  ParallelogramShape,
  DiamondShape,
  TrapeziumShape,
  ArcBottomShape,
  TriangleShape,
] as const;

export interface MixShapeProps extends IconT {
  fillColors: string[];
}

export const MixShape = ({ fillColors, ...props }: MixShapeProps) => {
  return (
    <Shape {...props}>
      <rect
        width="34.8625"
        height="34.0754"
        rx="17.0377"
        transform="matrix(0.976085 -0.217386 0.217552 0.976049 13.2363 60.4453)"
        fill={fillColors[0]}
      />
      <path
        d="M60.0201 21.7793C61.6804 18.1066 66.8422 17.5394 69.318 20.7576L84.9137 41.0294C87.4307 44.3012 85.2528 48.9657 81.0425 49.3206L55.3011 51.4908C51.2166 51.8352 48.2369 47.8436 49.8744 44.2214L60.0201 21.7793Z"
        fill={fillColors[1]}
      />
      <path
        d="M72.114 66.0373C72.763 63.2963 75.512 61.6001 78.2542 62.2488L99.3559 67.2408C102.098 67.8895 103.795 70.6374 103.146 73.3785L98.152 94.4718C97.503 97.2129 94.7539 98.909 92.0118 98.2603L70.9101 93.2684C68.1679 92.6197 66.4711 89.8717 67.12 87.1306L72.114 66.0373Z"
        fill={fillColors[2]}
      />
    </Shape>
  );
};
