import type { Question } from '@mentimeter/http-clients';
import type { Slide as VotingSlide } from '@mentimeter/voting-schema/api-types-overrides';
import { memoize } from 'lodash';
import { InvalidProxySetError } from '../../../utils/invalid-proxy-set-error';
import type { getVotingSlideProperty } from '../forward-compatibility/get-voting-slide-property';
import { getQuestionProperty } from '../backward-compatibility/question-properties/get-question-property';

type SlidePropertyKeys =
  | keyof VotingSlide
  | keyof Question
  | '__isProxy'
  | '__target'
  | 'isMigrated';

type GetCommonSlideCompatibilityHandler = (
  slidePropertyKeys: Array<SlidePropertyKeys>,
  mapSlidePropertyToQuestionProperty: typeof getVotingSlideProperty,
) => ProxyHandler<VotingSlide | Question>;

export const getVotingSlideCompatibilityHandler =
  memoize<GetCommonSlideCompatibilityHandler>(
    (slidePropertyKeys, mapSlidePropertyToQuestionProperty) => ({
      get(target: VotingSlide | Question, key: SlidePropertyKeys) {
        if (key === '__isProxy') {
          return true;
        }

        if (key === '__target') {
          return target;
        }
        const isTargetMigrated = Reflect.has(target, 'staticContent');

        if (key === 'isMigrated') {
          return isTargetMigrated;
        }

        // if new domain key
        if (slidePropertyKeys.includes(key)) {
          if (isTargetMigrated) {
            return target[key as keyof typeof target];
          }

          // unmigrated
          return mapSlidePropertyToQuestionProperty(
            target as Question,
            key as keyof VotingSlide,
          );
        }

        // old domain key === question properties
        if (isTargetMigrated) {
          /**
           * TODO [Slides]: Replace cast
           */
          return getQuestionProperty(target as VotingSlide, key as any);
        }

        // if unmigrated and old key
        return (target as Question)[key as keyof Question];
      },
      set(_: never, key: string) {
        throw new InvalidProxySetError(key, 'slide');
      },
    }),
  );
