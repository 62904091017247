import { useVotingContext } from '@mentimeter/question-modules-contexts';
import type {
  QuestionDimension,
  QuestionRange,
} from '@mentimeter/http-clients';
import { VotingConfirmationModal } from '@mentimeter/voting-ui';
import { type FormEvent, Fragment, useState } from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Form } from '@mentimeter/ragnar-ui/form';
import type { QuestionWithSlide } from '@mentimeter/voting-schema/api-types-overrides';
import type { SubmitVoteBody } from '@mentimeter/question-modules-types';
import { SubmitVoteFormButton } from '../../ui/Components/SubmitVoteFormButton';
import { LIKERT_SCALES } from './likertScales';
import { Option } from './Option';

const getLikert = (
  dimension: QuestionDimension,
  range: QuestionRange,
): string[] => {
  if (
    dimension.mid_values &&
    dimension.mid_values.length > 0 &&
    dimension.mid_values.length === range.max - range.min - 1
  ) {
    return [dimension.min, ...dimension.mid_values, dimension.max];
  }
  if (!dimension.min) return [];

  // Forms a string like 'min_label_max_label_nrOfLabels'
  const likertKey = `${dimension.min
    .toLowerCase()
    .split(' ')
    .join('_')}_${dimension.max.toLowerCase().split(' ').join('_')}_${
    range.max - range.min + 1
  }`;
  return LIKERT_SCALES[likertKey] ?? [];
};

function inRange(voteValue: number, range: QuestionRange) {
  return voteValue >= range.min && voteValue <= range.max;
}

interface Vote {
  [id: string]: { value: number; skipped: boolean };
}

function createSubmitVoteBody(
  question: QuestionWithSlide,
  rawVote: Record<
    QuestionWithSlide['choices'][0]['id'],
    [number, number] | 'other'
  >,
): SubmitVoteBody {
  if (question.isMigrated) {
    const interactiveContentChoiceIds = Object.entries(rawVote).map(
      ([rawVoteChoiceId, points]) => {
        const choice = question.interactiveContents[0]!.choices.find(
          (c) => c.legacyChoiceId?.toString() === rawVoteChoiceId,
        );
        return {
          interactiveContentChoiceId: choice!.interactiveContentChoiceId,
          value: points === 'other' ? ('skipped' as const) : points[0],
        };
      },
    );
    return {
      isMigrated: true,
      slidePublicKey: question.slidePublicKey,
      interactiveContentId:
        question.interactiveContents[0]!.interactiveContentId,
      payload: {
        type: 'scales',
        choices: interactiveContentChoiceIds,
      },
      partial: false,
    };
  }
  return {
    isMigrated: false,
    questionPublicKey: question.public_key,
    payload: { type: 'scales', vote: rawVote },
    partial: false,
  };
}

export function Interactive() {
  const { useQuestion, useActions, useTheme, useTranslate } =
    useVotingContext();
  const { skip: skipVote, vote: submitVote } = useActions();
  const question = useQuestion();
  const theme = useTheme();
  const translate = useTranslate();

  const [values, setValues] = useState<Vote | null>(null);
  const [shouldShowConfirmSubmitModal, setShouldShowConfirmSubmitModal] =
    useState(false);

  const { choices, dimensions, range, hide_skip: hideSkip } = question;

  const [dimension] = dimensions;

  const likert = getLikert(dimension, range);

  // The validated vote that is updated to number of choices and within range
  const vote: Vote = choices.reduce((prev, c) => {
    const item = values && values[c.id];
    return {
      ...prev,
      [c.id]: {
        value:
          item?.value !== undefined && inRange(item.value, range)
            ? item.value
            : range.min,
        skipped: item?.skipped || false,
      },
    };
  }, {});

  function onChange(id: number, e: FormEvent<HTMLInputElement>) {
    const element = e.target as HTMLInputElement;
    const rawValue = element.value;

    if (rawValue.length === 0) return;
    const value = parseInt(rawValue, 10);
    if (!inRange(value, range)) return;
    setValues((state) => ({
      ...state,
      [id]: { value, skipped: false },
    }));
  }

  function onChangeOptionSkip(id: number) {
    const item = vote[id];
    if (!item) return;
    setValues((state) => ({
      ...state,
      [id]: {
        value: item.value,
        skipped: !item.skipped,
      },
    }));
  }

  function handleSubmit() {
    const actualVotes = choices.reduce((curr, { id }) => {
      const item = vote[id];
      const value = !item || item.skipped ? 'other' : [item.value, range.min];

      return {
        ...curr,
        [id]: value,
      };
    }, {});

    submitVote(createSubmitVoteBody(question, actualVotes));
  }

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    // Edge case: voting on a scales question without choices
    if (choices.length === 0) {
      skipVote();
      return;
    }

    if (values === null) {
      setShouldShowConfirmSubmitModal(true);
      return;
    }
    handleSubmit();
  }

  return (
    <Form onSubmit={onSubmit} width="100%">
      <Box width="100%" mb={3}>
        {choices.map((c, index) => (
          <Fragment key={c.id}>
            <Option
              index={index}
              id={c.id}
              value={vote[c.id]?.value}
              label={c.label}
              labels={dimensions}
              min={range.min}
              max={range.max}
              likert={likert}
              onChange={onChange}
              onChangeOptionSkip={onChangeOptionSkip}
              skippable={!hideSkip}
              skipped={Boolean(vote[c.id]?.skipped)}
              theme={theme}
            />
          </Fragment>
        ))}
      </Box>
      <SubmitVoteFormButton />
      <VotingConfirmationModal
        id="submit-vote-modal-scales"
        showModal={shouldShowConfirmSubmitModal}
        onConfirm={handleSubmit}
        onDismiss={() => setShouldShowConfirmSubmitModal(false)}
        title={translate('scales.confirmation_modal_title')}
        confirmButtonText={translate('buttons.ok')}
        dismissButtonText={translate('buttons.cancel')}
      />
    </Form>
  );
}
