import { voting } from '@mentimeter/http-clients';

export function getAudienceQuestions(
  seriesVoteKey: string,
  mostUpvoted = false,
  page = 1,
) {
  if (mostUpvoted) {
    return voting().qfa.getByUpvote(seriesVoteKey, page);
  }
  return voting().qfa.get(seriesVoteKey, page);
}

export interface SubmitQuestionBody {
  question: string;
  asked_on_question_key?: string;
}

export const submitQuestion = (voteKey: string, body: SubmitQuestionBody) => {
  return voting().qfa.post(voteKey, body);
};

export const submitUpvote = (qfaDataId: number, voteKey: string) => {
  return voting().qfa.upvote(qfaDataId, voteKey);
};

export const undoUpvote = (qfaDataId: number, voteKey: string) => {
  return voting().qfa.undoUpvote(qfaDataId, voteKey);
};
