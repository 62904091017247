import * as React from 'react';
import { Button } from '@mentimeter/ragnar-ui/button';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Box } from '@mentimeter/ragnar-ui/box';
import { ThemeBgSensitive } from '@mentimeter/ragnar-ui/themes';
import {
  DrawerContainer,
  DrawerFooter,
  DrawerHeader,
  DrawerRoot,
} from '@mentimeter/ragnar-ui/drawer';
import { useRagnar } from '@mentimeter/ragnar-react';
import { useTranslate } from '../localization/Translate';

export const ParticipateAgainNotification = ({
  onClickStartOver,
}: {
  onClickStartOver: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  const [show, setShow] = React.useState(true);
  const translate = useTranslate();
  const { theme } = useRagnar();

  return (
    <DrawerRoot open={show} onOpenChange={setShow}>
      <DrawerContainer side="top" className="border-none">
        <ThemeBgSensitive themeBg={theme.colors.bg}>
          <Box width="100%" height="100%" bg="bg">
            <DrawerHeader>
              <Text>{translate('messages.do_you_want_to_start_over')}</Text>
            </DrawerHeader>
            <DrawerFooter>
              <Button onClick={() => setShow(false)} mr={2}>
                {translate('buttons.hide')}
              </Button>
              <Button variant="primary" onClick={onClickStartOver}>
                {translate('buttons.start_over')}
              </Button>
            </DrawerFooter>
          </Box>
        </ThemeBgSensitive>
      </DrawerContainer>
    </DrawerRoot>
  );
};
