import { Button } from '@mentimeter/ragnar-ui/button';

export const SkipButton = ({
  label,
  onClick,
}: {
  label: string;
  onClick: () => void;
}) => (
  <Button
    size="large"
    mt="space2"
    onClick={onClick}
    type="button"
    variant="secondary"
    aria-label={label}
  >
    {label}
  </Button>
);
