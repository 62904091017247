import React from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Label } from '@mentimeter/ragnar-ui/label';

export interface MultipleChoiceProps {
  id: number | string;
  index: number;
  selected?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
}

export const MultipleChoiceLabel = ({
  id,
  index,
  disabled = false,
  selected = false,
  children,
}: MultipleChoiceProps) => {
  const borderColor = selected
    ? 'secondary'
    : disabled
      ? 'borderWeak'
      : 'border';

  return (
    <Label
      htmlFor={id.toString()}
      data-testid={`choice-${index}`}
      width="100%"
      mb="space0"
    >
      <Box
        flexDirection="row"
        width="100%"
        alignItems="center"
        flex="1 1 auto"
        bg={selected ? 'secondaryWeakest' : 'bg'}
        borderRadius="4xl"
        overflow="hidden"
        borderColor={borderColor}
        borderWidth="1px"
        borderStyle="solid"
        px="space6"
        py="space4"
        extend={({ theme }) => ({
          cursor: !disabled ? 'pointer' : 'not-allowed',
          transition: `all ${theme.durations[1]}s ease`,
          boxShadow: selected
            ? `inset 0px 0px 0px 1px ${theme.colors.secondary}`
            : undefined,
          '@media(hover: hover)': {
            '&:hover': {
              borderColor: theme.colors.secondary,
            },
          },
          '&:has(input:focus)': {
            outline: `4px solid ${theme.colors.interactiveFocused}`,
            borderColor: theme.colors.secondary,
            outlineOffset: '2px',
          },
        })}
      >
        {children}
      </Box>
    </Label>
  );
};
