import { type DSC } from '@mentimeter/ragnar-dsc';
import { themes } from '@mentimeter/ragnar-colors';
import {
  PORTAL_ID_ACTIONSHEET_ABSOLUTE,
  PORTAL_ID_VOTING_PANEL_BOTTOM_BAR,
} from '@mentimeter/voting-ui';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Ratio, RATIO_LANDSCAPE } from '@mentimeter/ragnar-ui/ratio';
import { Theme } from '@mentimeter/ragnar-ui/themes';

const LANDSCAPE_RATIO_VALUE = parseFloat(RATIO_LANDSCAPE) / 100;

export const DesktopExperienceLayout = ({
  header,
  canvas,
  voting,
  theme,
}: {
  header: React.ReactNode;
  canvas: React.ReactNode;
  voting: React.ReactNode;
  theme: DSC;
}) => {
  const spacingValue = theme.kosmosSpacing.space4;

  return (
    <Theme theme={theme}>
      <Box
        width="100vw"
        height="100vh"
        alignItems="center"
        bg={themes.dark.colors.surfaceSunken}
      >
        {header}
        <Box
          width="100%"
          maxWidth="100vw"
          flex="1 1 auto"
          alignItems="stretch"
          flexDirection="row"
          flexWrap="wrap"
          p={spacingValue}
          gap={spacingValue}
        >
          <Box flex="1 1 auto" justifyContent="center">
            <Box
              // This is due to sfinx's fixed ratio and thus
              // inability to adjust to parent's width.
              // We deduct the padding value for the space above
              // and below canvas
              maxWidth={`calc((100vh - ${
                2 * spacingValue
              }px) / ${LANDSCAPE_RATIO_VALUE})`}
              maxHeight="100%"
              width="100%"
              alignSelf="center"
              style={{
                borderRadius: '16px',
              }}
              overflow="hidden"
            >
              <Ratio ratio="landscape">{canvas}</Ratio>
            </Box>
          </Box>
          <Box
            width={[null, null, '320px', '400px', '480px']}
            height="100%"
            borderRadius="16px"
            overflow="hidden"
            id={PORTAL_ID_ACTIONSHEET_ABSOLUTE}
          >
            <Box
              alignItems="stretch"
              width="100%"
              height="100%"
              overflow="auto"
              bg="bg"
            >
              {voting}
            </Box>

            <Box
              width="100%"
              position="absolute"
              bottom={0}
              zIndex={1}
              id={PORTAL_ID_VOTING_PANEL_BOTTOM_BAR}
            />
          </Box>
        </Box>
      </Box>
    </Theme>
  );
};
