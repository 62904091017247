import type { Question } from '@mentimeter/http-clients';
import type { Image } from '@core-api/audience/types/response';

export const imageToQuestionImage = (
  image: Image,
): NonNullable<Question['image']> => {
  return {
    url: image.presets?.large?.url,
    id: 'not_supported_in_voting',
    alt_description: image.altText ?? '',
    presets: image.presets as never,
    crop: image.crop
      ? {
          height: image.crop.height,
          width: image.crop.width,
          x: image.crop.x,
          y: image.crop.y,
          natural_height: 0, // doesnt apply to voting
          natural_width: 0,
        }
      : null,
    _object: 'image',
    _url: image.presets?.large?.url,
  };
};
