import * as React from 'react';
import { LoaderIcon } from '@mentimeter/ragnar-visuals';
import { postIntegrationMessage } from '@mentimeter/integrations-shared';
import { Button } from '@mentimeter/ragnar-ui/button';
import { useSeriesIdByKey } from '../desktop-experience/useSeriesIdByKey';
import { useAppSelector } from '../../redux-hooks';
import { useIntegration } from '../integrations';
import { voteKeySelector } from '../../selectors';

interface SendMeResultsButtonProps {
  onClick: () => void;
}

const MENTIMETER_URL = process.env.NEXT_PUBLIC_WWW_URL || 'mentimeter.com';

export const SendMeResultsButton = ({ onClick }: SendMeResultsButtonProps) => {
  const { integrationId, isInternalIntegration } = useIntegration();
  const voteKey = useAppSelector(voteKeySelector);
  const seriesId = useSeriesIdByKey(voteKey, true);
  const presentationUrl = `/app/presentation/${seriesId}/view?source=request-result&utm_source=govote&utm_medium=button`;

  const clickHandler = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      onClick();

      if (!seriesId) {
        // no seriesId loaded for some reason, do nothing
        return;
      }

      if (!isInternalIntegration) {
        // don't send event if not internal integration
        return;
      }

      // For integrations: this sends an event to the frame holding the voting web
      e.preventDefault();
      postIntegrationMessage(
        window?.parent,
        {
          type: 'open-url',
          payload: MENTIMETER_URL + presentationUrl,
        },
        MENTIMETER_URL || '*',
      );
    },
    [onClick, seriesId, isInternalIntegration, presentationUrl],
  );

  if (!seriesId) {
    return <LoaderIcon />;
  }

  return (
    <Button
      size="large"
      aria-label="View the results"
      onClick={clickHandler}
      target={integrationId && !isInternalIntegration ? '_blank' : '_self'}
      rel={
        integrationId && !isInternalIntegration
          ? 'noopener noreferrer'
          : undefined
      }
      href={MENTIMETER_URL + presentationUrl}
      py="space4"
      px="space8"
    >
      View the results
    </Button>
  );
};
