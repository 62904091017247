import { useVotingContext } from '@mentimeter/question-modules-contexts';
import { VotingConfirmationModal } from '@mentimeter/voting-ui';
import { useCallback, useEffect, useState } from 'react';
import type { QuestionWithSlide } from '@mentimeter/voting-schema/api-types-overrides';
import type { SubmitVoteBody } from '@mentimeter/question-modules-types';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Form } from '@mentimeter/ragnar-ui/form';
import { Text } from '@mentimeter/ragnar-ui/text';
import { SubmitVoteFormButton } from '../../ui/Components/SubmitVoteFormButton';
import { InputField } from './components/InputField';
import { range } from './utils/range';
import { getVoteText } from './utils/getVoteText';

function createSubmitVoteBody(
  question: QuestionWithSlide,
  rawVote: string,
  partial: boolean,
): SubmitVoteBody {
  if (question.isMigrated) {
    const firstICChoiceId =
      question.interactiveContents[0]!.choices[0]!.interactiveContentChoiceId;

    return {
      isMigrated: true,
      slidePublicKey: question.slidePublicKey,
      interactiveContentId:
        question.interactiveContents[0]!.interactiveContentId,
      payload: {
        type: 'word-cloud',
        choices: [
          {
            interactiveContentChoiceId: firstICChoiceId,
            value: rawVote,
          },
        ],
      },
      partial,
    };
  }
  return {
    isMigrated: false,
    questionPublicKey: question.public_key,
    payload: { type: 'wordcloud', vote: rawVote },
    partial,
  };
}

export const Interactive = () => {
  const { useTranslate, useActions, useQuestion, usePresentation } =
    useVotingContext();
  const { emojiFilterEnabled, voteId } = usePresentation();
  const question = useQuestion();
  const { vote: submitVote, skip } = useActions();
  const translate = useTranslate();
  const [showConfirmSkipModal, setShowConfirmSkipModal] = useState(false);
  const [showInputWillBeSkippedModal, setShowInputWillBeSkippedModal] =
    useState(false);
  const [showEnteredVoteCodeModal, setShowEnteredVoteCodeModal] =
    useState(false);

  const [voteAgain, setVoteAgain] = useState(false);
  const [submitFeedback, setSubmitFeedback] = useState(false);
  const emptyInput = () => range(question.max_nb_words).map(() => '');
  const [values, setValues] = useState<string[]>(emptyInput);

  const allModalsAreClosed =
    !showConfirmSkipModal &&
    !showInputWillBeSkippedModal &&
    !showEnteredVoteCodeModal;

  useEffect(() => {
    if ((question.max_nb_words || 0) - values.length === 0) return;

    // Update the length of the values array if the max_nb_words changes
    setValues((original) =>
      range(question.max_nb_words).map((_, i) => original[i] || ''),
    );
  }, [question.max_nb_words, values]);

  const onChange = useCallback((index: number, value: string) => {
    setSubmitFeedback(false);
    setValues((values) => {
      values[index] = value;
      return [...values];
    });
  }, []);

  const handleNext = () => {
    if (!allModalsAreClosed) {
      return;
    }

    if (values.some((w) => w !== '')) {
      setShowInputWillBeSkippedModal(true);
    } else {
      return skip();
    }
  };

  const handleSubmit = () => {
    // Clean up words
    const words = values.slice(0, question.max_nb_words).filter(Boolean);

    const noWords = words.length === 0;
    if (noWords) {
      setShowConfirmSkipModal(true);
      return;
    }

    const hasEnteredVoteId =
      voteId && words.some((word) => word.replace(/\s/g, '') === voteId);
    if (hasEnteredVoteId) {
      setShowEnteredVoteCodeModal(true);
      return;
    }

    submitVote(
      createSubmitVoteBody(
        question,
        getVoteText(words),
        Boolean(question.multiple_votes),
      ),
    );

    setValues(emptyInput());
    setVoteAgain(Boolean(question.multiple_votes));
    setSubmitFeedback(true);
  };

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        if (allModalsAreClosed) {
          handleSubmit();
        }
      }}
      alignItems="stretch"
      width="100%"
    >
      <Box gap="space6">
        {values.map((value, index) => {
          const placeholder =
            index === 0
              ? translate('word_cloud.enter_word')
              : translate('word_cloud.enter_another_word');

          return (
            <InputField
              key={`wordcloud-input-${index}`}
              onChange={(value) => onChange(index, value)}
              value={value}
              placeholder={placeholder}
              translate={translate}
              emojiFilterEnabled={emojiFilterEnabled}
              dataTestId={`vote-input-${index}`}
            />
          );
        })}
      </Box>

      {question.multiple_votes && (
        <Text as="p" color="textWeaker" mt="space4" lineHeight="relaxed">
          {translate('messages.can_multiple_answers')}
        </Text>
      )}

      {submitFeedback && question.multiple_votes && (
        <Text as="p" color="textWeaker" mt="space4" lineHeight="relaxed">
          {translate('word_cloud.thanks_input_add_another')}
        </Text>
      )}

      <SubmitVoteFormButton
        voteAgain={voteAgain}
        handleSkipVoteAgain={handleNext}
      />

      <VotingConfirmationModal
        id="skip-vote-modal-world-cloud"
        showModal={showConfirmSkipModal}
        onConfirm={skip}
        onDismiss={() => setShowConfirmSkipModal(false)}
        title={translate('messages.no_input')}
        bodyText={translate('messages.continue_without_voting')}
        confirmButtonText={translate('buttons.ok')}
        dismissButtonText={translate('buttons.cancel')}
      />
      <VotingConfirmationModal
        id="skip-vote-modal-world-cloud-has-input"
        showModal={showInputWillBeSkippedModal}
        onConfirm={skip}
        onDismiss={() => setShowInputWillBeSkippedModal(false)}
        title={translate('messages.continue_without_voting')}
        confirmButtonText={translate('buttons.ok')}
        dismissButtonText={translate('buttons.cancel')}
      />
      <VotingConfirmationModal
        id="modal-world-cloud-vote-code-entered"
        showModal={showEnteredVoteCodeModal}
        onConfirm={() => setShowEnteredVoteCodeModal(false)}
        onDismiss={() => setShowEnteredVoteCodeModal(false)}
        title={translate('messages.already_joined_presentation')}
        confirmButtonText={translate('buttons.ok')}
        dismissButtonText={translate('buttons.cancel')}
      />
    </Form>
  );
};
