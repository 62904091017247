import { create } from 'zustand';

export interface AuthenticationStore {
  authenticationNeeded: boolean;
  redirectUrl?: string;
  identityMode?: string;
  setAuthenticationNeeded: (authenticationNeeded: boolean) => void;
  setRedirectUrl: (redirectUrl: string) => void;
  setIdentityMode: (identityMode: string) => void;
}

export const useAuthenticationStore = create<AuthenticationStore>((set) => ({
  authenticationNeeded: false,
  redirectUrl: '',
  identityMode: '',
  setAuthenticationNeeded: (authenticationNeeded) =>
    set(() => ({
      authenticationNeeded,
    })),
  setRedirectUrl: (redirectUrl) =>
    set(() => ({
      redirectUrl,
    })),
  setIdentityMode: (identityMode) =>
    set(() => ({
      identityMode,
    })),
}));
