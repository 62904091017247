import { useVotingContext } from '@mentimeter/question-modules-contexts';
import { addUnit } from '@mentimeter/ragnar-utils';
import { TextMarkupRenderer } from '@mentimeter/question-modules-shared';
import type { TextT } from '@mentimeter/ragnar-ui/text';
import { Text } from '@mentimeter/ragnar-ui/text';

interface VotingDescriptionProps extends TextT {
  description?: string | undefined;
  descriptionStyled?: Record<string, unknown> | undefined;
}

export function VotingDescription({
  description,
  descriptionStyled,
  ...props
}: VotingDescriptionProps) {
  const {
    ThemeStyled: { StringParser },
  } = useVotingContext();

  const isDescriptionStyledEmpty: boolean =
    Object.keys(descriptionStyled || {}).length === 0;

  if (!description && isDescriptionStyledEmpty) return null;

  return (
    <Text
      as="p"
      lineHeight="relaxed"
      fontWeight="regular"
      color="text"
      fontSize="100"
      truncate={false}
      {...props}
      extend={({ theme }) => ({
        ...props.extend?.({ theme }),
        '& h1': {
          fontSize: addUnit(theme.kosmosFontSize['175']),
          lineHeight: theme.kosmosLineHeight.relative.snug,
          fontWeight: theme.fontWeights.regular,
          marginBottom: addUnit(theme.kosmosSpacing.space3),
        },
        '& h2': {
          fontSize: addUnit(theme.kosmosFontSize['175']),
          lineHeight: theme.kosmosLineHeight.relative.snug,
          fontWeight: theme.fontWeights.regular,
          marginBottom: addUnit(theme.kosmosSpacing.space3),
        },
        '& h3': {
          fontSize: addUnit(theme.kosmosFontSize['175']),
          lineHeight: theme.kosmosLineHeight.relative.snug,
          fontWeight: theme.fontWeights.regular,
          marginBottom: addUnit(theme.kosmosSpacing.space3),
        },
        '& blockquote': {
          fontSize: addUnit(theme.kosmosFontSize['175']),
          lineHeight: theme.kosmosLineHeight.relative.snug,
          fontWeight: theme.fontWeights.regular,
          marginBottom: addUnit(theme.kosmosSpacing.space3),
        },
        '& ol': {
          paddingLeft: addUnit(theme.kosmosSpacing.space6),
        },
        '& ul': {
          paddingLeft: addUnit(theme.kosmosSpacing.space6),
        },
      })}
    >
      {isDescriptionStyledEmpty ? (
        <StringParser
          source={description || ''}
          disableLineBreaks={false}
          allowBlankLines
        />
      ) : (
        <TextMarkupRenderer
          content={descriptionStyled || ''}
          allowBlockStyles
          hideColors
        />
      )}
    </Text>
  );
}
