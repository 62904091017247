import * as React from 'react';
import { Button } from '@mentimeter/ragnar-ui/button';
import { useTranslate } from '../localization/Translate';

export interface QfaButtonProps {
  onClick: () => void;
}

export const QfaButton = ({ onClick }: QfaButtonProps) => {
  const translate = useTranslate();

  return (
    <Button size="large" onClick={onClick} width="100%">
      {translate('buttons.open_qa')}
    </Button>
  );
};
