/* eslint-disable menti-react/filename-convention--jsx */
import { Fragment, useMemo } from 'react';
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import type { QuestionChoice } from '@mentimeter/http-clients';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Box } from '@mentimeter/ragnar-ui/box';
import { List } from '@mentimeter/ragnar-ui/list';
import { TitleSectionWithImage } from '../../ui/Components/VotingTitle';

const MAX_BULLET_DEPTH = 1;

interface Indentations {
  string: string;
  indentations: number;
  space: string;
}

interface Item {
  item: string;
  subItems: string[];
}

export const getIndentations = (input: string): Indentations => {
  let output = input;
  const match = input.match(/^(-|\*)+\s/g);
  let indentations =
    match !== null && match[0] !== undefined ? match[0].length - 1 : 0;
  if (indentations > 0) {
    output = output.substring(indentations);
  }
  indentations = Math.max(0, Math.min(indentations, MAX_BULLET_DEPTH));
  return {
    string: output,
    indentations,
    space: `${indentations}em`,
  };
};

const Item = ({ text, space }: { text: string; space?: string }) => {
  const {
    ThemeStyled: { StringParser },
  } = useVotingContext();
  return (
    <Text ml={space} color="text" lineHeight="relaxed" as="li">
      <StringParser source={text} disableLineBreaks />
    </Text>
  );
};

const NumberedList = ({ bullets }: { bullets: QuestionChoice[] }) => {
  const numberedList = useMemo(() => {
    let bulletIndex = -1;
    const numberedList: Item[] = [];
    bullets.forEach((item) => {
      const { string, indentations } = getIndentations(item.label);
      if (indentations > 0) {
        if (numberedList[bulletIndex]) {
          numberedList[bulletIndex]!.subItems.push(string);
        } else {
          numberedList.push({
            item: '',
            subItems: [string],
          });
          bulletIndex++;
        }
      } else {
        numberedList.push({ item: string, subItems: [] });
        bulletIndex++;
      }
    });
    return numberedList;
  }, [bullets]);

  return (
    <List alignItems="stretch" as="ol" pl="space6">
      {numberedList.map(({ item, subItems }) => (
        <Fragment key={`item-${item}`}>
          {item ? <Item text={item} /> : null}
          <Box ml="1em">
            <List as="ol" type="a">
              {subItems.map((subItem) => (
                <Item key={`item-${subItem}`} text={subItem} />
              ))}
            </List>
          </Box>
        </Fragment>
      ))}
    </List>
  );
};

const BulletList = ({ bullets }: { bullets: QuestionChoice[] }) => (
  <List alignItems="stretch" as="ul" pl="space6">
    {bullets.map((item) => {
      const { space, string } = getIndentations(item.label);
      return <Item key={`item-${item.id}`} text={string} space={space} />;
    })}
  </List>
);

export function Content() {
  const { useQuestion, useBullets } = useVotingContext();
  const { title_meta, image, question, numbered_list } = useQuestion();
  const bullets = useBullets();

  return (
    <Box width="100%" alignItems="stretch" aria-live="polite" mb={3}>
      <TitleSectionWithImage title={question} meta={title_meta} image={image} />
      {numbered_list ? (
        <NumberedList bullets={bullets} />
      ) : (
        <BulletList bullets={bullets} />
      )}
    </Box>
  );
}
