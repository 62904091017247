import { useVotingContext } from '@mentimeter/question-modules-contexts';
import React from 'react';
import { emojiRegex } from '@mentimeter/emojis';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import {
  CharacterCount,
  CharacterCountWrap,
} from '@mentimeter/ragnar-ui/character-count';
import { Form } from '@mentimeter/ragnar-ui/form';
import { Label } from '@mentimeter/ragnar-ui/label';
import { Text } from '@mentimeter/ragnar-ui/text';
import { TextInputItem } from '@mentimeter/ragnar-ui/input/text-input';

const MAX_CHARACTER_INPUT = 30;
const MULTIPLE_WHITESPACE_REGEX = / +(?= )/g;
const disabledChars = new RegExp(
  '[' +
    // basic latin & latin supplement (keeping accents)
    '!-&(-,\u002E\u002F\u003A-\u0040' +
    '\u005B-\u005E\u007B-\u00A7\u00A9\u00B3\u00B5\u00BF\u00D7\u00F7' +
    // general punctuation
    '\u2016-\u205E' +
    // arrows, mathematical operators, and other symbols
    '\u2100-\u2319' +
    '\u231C-\u25FF' +
    ']+',
  'g',
);

const sanitizeName = (value: string) => {
  return value
    .replace(disabledChars, '')
    .replace(emojiRegex, '')
    .replace(MULTIPLE_WHITESPACE_REGEX, '');
};

export const PlayerForm = ({
  defaultName,
  placeholder,
  onSubmit,
}: {
  defaultName: string;
  placeholder: string;
  onSubmit: (name: string) => void;
}) => {
  const { useTranslate } = useVotingContext();
  const translate = useTranslate();

  const [name, setName] = React.useState(defaultName);
  const [error, setError] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);

  return (
    <Form
      mt="space6"
      width="100%"
      alignItems="stretch"
      onSubmit={(e) => {
        e.preventDefault();
        setSubmitted(true);
        // Fallback to the Emoji name on empty submit
        onSubmit(name || placeholder);
      }}
    >
      <Label htmlFor="quiz-name">{translate('state.enter_name')}</Label>
      {/**
       * Based on applications/voting/src/ui/Screens/Vote/QuestionTypes/Quiz/LobbyContent.tsx
       * and as our TextInput seems to have an internal state, one can not validate
       */}
      <CharacterCountWrap>
        <TextInputItem
          data-testid="quiz-player-name"
          id="quiz-name"
          name="quiz-name"
          aria-describedby="name-character-counter"
          maxLength={30}
          placeholder={placeholder}
          value={name}
          onChange={(e) => {
            setSubmitted(false);
            setError(false);
            const value = e.target.value;
            const sanitized = sanitizeName(value);
            if (value !== sanitized) {
              setError(true);
            }
            setName(sanitized);
          }}
        />
        <CharacterCount
          id="name-character-counter"
          maxLength={MAX_CHARACTER_INPUT}
          value={name}
        />
      </CharacterCountWrap>
      {error && (
        <Text mt="space2" fontSize="87.5" textAlign="center">
          {translate('word_cloud.fields_special_char')}
        </Text>
      )}
      <Box alignItems="center">
        <Button
          size="large"
          mt="space6"
          mb="space4"
          type="submit"
          variant="primary"
          data-testid="quiz-player-form-submit"
          disabled={submitted}
          state={submitted ? 'loading' : undefined}
        >
          {translate('buttons.join')}
        </Button>
      </Box>
    </Form>
  );
};
