import type { PaceMode, VotingSeries } from '@mentimeter/http-clients';
import type { SeriesWithSlideDeck as VotingSeriesWithSlideDeck } from '@mentimeter/voting-schema/api-types-overrides';
import type { ParticipationSettings } from '@core-api/audience/types/response';
import {
  PARTICIPATION_MODE_SURVEY,
  PARTICIPATION_MODE_PRESENTATION,
  PARTICIPATION_POLICY_NO_RESTRICTION,
  PARTICIPATION_POLICY_SINGLE_SUBMISSION,
} from '../../../constants';

export const seriesPaceToSlideDeckParticipationMode = (
  mode: PaceMode,
): ParticipationSettings['participationMode'] => {
  return mode === 'audience'
    ? PARTICIPATION_MODE_SURVEY
    : PARTICIPATION_MODE_PRESENTATION;
};

export const seriesVoteAgainEnabledToSlideDeckParticipationPolicy = (
  voteAgainEnabled: boolean,
): ParticipationSettings['participationPolicy'] => {
  return voteAgainEnabled
    ? PARTICIPATION_POLICY_NO_RESTRICTION
    : PARTICIPATION_POLICY_SINGLE_SUBMISSION;
};

export const votingSeriesPaceToVotingSlideDeckParticipationSettings = (
  series: VotingSeries | VotingSeriesWithSlideDeck,
): ParticipationSettings => {
  const participationMode = seriesPaceToSlideDeckParticipationMode(
    series.pace.mode,
  );

  const participationPolicy =
    seriesVoteAgainEnabledToSlideDeckParticipationPolicy(
      series.vote_again_enabled,
    );

  const participationIdentityMode =
    series.participation_identity_mode ?? 'anonymous';

  return {
    participationKey: series.vote_key,
    participationMode,
    participationPolicy,
    participationIdentityMode,
    participationCode: '', // TODO: Delete this after BE type is updated
  };
};
