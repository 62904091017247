import type { TranslationFn } from '@mentimeter/question-modules-types';
import { addUnit } from '@mentimeter/ragnar-utils';
import { PORTAL_ID_VOTING_PANEL_BOTTOM_BAR } from '@mentimeter/voting-ui';
import React from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import { PortalOld } from '@mentimeter/ragnar-ui/portal';
import { Text } from '@mentimeter/ragnar-ui/text';

interface BottomBarT {
  votesLeft: number;
  isLoading: boolean;
  onSubmit: () => void;
  translate: TranslationFn;
}

export const BottomBar = ({
  votesLeft,
  isLoading,
  onSubmit,
  translate,
}: BottomBarT) => {
  return (
    <PortalOld domId={PORTAL_ID_VOTING_PANEL_BOTTOM_BAR}>
      <Box
        alignItems="center"
        width="100%"
        bg="bg"
        borderColor="borderWeak"
        borderStyle="solid"
        borderTopWidth="1px"
        zIndex={2}
      >
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          maxWidth="600px"
          py="space4"
          px={['space10', 'space4']}
          extend={({ theme }) => ({
            '@media screen and (max-width: 352px)': {
              paddingLeft: addUnit(theme.kosmosSpacing.space1),
              paddingRight: addUnit(theme.kosmosSpacing.space1),
              width: '272px',
            },
          })}
        >
          <Box
            flexDirection="row"
            flex="1 1 auto"
            alignItems="center"
            data-testid="votes-left"
            aria-live="assertive"
            aria-atomic="true"
          >
            {votesLeft > 0 ? (
              <Text lineHeight="relaxed">
                {votesLeft === 1
                  ? translate('open_ended.vote_left', { nrOfVotes: votesLeft })
                  : translate('open_ended.votes_left', {
                      nrOfVotes: votesLeft,
                    })}
              </Text>
            ) : (
              <Text lineHeight="relaxed">
                {translate('open_ended.no_votes_remaining')}
              </Text>
            )}
          </Box>
          <Button
            size="large"
            variant="primary"
            onClick={onSubmit}
            state={isLoading ? 'loading' : undefined}
            disabled={isLoading}
            data-testid="submit-votes"
          >
            {translate('open_ended.submit_votes')}
          </Button>
        </Box>
      </Box>
    </PortalOld>
  );
};
