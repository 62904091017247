import {
  addBreadcrumb,
  captureException,
  MentiError,
} from '@mentimeter/errors/sentry';
import type { ReactionPayload } from '@mentimeter/http-clients';
import {
  wasHandledNetworkError,
  wasMissingResponseError,
} from '@mentimeter/http-clients';
import throttle from 'lodash/throttle';
import * as seriesApi from '../../api/series';

const REACTION_THROTTLE_IN_MS = 1000;

// The sendReaction method will be throttled after REACTION_THROTTLE requests to
// avoid "hammering" on the api/vote endpoint. It will instead "count" the amount of reactions
// the user want to send and add this number to the api/slide request. This is done
// to keep the "live feeling" but at the same time not hammering on our back end too much.
let reactionAmountCounter = 0; // This will keep the amount of reactions to send to the back end

const publishReactionThrottled = throttle(
  async (questionId: string, voteKey: string, payload: ReactionPayload) => {
    payload.amount = reactionAmountCounter;
    reactionAmountCounter = 0;
    try {
      await seriesApi.publishReaction(questionId, voteKey, payload);
    } catch (err: any) {
      if (wasMissingResponseError(err)) {
        // The request was made but no response was received
        addBreadcrumb({
          message: 'No response on publishReaction',
          category: 'network',
          level: 'warning',
          data: { payload },
        });
      } else if (!wasHandledNetworkError(err)) {
        captureException(
          new MentiError('send reaction throttled failed', {
            cause: err,
            feature: 'live',
          }),
        );
      }
    }
  },
  REACTION_THROTTLE_IN_MS,
);

export function publishReaction(
  questionId: string,
  voteKey: string,
  payload: ReactionPayload,
) {
  reactionAmountCounter += 1;
  publishReactionThrottled(questionId, voteKey, payload);
}
