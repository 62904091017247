import type { VotingSeries } from '@mentimeter/http-clients';
import type { SlideDeck as VotingSlideDeck } from '@mentimeter/voting-schema/api-types-overrides';
import { getOrSetCache, type WithCache } from '../../../utils/get-or-set-cache';
import { seriesCfaPropertiesToLiveChatSettings } from '../../../forward-compatibility/converters/series/series-cfa-properties-to-live-chat-settings';
import { seriesReactionsToSlideDeckReactionSettings } from '../../../forward-compatibility/converters/series/series-reactions-to-slidedeck-reaction-settings';
import { seriesLanguageToLanguageSettings } from '../../../forward-compatibility/converters/series/series-language-to-language-settings';
import { createVotingSlideCompatibilityLayer } from '../slide-compatibility-layer/create-voting-slide-compatibility-layer';
import { votingSeriesPaceToVotingSlideDeckParticipationSettings } from './voting-series-pace-to-voting-slidedeck-participation-settings';
import { votingSeriesQaPropertiesToQaSettings } from './voting-series-qa-properties-to-qa-settings';
import { votingSeriesResultsSharingToSlideDeckResultsSharing } from './voting-series-results-sharing-to-slidedeck-results-sharing';

export function getUnmigratedVotingSlideDeckProperty(
  target: VotingSeries,
  key: keyof VotingSeries | keyof VotingSlideDeck | '__isProxy' | '__target',
) {
  switch (key) {
    case 'languageSettings':
      const languageSettings = seriesLanguageToLanguageSettings(
        target as VotingSeries,
      );
      return getOrSetCache(
        target as WithCache<VotingSeries>,
        'languageSettings',
        JSON.stringify(languageSettings),
        languageSettings,
      );

    case 'legacyThemeSettings':
      const legacyThemeSettings = target.theme;

      return getOrSetCache(
        target as WithCache<VotingSeries>,
        'legacyThemeSettings',
        JSON.stringify(legacyThemeSettings),
        legacyThemeSettings,
      );

    case 'liveChatSettings':
      const liveChatSettings = seriesCfaPropertiesToLiveChatSettings(
        target as VotingSeries,
      );
      return getOrSetCache(
        target as WithCache<VotingSeries>,
        'liveChatSettings',
        JSON.stringify(liveChatSettings),
        liveChatSettings,
      );

    case 'name':
      return target.name;

    case 'ownershipSettings':
      const ownershipSettings =
        votingSeriesResultsSharingToSlideDeckResultsSharing(
          target as VotingSeries,
        );

      return getOrSetCache(
        target as WithCache<VotingSeries>,
        'ownershipSettings',
        JSON.stringify(ownershipSettings),
        ownershipSettings,
      );

    case 'participationSettings':
      const participationSettings =
        votingSeriesPaceToVotingSlideDeckParticipationSettings(
          target as VotingSeries,
        );

      return getOrSetCache(
        target as WithCache<VotingSeries>,
        'participationSettings',
        JSON.stringify(participationSettings),
        participationSettings,
      );

    case 'qaSettings':
      const qaSettings = votingSeriesQaPropertiesToQaSettings(
        target as VotingSeries,
      );
      return getOrSetCache(
        target as WithCache<VotingSeries>,
        'qaSettings',
        JSON.stringify(qaSettings),
        qaSettings,
      );

    case 'reactionSettings':
      const reactionSettings = seriesReactionsToSlideDeckReactionSettings(
        target as VotingSeries,
      );
      return getOrSetCache(
        target as WithCache<VotingSeries>,
        'reactionSettings',
        JSON.stringify(reactionSettings),
        reactionSettings,
      );

    case 'slides': {
      const slides = target.questions.map((question) => {
        return createVotingSlideCompatibilityLayer(question);
      });

      return getOrSetCache(
        target as WithCache<VotingSeries>,
        'slides',
        JSON.stringify(slides),
        slides,
      );
    }

    case 'questions': {
      const questions = target.questions.map((question) => {
        return createVotingSlideCompatibilityLayer(question);
      });

      return getOrSetCache(
        target as WithCache<VotingSeries>,
        'questions',
        JSON.stringify(questions),
        questions,
      );
    }

    case 'themeId':
      return target.theme_id;

    default:
      return target[key as keyof VotingSeries];
  }
}
