'use client';
import * as React from 'react';
import { useAppSelector } from '../redux-hooks';
import { requestAudienceConnection } from '../api/audience';
import { voteKeySelector } from '../selectors';
import { useAudienceConnectStore } from './useAudienceConnectStore';

export const useAudienceConnect = ({
  readyToConnect,
  shouldRequestDesktopExperience,
}: {
  readyToConnect: boolean;
  shouldRequestDesktopExperience: boolean;
}) => {
  const setStatus = useAudienceConnectStore((state) => state.setStatus);
  const setDesktopExperienceLimitReached = useAudienceConnectStore(
    (state) => state.setDesktopExperienceLimitReached,
  );
  const voteKey = useAppSelector(voteKeySelector);

  React.useEffect(() => {
    if (!voteKey || !readyToConnect) return;
    async function connectAsAudienceMember() {
      const status = await requestAudienceConnection(
        voteKey,
        shouldRequestDesktopExperience,
      );
      if (status === 'desktopExperienceLimitReached') {
        setDesktopExperienceLimitReached(true);
        setStatus('connected');
      } else {
        setDesktopExperienceLimitReached(false);
        setStatus(status);
      }
    }
    connectAsAudienceMember();
  }, [
    readyToConnect,
    shouldRequestDesktopExperience,
    voteKey,
    setStatus,
    setDesktopExperienceLimitReached,
  ]);
};
