import type { OwnershipSettings } from '@core-api/audience/types/response';
import type { VotingSeries } from '@mentimeter/http-clients';

export const votingSeriesResultsSharingToSlideDeckResultsSharing = (
  series: VotingSeries,
): OwnershipSettings => {
  return {
    resultsSharing: series.results_sharing || 'off',
  };
};
