import {
  ActionToggle,
  NumberPicker,
  ResponseCard,
} from '@mentimeter/voting-ui';
import React from 'react';
import type { BoxT } from '@mentimeter/ragnar-ui/box';
import { Box } from '@mentimeter/ragnar-ui/box';

interface UpvoteControlsT
  extends Pick<
    ResponseWithUpvotingProps,
    'canHaveMultipleUpvotes' | 'onUpvote'
  > {
  upvotes: number;
  responseId: string;
}

const UpvoteControls = ({
  upvotes,
  responseId,
  onUpvote,
  canHaveMultipleUpvotes,
}: UpvoteControlsT) => {
  const onToggleChange = React.useCallback(() => {
    if (upvotes === 0) {
      onUpvote(responseId, 1);
    } else {
      onUpvote(responseId, 0);
    }
  }, [onUpvote, responseId, upvotes]);

  if (canHaveMultipleUpvotes) {
    return (
      <NumberPicker
        max={5}
        min={0}
        id={responseId}
        value={upvotes}
        onChange={(value) => onUpvote(responseId, value ?? 0)}
        addLabel="Add upvote"
        subtractLabel="Remove upvote"
      />
    );
  }

  return (
    <ActionToggle
      id={responseId}
      onChange={onToggleChange}
      checked={upvotes > 0}
    />
  );
};

interface ResponseWithUpvotingProps extends Pick<BoxT, 'mb'> {
  item: {
    id: string;
    answer: string;
  };
  onUpvote: (responseId: string, value: number) => void;
  nrOfUpvotes: number;
  canHaveMultipleUpvotes: boolean;
}
export const ResponseWithUpvoting = ({
  item,
  onUpvote,
  nrOfUpvotes,
  canHaveMultipleUpvotes,
  mb = 'space5',
}: ResponseWithUpvotingProps) => {
  const hasVotes = nrOfUpvotes > 0;
  return (
    <ResponseCard id={item.id} text={item.answer} hasVotes={hasVotes} mb={mb}>
      <Box
        width="100%"
        flexDirection="row-reverse"
        justifyContent="space-between"
        alignItems="center"
        mt="space5"
      >
        <UpvoteControls
          responseId={item.id}
          upvotes={nrOfUpvotes}
          onUpvote={onUpvote}
          canHaveMultipleUpvotes={canHaveMultipleUpvotes}
        />
      </Box>
    </ResponseCard>
  );
};
