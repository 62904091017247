import type { Question } from '@mentimeter/http-clients';
import type {
  Slide as VotingSlide,
  QuestionWithSlide as VotingQuestionWithSlide,
} from '@mentimeter/voting-schema/api-types-overrides';
import { getOrSetCache, type WithCache } from '../../../utils/get-or-set-cache';
import { getQuestionStaticContent } from '../../../forward-compatibility/static-content/get-question-static-content';
import { getQuestionInteractiveContent } from '../../../forward-compatibility/interactive-content/get-question-interactive-content';
import { questionImagesToSlideImages } from '../../../forward-compatibility/converters/question/question-images-to-slide-images';
import { UnsupportedSlidePropertyError } from '../../../backward-compatibility/errors/unsupported-slide-property-error';

export const getVotingSlideProperty = (
  question: Question,
  key: keyof VotingSlide,
) => {
  switch (key) {
    case 'slidePublicKey':
      return question.public_key;

    case 'staticContent': {
      const staticContent = getQuestionStaticContent(question);
      const key = JSON.stringify(staticContent);
      return getOrSetCache(
        question as WithCache<VotingQuestionWithSlide>,
        'staticContent',
        key,
        staticContent,
      );
    }

    case 'interactiveContents': {
      const interactiveContent = getQuestionInteractiveContent(question);
      const key = JSON.stringify(interactiveContent);
      const result = getOrSetCache(
        question as WithCache<VotingQuestionWithSlide>,
        'interactiveContents',
        key,
        interactiveContent ? [interactiveContent] : [],
      );
      return result;
    }

    case 'images': {
      const images = questionImagesToSlideImages(
        question as VotingQuestionWithSlide,
      );
      const key = JSON.stringify(images);
      return getOrSetCache(
        question as WithCache<VotingQuestionWithSlide>,
        'images',
        key,
        images,
      );
    }

    case 'title':
      return question.question;

    case 'isMigrated':
      return false;

    case 'design':
      return undefined;

    default:
      const exhaustive: never = key;
      if (!Reflect.has(question, exhaustive)) {
        if (process.env.NODE_ENV === 'development') {
          const error = new UnsupportedSlidePropertyError(exhaustive);
          // eslint-disable-next-line no-console
          console.warn(error.message);
        }
      }
      return undefined;
  }
};
