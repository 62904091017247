import React from 'react';
import { Loader } from '@mentimeter/question-modules-shared';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Box } from '@mentimeter/ragnar-ui/box';

export const WaitingView = ({ children }: React.PropsWithChildren) => {
  return (
    <Box
      px={2}
      alignItems="center"
      bg="neutralWeakest"
      width="100%"
      borderRadius={2}
      mt={3}
    >
      <Text pt={4} pb={3} fontSize={2} color="text" textAlign="center">
        {children}
      </Text>
      <Loader />
    </Box>
  );
};
