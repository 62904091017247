import type { VisualizationColors } from '@mentimeter/ragnar-dsc';
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import type { QuestionDimension } from '@mentimeter/http-clients';
import { VotingSubTitle } from '@mentimeter/voting-ui';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import { Range } from '@mentimeter/ragnar-ui/range';

interface Props {
  index: number;
  id: number;
  value: number | undefined;
  label: string;
  min?: number;
  max?: number;
  step?: number;
  likert?: Array<string>;
  labels: [QuestionDimension, QuestionDimension];
  onChange: (id: number, value: any, axis?: 'x' | 'y') => void;
  onChangeOptionSkip: (id: number) => void;
  skippable: boolean;
  skipped: boolean;
  theme: VisualizationColors;
}

export function Option({
  index,
  id,
  label,
  value = 1,
  skipped,
  min = 1,
  max = 5,
  step = 1,
  likert,
  labels,
  onChange,
  onChangeOptionSkip,
  skippable,
  theme,
}: Props) {
  const {
    ThemeStyled: { StringParser },
    useTranslate,
  } = useVotingContext();
  const likertIndex = value - min;
  const translate = useTranslate();
  return (
    <Box
      as="fieldset"
      aria-disabled={skipped}
      width="100%"
      alignItems="stretch"
      mt={index !== 0 ? 'space14' : 'space0'}
    >
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        mb="space4"
      >
        <VotingSubTitle id={`option-label-${id}`} as="legend">
          <StringParser source={label} disableLineBreaks />
        </VotingSubTitle>
        {skippable && (
          <Button
            size="compact"
            aria-label={
              skipped
                ? `${translate('buttons.unskip')} ${label}`
                : `${translate('buttons.skip')} ${label}`
            }
            aria-disabled={false}
            onClick={() => onChangeOptionSkip(id)}
          >
            {skipped ? translate('buttons.unskip') : translate('buttons.skip')}
          </Button>
        )}
      </Box>
      <Box
        alignItems="stretch"
        width="100%"
        extend={({ theme: t }) => ({
          opacity: skipped ? t.opacity.faded : 1,
        })}
      >
        <Box flexDirection="row" mb="space2">
          <Text color="text">{value}</Text>
          {likert && (
            <Text ml="space2" color="text">
              <StringParser
                source={likert[likertIndex] ?? ''}
                disableLineBreaks
              />
            </Text>
          )}
        </Box>
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          mb="space4"
        >
          <Text
            color="text"
            mr="space4"
            data-testid={`voting-range-min-value-${id}`}
            aria-hidden
          >
            {min}
          </Text>
          <Range
            disabled={skipped}
            aria-valuetext={translate('scales.aria_valueinfo', {
              value,
              labelMin: labels[0].min,
              labelMax: labels[0].max,
            })}
            aria-label={label}
            id={`scales-${index}`}
            name={`scale-input-${index}`}
            min={min}
            max={max}
            step={step}
            onChange={(e) => onChange(id, e)}
            value={value}
            thumbColor={
              theme.fillColors[index % theme.fillColors.length] as string
            }
          />
          <Text
            color="text"
            ml="space4"
            data-testid={`voting-range-max-value-${id}`}
            aria-hidden
          >
            {max}
          </Text>
        </Box>
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          aria-hidden
        >
          <Text fontSize="87.5" color="textWeak">
            <StringParser source={labels[0].min} disableLineBreaks />
          </Text>
          <Text fontSize="87.5" color="textWeak" textAlign="right">
            <StringParser source={labels[0].max} disableLineBreaks />
          </Text>
        </Box>
      </Box>
    </Box>
  );
}
