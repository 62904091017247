/* eslint-disable menti-react/filename-convention--jsx */
import { useState } from 'react';
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import { VotingConfirmationModal } from '@mentimeter/voting-ui';
import type { SubmitVoteBody } from '@mentimeter/question-modules-types';
import type { QuestionWithSlide } from '@mentimeter/voting-schema/api-types-overrides';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Form } from '@mentimeter/ragnar-ui/form';
import { SubmitVoteFormButton } from '../../ui/Components/SubmitVoteFormButton';
import { TitleSectionWithImage } from '../../ui/Components/VotingTitle';
import { VoteOption } from './VoteOption';

const POOL = 100;
const STEP = 10;

export function Content() {
  const { useQuestion } = useVotingContext();
  const question = useQuestion();

  return (
    <TitleSectionWithImage
      image={question.image}
      meta={question.title_meta}
      title={question.question}
      titleStyled={question.question_styled}
      description={question.question_description}
    />
  );
}

type PointsByLegacyChoiceId = Record<
  QuestionWithSlide['choices'][0]['id'],
  number
>;

function createSubmitVoteBody(
  question: QuestionWithSlide,
  rawVote: PointsByLegacyChoiceId,
): SubmitVoteBody {
  if (question.isMigrated) {
    const interactiveContentChoiceIds = Object.entries(rawVote).map(
      ([rawVoteChoiceId, points]) => {
        const choice = question.interactiveContents[0]!.choices.find(
          (c) => c.legacyChoiceId?.toString() === rawVoteChoiceId,
        );
        return {
          interactiveContentChoiceId: choice!.interactiveContentChoiceId,
          value: points,
        };
      },
    );
    return {
      isMigrated: true,
      slidePublicKey: question.slidePublicKey,
      interactiveContentId:
        question.interactiveContents[0]!.interactiveContentId,
      payload: {
        type: 'prioritization',
        choices: interactiveContentChoiceIds,
      },
      partial: false,
    };
  }
  return {
    isMigrated: false,
    questionPublicKey: question.public_key,
    payload: { type: 'prioritisation', vote: rawVote },
    partial: false,
  };
}

export function Interactive() {
  const { useQuestion, useActions, useTranslate } = useVotingContext();
  const { vote: submitVote } = useActions();
  const question = useQuestion();
  const translate = useTranslate();

  const [showModal, setShowModal] = useState(false);
  const [values, setValues] =
    useState<PointsByLegacyChoiceId>(initializeValues);

  const usedPoints = Object.values(values).reduce((acc, curr) => acc + curr, 0);
  const pointsLeft = POOL - usedPoints;

  function initializeValues() {
    return question.choices.reduce((hash, choice) => {
      hash[choice.id] = 0;
      return hash;
    }, {} as PointsByLegacyChoiceId);
  }

  function onChange(id: number, value: number) {
    setValues({ ...values, [id]: value });
  }

  function onSubmitVote() {
    if (showModal) {
      setShowModal(false);
    }
    submitVote(createSubmitVoteBody(question, values));
  }

  return (
    <Box width="100%" alignItems="stretch">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          if (showModal === false && pointsLeft > 0) {
            setShowModal(true);
          } else {
            onSubmitVote();
          }
        }}
        width="100%"
      >
        <Box
          width="100%"
          alignItems="stretch"
          gap="space14"
          pt="space2"
          pb="space6"
        >
          {question.choices.map((choice) => (
            <VoteOption
              key={choice.id}
              id={choice.id}
              title={choice.label}
              value={values[choice.id]}
              onChange={onChange}
              pointsLeft={pointsLeft}
              pool={POOL}
              step={STEP}
              translate={translate}
            />
          ))}
        </Box>
        <SubmitVoteFormButton />
      </Form>
      <VotingConfirmationModal
        id="skip-vote-modal-100-points"
        showModal={showModal}
        onConfirm={onSubmitVote}
        onDismiss={() => setShowModal(false)}
        title={translate('prioritisation.not_100_points')}
        confirmButtonText={translate('buttons.ok')}
        dismissButtonText={translate('buttons.cancel')}
      />
    </Box>
  );
}
