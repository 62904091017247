import * as React from 'react';
import useSWR from 'swr';
import { isAxiosError } from '@mentimeter/http-clients';
import { useAppDispatch } from '../redux-hooks';
import { refreshSeriesSuccess } from '../reducers/seriesSlice';
import { useAuthenticationStore } from '../features/auth/authenticationStore';
import { getSlideDeckByKey } from '../api/slideDeck';

interface AuthenticationInstructionsResponse {
  message: string;
  series: { identity_mode: string };
  redirect_url: string;
}

const hardReloadPageTimeout = process.env.NEXT_PUBLIC_HARD_RELOAD_PAGE_MS
  ? Number.parseInt(process.env.NEXT_PUBLIC_HARD_RELOAD_PAGE_MS, 10)
  : 1000 * 60 * 60 * 24;

const createdAt = Date.now();

export function useRefreshSeries(voteKey: string, enableAutoRefresh = true) {
  const dispatch = useAppDispatch();
  const { setIdentityMode, setAuthenticationNeeded, setRedirectUrl } =
    useAuthenticationStore();

  const { mutate } = useSWR(
    `/slide-deck/${voteKey}`,
    async () => {
      try {
        const seriesOrSlideDeck = await getSlideDeckByKey(voteKey);
        setAuthenticationNeeded(false);
        dispatch(refreshSeriesSuccess(seriesOrSlideDeck));
        return seriesOrSlideDeck;
      } catch (e: unknown) {
        if (
          isAxiosError<AuthenticationInstructionsResponse>(e) &&
          e.response?.status === 401 &&
          e.response.data.message === 'Authentication is required'
        ) {
          const data = e.response.data;
          setAuthenticationNeeded(true);
          setIdentityMode(data.series.identity_mode);
          setRedirectUrl(data.redirect_url);
        }

        // Ignore fails on refresh
        // TODO: Does it make sense to act on errors here?
        return;
      }
    },
    {
      refreshInterval: 0,
      revalidateOnFocus: enableAutoRefresh,
      revalidateOnReconnect: enableAutoRefresh,
    },
  );

  return React.useCallback(() => {
    const isExpired =
      createdAt > 0 && Date.now() - createdAt > hardReloadPageTimeout;
    if (isExpired) window.location.reload();
    else mutate();
  }, [mutate]);
}
