import React from 'react';
import type { TextT } from '@mentimeter/ragnar-ui/text';
import { Text } from '@mentimeter/ragnar-ui/text';

interface VotingSubTitleT extends TextT {
  id?: string;
  children: React.ReactNode;
}

export const VotingSubTitle = ({ children, id, ...props }: VotingSubTitleT) => {
  return (
    children && (
      <Text
        fontFamily="heading"
        lineHeight="150"
        fontWeight="semiBold"
        color="text"
        fontSize="112.5"
        truncate={false}
        style={{ wordBreak: 'break-word' }}
        id={id}
        {...props}
      >
        {children}
      </Text>
    )
  );
};
