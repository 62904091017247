import * as React from 'react';
import { CheckIcon } from '@mentimeter/ragnar-visuals';
import { animated, type SpringValues, useTransition } from '@react-spring/web';
import { useState } from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Form } from '@mentimeter/ragnar-ui/form';
import { Button } from '@mentimeter/ragnar-ui/button';
import { Label } from '@mentimeter/ragnar-ui/label';
import { TextareaItem } from '@mentimeter/ragnar-ui/input/textarea';
import {
  CharacterCount,
  CharacterCountWrap,
} from '@mentimeter/ragnar-ui/character-count';
import { ErrorMessage } from '@mentimeter/ragnar-ui/error-message';
import { qfaModerationEnabledSelector } from '../../selectors';
import { usePresentationState } from '../../presentation-state';
import { useAppSelector } from '../../redux-hooks';
import { useTranslate } from '../localization/Translate';
import { trackEvent } from '../../utils/tracker';

interface Props {
  onSubmit: (question: string) => void;
}

export const qfaFormTestIds = {
  formContainer: 'qfaFormTestIds_container',
  submitButton: 'qfaFormTestIds_submit_button',
  input: 'qfaFormTestIds_input',
};

export const QfaForm = ({ onSubmit }: Props) => {
  const translate = useTranslate();
  const { loading, punishment } = useAppSelector((state) => state.qfa);
  const qfaModerationEnabled = useAppSelector(qfaModerationEnabledSelector);

  const { participantIdentity } = usePresentationState();

  const [question, setQuestion] = useState('');
  const [hasSubmitted, setHasSubmitted] = React.useState(false);
  const [canSubmit, setCanSubmit] = React.useState(true);
  const [emptyQuestionError, setEmptyQuestionError] = React.useState(false);

  React.useEffect(() => {
    const currentTime = Date.now();
    // The punishement has already passed
    if (currentTime > punishment) return;

    trackEvent('QFA', 'Triggered spam message');
    setCanSubmit(false);

    const timeout = setTimeout(() => {
      setCanSubmit(true);
    }, punishment - currentTime);

    return () => clearTimeout(timeout);
  }, [punishment]);

  const showSuccess = hasSubmitted && !loading && punishment === 0;

  const getSubmitMessage = () => {
    if (!canSubmit) return translate('qfa.please_give_moment');
    else if (showSuccess && qfaModerationEnabled)
      return translate('qfa.moderation_message');
    else if (showSuccess) return translate('qfa.thank_you');
    else return null;
  };

  const describedByIds = [
    'question-character-counter',
    emptyQuestionError && !question && 'error-msg-feedback',
  ]
    .filter((id) => id)
    .join(' ');

  return (
    <Box width="100%" height="100%" flex="1 1 auto">
      <SubmitFeedback submitFeedback={getSubmitMessage()} />
      <Form
        onSubmit={(e) => {
          e.preventDefault();

          if (loading) return;
          if (question) {
            setHasSubmitted(true);
            onSubmit(question);
          } else {
            setEmptyQuestionError(true);
          }
        }}
        data-testid={qfaFormTestIds.formContainer}
        width="100%"
        style={{
          visibility: canSubmit ? 'visible' : 'hidden',
        }}
      >
        <Label
          htmlFor="qfa-form-input"
          mb="space6"
          fontSize="125"
          fontWeight="regular"
          lineHeight="snug"
        >
          {translate('qfa.new_question')}
        </Label>
        <Box
          width="100%"
          alignItems="stretch"
          justifyContent="stretch"
          mb="space10"
        >
          <CharacterCountWrap multiline>
            <TextareaItem
              data-testid={qfaFormTestIds.input}
              placeholder={translate('qfa.your_question_to_the_presenter')}
              autoComplete="off"
              id="qfa-form-input"
              name="question"
              aria-describedby={describedByIds || undefined}
              maxLength={200}
              minHeight="120px"
              value={question}
              status={emptyQuestionError && !question ? 'error' : undefined}
              onChange={(e) => {
                setEmptyQuestionError(false);
                setQuestion(e.currentTarget.value);
              }}
            />
            <CharacterCount
              id="question-character-counter"
              maxLength={200}
              value={question}
              multiline
            />
          </CharacterCountWrap>
          {emptyQuestionError && !question && (
            <ErrorMessage id="error-msg-feedback" mt="space2">
              Empty field. Please enter a question and retry.
            </ErrorMessage>
          )}
        </Box>
        <Box width="100%" alignItems="center" gap="space4">
          <Button
            size="large"
            data-testid={qfaFormTestIds.submitButton}
            variant="primary"
            state={loading ? 'loading' : undefined}
            type="submit"
          >
            {translate('buttons.submit')}
          </Button>
          {participantIdentity.status === 'is_identified' && (
            <Text py="space2" color="textWeaker">
              {translate('qfa.asking_as', {
                name: participantIdentity.name,
              })}
            </Text>
          )}
        </Box>
      </Form>
    </Box>
  );
};

const SubmitFeedback = ({
  submitFeedback,
}: {
  submitFeedback: string | null;
}) => {
  const transitions = useTransition(submitFeedback, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    config: {
      duration: 300,
    },
  });

  return (
    <>
      {transitions(
        (styles) =>
          submitFeedback && (
            <Box
              as={animated.div}
              position="absolute"
              alignItems="center"
              justifyContent="center"
              width="100%"
              height="100%"
              top={0}
              left={0}
              right={0}
              bottom={0}
              bg="bg"
              zIndex={2}
              style={styles as SpringValues}
            >
              <Box
                justifyContent="center"
                alignItems="center"
                aria-live="polite"
              >
                <Text>
                  <CheckIcon color="textWeak" />
                </Text>
                <Text textAlign="center">{submitFeedback}</Text>
              </Box>
            </Box>
          ),
      )}
    </>
  );
};
