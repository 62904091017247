import React from 'react';
import type { BoxT } from '@mentimeter/ragnar-ui/box';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';

export interface ResponseCardT extends BoxT {
  id: string;
  hasVotes?: boolean;
  text: string;
}

export const ResponseCard = ({
  id,
  hasVotes,
  text,
  children,
  ...props
}: React.PropsWithChildren<ResponseCardT>) => {
  return (
    <Box
      data-testid={`response-card-${id}`}
      bg={hasVotes ? 'secondaryWeak' : 'surfaceSunken'}
      py="space5"
      px="space4"
      borderRadius="xl"
      width="100%"
      {...props}
    >
      <Text color={hasVotes ? 'onSecondaryWeak' : 'text'} textAlign="left">
        {text}
      </Text>
      {children}
    </Box>
  );
};
