import * as React from 'react';
import type Ably from 'ably';
import {
  usePresentationRealtimeSetup,
  usePresentationRealtimeStore,
} from '@mentimeter/realtime';
import * as Sentry from '@sentry/nextjs';
import { useAppDispatch, useAppSelector } from '../redux-hooks';
import { reportAudienceDisconnected } from '../api/audience';
import { archiveResult } from '../reducers/votingSlice';
import { useReactionVotes } from '../features/reactions/useReactionVotes';
import { useIdentity } from '../features/identified-responses/useIdentity';
import { voteKeySelector } from '../selectors';
import { useRefreshSeries } from './useRefreshSeries';
import { ablyConfig } from './ablyConfig';

const ARCHIVE_RESULT_EVENT = 'archive_result';
const PRESENTATION_STATE_UPDATE_SERIES = 'presentation_state:update_series';

let shouldRefreshSeriesData = false;

const ENABLE_REALTIME_PUBLISH_DD_METRICS =
  (process.env.NEXT_PUBLIC_ENABLE_REALTIME_PUBLISH_DD_METRICS as
    | 'true'
    | 'false'
    | undefined) ?? 'false';

export function usePubSub(shouldInit: boolean) {
  const dispatch = useAppDispatch();
  const voteKey = useAppSelector(voteKeySelector);
  const clearReactionVotes = useReactionVotes(
    (state) => state.clearReactionVotes,
  );
  const ablyReady = usePresentationRealtimeSetup({
    ablyConfig,
    shouldInit,
    enableRealtimePublishDDMetricsEnvVar: ENABLE_REALTIME_PUBLISH_DD_METRICS,
  });

  const client = usePresentationRealtimeStore((state) => state.client);
  const refresh = useRefreshSeries(voteKey, ablyReady);

  const { resetIdentity } = useIdentity();

  React.useEffect(() => {
    if (!voteKey) return;
    if (!ablyReady) return;

    function onConnected() {
      Sentry.addBreadcrumb({
        type: 'Realtime',
        category: 'ably.connected',
        message: 'Ably client connected',
        level: 'info',
      });

      // Refresh series data if the client has been disconnected. For example
      // if the user temporarily lost their internet connection. This is
      // not needed on the first successful connection.
      if (shouldRefreshSeriesData) {
        refresh();
      } else {
        shouldRefreshSeriesData = true;
      }
    }

    function onHasDisconnected() {
      reportAudienceDisconnected(voteKey);
    }

    function onDisconnected() {
      Sentry.addBreadcrumb({
        type: 'Realtime',
        category: 'ably.disconnected',
        message: 'Ably client disconnected',
        level: 'info',
      });

      onHasDisconnected();
      refresh();
    }

    function messageHandler(message: Ably.Message) {
      const { event, name } = message.data;

      switch (event || name) {
        case ARCHIVE_RESULT_EVENT:
          clearReactionVotes();
          refresh();
          resetIdentity();
          dispatch(archiveResult());
          break;
        case PRESENTATION_STATE_UPDATE_SERIES:
          refresh();
          break;
        default:
          break;
      }
      Sentry.addBreadcrumb({
        type: 'Realtime',
        category: 'ably.message',
        message: `Recieved event "${event || name}"`,
        level: 'info',
      });
    }

    window.addEventListener('beforeunload', onHasDisconnected);
    if (!client) {
      return () => {
        window.removeEventListener('beforeunload', onHasDisconnected);
      };
    }
    client.connection.on('connected', onConnected);
    client.connection.on('disconnected', onDisconnected);
    client.channels
      .get(`vote_keys_${voteKey}`)
      .subscribe(messageHandler)
      .catch(() => {
        // Previously we crashed here. Should we act on subscription setup failure?
      });
    return () => {
      window.removeEventListener('beforeunload', onHasDisconnected);
      client.connection.off('connected', onConnected);
      client.connection.off('disconnected', onDisconnected);
      client.channels.get(`vote_keys_${voteKey}`).unsubscribe(messageHandler);
    };
  }, [
    ablyReady,
    clearReactionVotes,
    client,
    dispatch,
    refresh,
    resetIdentity,
    voteKey,
  ]);
}
