import { useVotingContext } from '@mentimeter/question-modules-contexts';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';
import { SkipButton } from './SkipButton';
import { SubmitButton } from './SubmitButton';

interface SubmitVoteFormButtonProps {
  allowMultipleVotes?: boolean;
  voteAgain?: boolean;
  handleSkipVoteAgain?: () => void;
}

export const SubmitVoteFormButton = ({
  allowMultipleVotes = false,
  voteAgain = false,
  handleSkipVoteAgain,
}: SubmitVoteFormButtonProps) => {
  const { useActions, useTranslate, useQuestion } = useVotingContext();
  const { skip: skipVote, error, isLoading } = useActions();
  const translate = useTranslate();
  const question = useQuestion();
  const hasClosedVoting = !question.active;

  return (
    <Box
      width="100%"
      height="100%"
      mt="space6"
      flex="1 1 auto"
      flexDirection="column"
    >
      {error === 'duplicated' && !allowMultipleVotes ? (
        <Box width="100%" alignItems="center">
          <Text fontSize="100">{translate('messages.already_responded')}</Text>
          <SkipButton
            onClick={skipVote}
            label={translate('buttons.skip_question')}
          />
        </Box>
      ) : (
        <Box width="100%" alignItems="center">
          <SubmitButton
            state={isLoading ? 'loading' : undefined}
            disabled={hasClosedVoting || isLoading}
          >
            {translate('buttons.submit')}
          </SubmitButton>
          {voteAgain && (
            <SkipButton
              onClick={handleSkipVoteAgain ?? skipVote}
              label={translate('buttons.next_question')}
            />
          )}
        </Box>
      )}
    </Box>
  );
};
