import { Box } from '@mentimeter/ragnar-ui/box';
import {
  CharacterCount,
  CharacterCountWrap,
} from '@mentimeter/ragnar-ui/character-count';
import type { TranslationFn } from '@mentimeter/question-modules-types';
import { Label } from '@mentimeter/ragnar-ui/label';
import { SelectItem } from '@mentimeter/ragnar-ui/input/select-item';
import { TextInputItem } from '@mentimeter/ragnar-ui/input/text-input';
import { CheckItem } from '@mentimeter/ragnar-ui/check-item';

export type FieldTypes = 'text' | 'email' | 'checkbox' | 'date' | 'dropdown';

const MAX_LENGTH = 140;

interface QuickFormFieldSharedProps {
  fieldId: number;
  label: string;
  required: boolean;
}

interface QuickFormTextFieldProps extends QuickFormFieldSharedProps {
  value: string | undefined;
  onChange: (fieldId: number, value: string) => void;
  type: 'text' | 'email';
}

export const QuickFormTextField = ({
  fieldId,
  label,
  required,
  value,
  onChange,
  type,
}: QuickFormTextFieldProps) => {
  return (
    <Box width="100%">
      <Label htmlFor={`field-${fieldId}`} required={required}>
        {label}
      </Label>
      <CharacterCountWrap width="100%">
        <TextInputItem
          autoComplete="off"
          maxLength={MAX_LENGTH}
          type={type}
          required={required}
          name={`field-${fieldId}`}
          aria-describedby={`field-${fieldId}-character-counter`}
          id={`field-${fieldId}`}
          onChange={(e) => onChange(fieldId, e.target.value)}
          value={value}
        />
        <CharacterCount
          id={`field-${fieldId}-character-counter`}
          maxLength={MAX_LENGTH}
          value={value ?? ''}
        />
      </CharacterCountWrap>
    </Box>
  );
};

interface QuickFormDateFieldProps extends QuickFormFieldSharedProps {
  value: string | undefined;
  onChange: (fieldId: number, value: string) => void;
}

export const QuickFormDateField = ({
  fieldId,
  label,
  required,
  value,
  onChange,
}: QuickFormDateFieldProps) => {
  const isoToday = new Date().toISOString();
  const today = isoToday.substring(0, isoToday.indexOf('T'));
  return (
    <Box width="100%">
      <Label htmlFor={`field-${fieldId}`} required={required}>
        {label}
      </Label>
      <TextInputItem
        autoComplete="off"
        maxLength={MAX_LENGTH}
        type="date"
        required={required}
        name={`field-${fieldId}`}
        id={`field-${fieldId}`}
        onChange={(e) => onChange(fieldId, e.target.value)}
        value={value || today}
      />
    </Box>
  );
};

interface QuickFormDropdownFieldProps extends QuickFormFieldSharedProps {
  options: string[];
  onChange: (fieldId: number, selectedOption: string) => void;
  translate: TranslationFn;
}

export const QuickFormDropdownField = ({
  fieldId,
  label,
  options,
  required,
  onChange,
  translate,
}: QuickFormDropdownFieldProps) => {
  const dropdownOptions = !options ? [] : options;
  const initialValue = `--- ${translate('texts.choose_option')} ---`;
  const defaultOption = {
    key: `default-${fieldId}`,
    label: initialValue,
    value: 'default',
  };
  const newOptions = dropdownOptions.map((option) => ({
    key: `${option}-${fieldId}`,
    label: option,
    value: option,
  }));

  return (
    <Box width="100%">
      <Label htmlFor={`field-${fieldId}`} required={required}>
        {label}
      </Label>
      <SelectItem
        required={required}
        id={`field-${fieldId}`}
        name={`field-${fieldId}`}
        onChange={(e) => onChange(fieldId, e.target.value)}
      >
        {[defaultOption, ...newOptions].map((optionItem) => (
          <SelectItem.Option key={optionItem.key} value={optionItem.value}>
            {optionItem.label}
          </SelectItem.Option>
        ))}
      </SelectItem>
    </Box>
  );
};

interface QuickFormCheckboxFieldProps extends QuickFormFieldSharedProps {
  optionLabels: string[];
  selectedOptionLabels: string[];
  onChange: (fieldId: number, optionLabel: string, checked: boolean) => void;
}

export const QuickFormCheckboxField = ({
  fieldId,
  label,
  optionLabels,
  required,
  selectedOptionLabels: value,
  onChange,
}: QuickFormCheckboxFieldProps) => {
  return (
    <Box as="fieldset" width="100%">
      <Label
        as="legend"
        required={Boolean(required)}
        extend={() => ({
          '&:hover': {
            cursor: 'initial',
          },
        })}
      >
        {label}
      </Label>

      <Box width="100%" gap="space3">
        {optionLabels.map((option) => (
          <Box
            key={option}
            width="100%"
            flexDirection="row"
            alignItems="center"
            gap="space3"
          >
            <CheckItem
              id={`field-${fieldId}-${option}`}
              name={`field-${fieldId}-${option}`}
              onChange={(e) => onChange(fieldId, option, e.target.checked)}
              checked={Array.isArray(value) ? value.includes(option) : false}
            />
            <Label
              htmlFor={`field-${fieldId}-${option}`}
              mb="space0"
              flex="1 1 auto"
            >
              {option}
            </Label>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
