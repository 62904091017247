import { useVotingContext } from '@mentimeter/question-modules-contexts';
import type { Player } from '@mentimeter/quiz';
import { getOrdinal } from '@mentimeter/quiz';
import { ClockIcon, LoaderIcon } from '@mentimeter/ragnar-visuals';
import React from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';
import { AvatarStatusBubble, AvatarWrapper } from '../ui/Avatar';
import { AnnouncedHeading } from '../ui/AnnouncedHeading';
import { useLeaderboardDelay } from '../useLeaderboardDelay';
import { FadeIn } from '../ui/Transitions';

export const FinalLeaderboard = ({
  player,
  playerColor,
  points,
  position,
}: {
  player: Player | undefined;
  playerColor: string;
  points: number;
  position: number;
}) => {
  const { useTranslate } = useVotingContext();
  const translate = useTranslate();

  const playerPositionOrdinal = getOrdinal(position, translate);

  const ready = useLeaderboardDelay();

  return player ? (
    <>
      <AvatarWrapper player={player} playerColor={playerColor} />
      <FadeIn>
        {!ready && (
          <AvatarStatusBubble>
            <ClockIcon color="onNeutralWeak" size={4} />
          </AvatarStatusBubble>
        )}
      </FadeIn>
      {ready ? (
        <FadeIn>
          <Box alignItems="center" mt="space3">
            <AnnouncedHeading
              fontSize="175"
              textAlign="center"
              color="text"
              fontWeight="regular"
            >
              {position === 1
                ? translate('state.you_winner', {
                    name: player.name ?? player.emojiName,
                  })
                : translate('state.you_finished', {
                    playerPositionOrdinal,
                  })}
            </AnnouncedHeading>
            <Text my="space2" textAlign="center" color="text">
              <Text fontWeight="semiBold" color="text" mr="space0.5">
                {points}
              </Text>
              {translate('state.points_total')}
            </Text>
          </Box>
        </FadeIn>
      ) : (
        <AnnouncedHeading
          fontSize="175"
          textAlign="center"
          color="text"
          fontWeight="regular"
          mt="space3"
        >
          {translate('state.loading_result')}
        </AnnouncedHeading>
      )}
    </>
  ) : (
    <LoaderIcon />
  );
};
