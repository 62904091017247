import React, { useState } from 'react';
import { emojiRegex, removeEmojisFromString } from '@mentimeter/emojis';
import type { UseTranslateT } from '@mentimeter/question-modules-types';
import { Box } from '@mentimeter/ragnar-ui/box';
import {
  CharacterCount,
  CharacterCountWrap,
} from '@mentimeter/ragnar-ui/character-count';
import { TextInputItem } from '@mentimeter/ragnar-ui/input/text-input';
import { ErrorMessage } from '@mentimeter/ragnar-ui/error-message';
import { cleanWord } from '../utils/cleanWord';
import { DISABLED_CHARS } from '../constants/regex';
import { MAX_CHAR_LENGTH } from '../constants/common';

const hasCharacter = (word: string, charRegex: RegExp) => charRegex.test(word);

interface InputFieldProps {
  onChange: (value: string) => void;
  value: string;
  placeholder: string;
  translate: ReturnType<UseTranslateT>;
  emojiFilterEnabled: boolean;
  dataTestId: string;
}

export const InputField = ({
  onChange,
  value,
  placeholder,
  translate,
  emojiFilterEnabled,
  dataTestId,
}: InputFieldProps) => {
  const [shouldShowSpecialCharsWarning, setShouldShowSpecialCharsWarning] =
    useState(false);
  const [shouldShowEmojisWarning, setShouldShowEmojisWarning] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    const hasSpecialChars = hasCharacter(newValue, DISABLED_CHARS);
    const hasEmojis = hasCharacter(newValue, emojiRegex);

    setShouldShowSpecialCharsWarning(hasSpecialChars);
    setShouldShowEmojisWarning(emojiFilterEnabled && hasEmojis);

    if (hasSpecialChars || hasEmojis) {
      const filteredValue = emojiFilterEnabled
        ? removeEmojisFromString(cleanWord(newValue))
        : cleanWord(newValue);
      onChange(filteredValue);
    } else {
      onChange(newValue);
    }
  };

  const handleBlur = () => {
    setShouldShowEmojisWarning(false);
    setShouldShowSpecialCharsWarning(false);
  };

  return (
    <Box width="100%" alignItems="stretch">
      <CharacterCountWrap>
        <TextInputItem
          autoComplete="off"
          name="wordcloud-input"
          aria-describedby="wordcloud-input-character-counter"
          maxLength={MAX_CHAR_LENGTH}
          placeholder={placeholder}
          aria-label={placeholder}
          value={value}
          data-testid={dataTestId}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <CharacterCount
          id="wordcloud-input-character-counter"
          maxLength={MAX_CHAR_LENGTH}
          value={value}
        />
      </CharacterCountWrap>
      {shouldShowSpecialCharsWarning && (
        <ErrorMessage color="textWeaker" aria-live="assertive">
          {translate('word_cloud.fields_special_char')}
        </ErrorMessage>
      )}
      {shouldShowEmojisWarning && (
        <ErrorMessage color="textWeaker" aria-live="assertive">
          {translate('messages.fields_emoji')}
        </ErrorMessage>
      )}
    </Box>
  );
};
