/* eslint-disable menti-react/filename-convention--jsx */
import * as React from 'react';
import type { Question, Reaction, Series } from '@mentimeter/http-clients';
import { getLatestPresentationId } from '@mentimeter/presentation-state';
import { Box } from '@mentimeter/ragnar-ui/box';
import { trackEvent, useTrack } from '../../utils/tracker';
import { useAppSelector } from '../../redux-hooks';
import { getDSCWithTheme, voteKeySelector } from '../../selectors';
import { QuickSlideReaction, ReactionAnimation } from '../../ui';
import {
  REACTION_MAP,
  REACTION_SORT_MAP,
} from '../../ui/Components/Reaction/Utils';
import { useReactionVotes } from './useReactionVotes';
import { publishReaction } from './publishReaction';
import { submitReactionVote } from './submitReactionVote';

export const Reactions = ({
  question,
  availableReactions = [],
}: {
  question: Question;
  availableReactions: Series['reactions'];
}) => {
  const trackVoter = useTrack();

  const voteKey = useAppSelector(voteKeySelector);

  const setReactionVote = useReactionVotes((state) => state.setReactionVote);
  const reactionVote = useReactionVotes(
    (state) => state.reactionVotes[question.public_key],
  );
  const theme = useAppSelector(getDSCWithTheme);

  // Calculate a random color to react with
  const [themeIndex] = React.useState(
    theme.visualizationColors.fillColors
      ? Math.floor(
          Math.random() * theme.visualizationColors.fillColors.length - 0,
        )
      : 1,
  );

  const sortedReactions = React.useMemo(
    () =>
      (availableReactions ?? [])
        .filter((reaction) => Object.keys(REACTION_MAP).includes(reaction))
        .sort(
          (a: Reaction, b: Reaction) =>
            REACTION_SORT_MAP[a] - REACTION_SORT_MAP[b],
        ),
    [availableReactions],
  );

  const handleSendReaction = (reactionKey: Reaction) => {
    const themeClass = `theme-fill-color-${themeIndex}`;
    const reactionPayload = {
      emoji: reactionKey,
      color: themeClass,
    };

    // Submit a reaction vote if we haven't voted in this app yet or we changed
    // the reaction we voted on.
    if (reactionVote !== reactionKey) {
      trackEvent('event status', 'Sent reaction');

      setReactionVote(question.public_key, reactionPayload.emoji);
      submitReactionVote(question.public_key, voteKey, reactionPayload, {
        onDone: () => {
          trackVoter({
            event: 'Clicked reaction button',
            properties: {
              'Presentation id': getLatestPresentationId(),
              'reaction type': reactionKey,
              'question type': question.type,
              'slide type':
                question.type === 'slide' ? question.slide_type : undefined,
            },
          });
        },
      });
    } else {
      publishReaction(question.id, voteKey, reactionPayload);
    }
  };

  if (sortedReactions.length === 0) return null;

  return (
    <Box
      width="100%"
      justifyContent="center"
      flexWrap="wrap"
      flexDirection="row"
      py="space10"
      gap="space2"
    >
      {sortedReactions.map((r) => {
        return (
          // This key is used to force a re-render when changing slides
          <Box key={`${question.id}-${r}-animation`}>
            <ReactionAnimation themeIndex={themeIndex} reaction={r}>
              <QuickSlideReaction
                reaction={r}
                vote={reactionVote}
                themeIndex={themeIndex}
                onClick={handleSendReaction}
              />
            </ReactionAnimation>
          </Box>
        );
      })}
    </Box>
  );
};
