import * as React from 'react';
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import { ErrorCodes, ClientErrorCodes } from '@mentimeter/quiz';
import { LoaderIcon } from '@mentimeter/ragnar-visuals';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Button } from '@mentimeter/ragnar-ui/button';
import { AnnouncedHeading } from '../ui/AnnouncedHeading';

const NO_AVAILABLE_QUIZ_MSG = 'quiz.controller.no_available_quiz';
const PLAYER_FAILED_MSG = 'quiz.controller.player_failed';
const WRONG_VOTE_KEY_MSG = 'quiz.controller.wrong_vote_key';
const VOTE_FAILED_MSG = WRONG_VOTE_KEY_MSG;
const VOTED_TOO_SLOW_MSG = 'quiz.controller.vote_too_slow';
const VOTED_TOO_FAST_MSG = 'quiz.controller.vote_too_fast';
const ALREADY_VOTED_MSG = 'messages.already_responded';
const RESPONSE_MSG = 'messages.response';
const NO_CONNECTION = 'quiz.controller.no_results';
// TODO: Update "TOO_MANY_PLAYERS_MSG" to use translation with key "quiz.controller.too_many_players" when localized
const TOO_MANY_PLAYERS_MSG =
  'Wow, this is a popular quiz! All the playing spots are filled. Sit back, relax, and enjoy the show.';

const CODE_MESSAGE_MAP: { [key in ErrorCodes | ClientErrorCodes]?: string } = {
  [ErrorCodes.NO_AVAILABLE_QUIZ]: NO_AVAILABLE_QUIZ_MSG,
  [ErrorCodes.FAILED_TO_CREATE_OR_UPDATE_PLAYER]: PLAYER_FAILED_MSG,
  [ErrorCodes.FAILED_TO_POST_VOTE]: VOTE_FAILED_MSG,
  [ErrorCodes.PLAYER_VOTED_TOO_SLOW]: VOTED_TOO_SLOW_MSG,
  [ErrorCodes.PLAYER_VOTED_TOO_FAST]: VOTED_TOO_FAST_MSG,
  [ErrorCodes.PLAYER_HAS_ALREADY_VOTED]: ALREADY_VOTED_MSG,
  [ErrorCodes.FAILED_TO_GET_RESULT]: RESPONSE_MSG,
  [ClientErrorCodes.TOO_MANY_PLAYERS]: TOO_MANY_PLAYERS_MSG,
  [ClientErrorCodes.NO_CONNECTION]: NO_CONNECTION,
};

export const Error = ({
  code,
  preLoading,
}: {
  code?: keyof typeof CODE_MESSAGE_MAP;
  preLoading?: boolean;
}) => {
  const {
    useTranslate,
    sendDdMetric,
    ThemeStyled: { StringParser },
  } = useVotingContext();
  const translate = useTranslate();

  const onReload = React.useCallback(() => {
    sendDdMetric({ name: 'mmjs.error_reloaded_quiz', value: 1 });
    window.location.reload();
  }, [sendDdMetric]);

  const onJoinAnother = React.useCallback(() => {
    sendDdMetric({ name: 'mmjs.error_clicked_join_another_quiz', value: 1 });
    window.location.assign('/');
  }, [sendDdMetric]);

  const [showError, setShowError] = React.useState(false);
  React.useEffect(() => {
    if (!preLoading) return;
    const timer = setTimeout(() => {
      setShowError(true);
    }, 5000);
    return () => clearTimeout(timer);
  }, [preLoading]);

  if (preLoading && !showError) {
    return <LoaderIcon />;
  }

  const translationKey = code ? CODE_MESSAGE_MAP[code] : undefined;
  const error = translationKey
    ? (translate(translationKey) ?? translationKey)
    : 'Unknown error';

  return (
    <>
      {error ? (
        <AnnouncedHeading
          textAlign="center"
          fontSize="112.5"
          color="text"
          fontWeight="regular"
        >
          <StringParser
            source={error}
            disableLineBreaks={false}
            allowHyperlinks
          />
        </AnnouncedHeading>
      ) : (
        <Text fontFamily="monospace" textAlign="center" fontSize="62.5">
          {/* eslint-disable-next-line menti-react-jsx/no-possibly-undefined-in-jsx */}
          Error code: {code}
        </Text>
      )}
      <Text textAlign="center" fontSize="87.5" color="text" mb="space3">
        {translate('messages.general_support_message', {
          supportMail: 'hello@mentimeter.com',
        })}
      </Text>
      <Box my="space6" alignItems="center">
        <Button size="large" variant="primary" onClick={onReload}>
          {translate('buttons.reload')}
        </Button>
        <Button variant="secondary" onClick={onJoinAnother} mt="space1">
          {translate('buttons.join_quiz')}
        </Button>
      </Box>
    </>
  );
};
