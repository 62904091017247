import type { QuestionWithSlide } from '@mentimeter/editor-schema/api-types-overrides';
import type { Question } from '@mentimeter/http-clients';
import type { Slide as VotingSlide } from '@mentimeter/voting-schema/api-types-overrides';
import { NoInteractiveContentError } from '../../../../backward-compatibility/errors/no-interactive-content-error';
import type { CommonQuestionProperty } from './get-common-question-property';

export const getQuestionPinOnImageProperty = (
  target: VotingSlide,
  prop: keyof Omit<Question, CommonQuestionProperty>,
) => {
  const interactiveContents = target.interactiveContents?.[0];

  if (interactiveContents) {
    switch (prop as keyof Omit<QuestionWithSlide, CommonQuestionProperty>) {
      default:
        return undefined;
    }
  }

  throw new NoInteractiveContentError(target.slidePublicKey);
};
