import type { ImageResource, QuestionChoice } from '@mentimeter/http-clients';
import type {
  InteractiveContentChoice,
  Slide as VotingSlide,
} from '@mentimeter/voting-schema/api-types-overrides';
import type { Image } from '@core-api/audience/types/response';
import {
  getOrSetCache,
  type WithCache,
} from '../../../../../utils/get-or-set-cache';

function getImageResourceFromImage(image: Image | null): ImageResource | null {
  if (!image) {
    return null;
  }
  return {
    _object: 'image',
    _url: image.presets.huge.url,
    id: image.presets.huge.url,
    url: image.presets.medium.url,
    alt_description: image.altText,
    presets: {
      source: {
        url: image.presets.huge.url,
        height: image.presets.huge.height ?? undefined,
        width: image.presets.huge.width ?? undefined,
      },
      original: {
        url: image.presets.huge.url,
        height: image.presets.huge.height ?? undefined,
        width: image.presets.huge.width ?? undefined,
      },
      preview: {
        url: image.presets.preview.url,
        height: image.presets.preview.height ?? undefined,
        width: image.presets.preview.width ?? undefined,
      },
      small: {
        url: image.presets.small.url,
        height: image.presets.small.height ?? undefined,
        width: image.presets.small.width ?? undefined,
      },
      medium: {
        url: image.presets.medium.url,
        height: image.presets.medium.height ?? undefined,
        width: image.presets.medium.width ?? undefined,
      },
      large: {
        url: image.presets.large.url,
        height: image.presets.large.height ?? undefined,
        width: image.presets.large.width ?? undefined,
      },
      huge: {
        url: image.presets.huge.url,
        height: image.presets.huge.height ?? undefined,
        width: image.presets.huge.width ?? undefined,
      },
    },
    crop: null,
  };
}

export function getCachedChoicesProperty(
  slide: VotingSlide,
  interactiveContentChoices: Array<InteractiveContentChoice>,
) {
  const choices: Array<QuestionChoice> =
    interactiveContentChoices?.map((c, i) => {
      return {
        image: getImageResourceFromImage(c.image),
        image_url: c.image?.presets?.medium?.url,
        cropped_image_url: c.image?.presets?.medium?.url,
        label: c.title,
        id: c.legacyChoiceId ?? i + 1,
        position: i,
        correct_answer: false,
      };
    }) ?? [];

  const cacheKey = JSON.stringify(choices);
  return getOrSetCache(
    slide as WithCache<VotingSlide>,
    'choices',
    cacheKey,
    choices,
  );
}
