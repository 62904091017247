import React, { useEffect, useState } from 'react';
import { ArrowDownIcon, ArrowUpIcon } from '@mentimeter/ragnar-visuals';
import type { VotingSDKT } from '@mentimeter/question-modules-types';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Clickable } from '@mentimeter/ragnar-ui/clickable';
import type { RankableChoice } from './types';

interface RankingManualSortProps {
  index: number;
  onMoveIndex: (currentIndex: number, newIndex: number) => void;
  rankedChoices: RankableChoice[];
  isDragging: boolean;
  item: RankableChoice;
  translate: ReturnType<VotingSDKT['useTranslate']>;
  setSortWithClick: (moveIndex: boolean) => void;
  sortWithClick: boolean;
}

export const SortButtons = ({
  index,
  onMoveIndex,
  sortWithClick,
  rankedChoices,
  isDragging,
  item,
  translate,
  setSortWithClick,
}: RankingManualSortProps) => {
  const prevIndex = index - 1;
  const nextIndex = index + 1;

  const [isOuterItem, setIsOuterItem] = useState({
    first: false,
    last: false,
  });
  const timeout = sortWithClick ? 150 : 0;

  useEffect(() => {
    setTimeout(() => {
      //set in state because of what seems to be a chrome bug, not updating the dom in time (button remains disabled)
      setIsOuterItem({
        last: rankedChoices?.length === index + 1,
        first: index === 0,
      });
      setSortWithClick(false);
    }, timeout);
  }, [index, rankedChoices?.length, timeout, setSortWithClick]);

  return (
    <Box
      position="absolute"
      right={0}
      flexDirection="row"
      gap="space1"
      extend={() => ({
        opacity: isDragging ? 0 : 1,
      })}
    >
      <Clickable
        type="button"
        height="48px"
        width="48px"
        alignItems="center"
        justifyContent="center"
        disabled={isOuterItem.first}
        onClick={() => onMoveIndex(index, prevIndex)}
        aria-label={`${item.label}${
          !isOuterItem.first
            ? ', ' + translate('ranking.move_up') + ' ' + index
            : ''
        }  `}
      >
        <ArrowUpIcon aria-hidden size={3} color="text" />
      </Clickable>

      <Clickable
        type="button"
        height="48px"
        width="48px"
        alignItems="center"
        justifyContent="center"
        onClick={() => onMoveIndex(index, nextIndex)}
        disabled={isOuterItem.last}
        aria-label={`${item.label}${
          !isOuterItem.last
            ? ', ' + translate('ranking.move_down') + ' ' + (index + 2)
            : ''
        }  `}
      >
        <ArrowDownIcon aria-hidden size={3} color="text" />
      </Clickable>
    </Box>
  );
};
