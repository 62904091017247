import React, { useState } from 'react';
import { create } from 'zustand';
import { addListener } from '@reduxjs/toolkit';
import type { Question } from '@mentimeter/http-clients';
import {
  usePresentationReadOnly,
  subscribeToPresentationStore,
  getPresentationStore,
  useVotingSetupPresentationState,
} from '@mentimeter/presentation-state';
import { useTimeout } from '@mentimeter/react-hooks';
import { usePresentationRealtimeStore } from '@mentimeter/realtime';
import { useAppDispatch, useAppSelector } from '../redux-hooks';
import { useOnHasFinishedVotingOnSlide } from '../actions/useOnHasFinishedVotingOnSlide';
import { useOnSkipQuestion } from '../actions/useOnSkipQuestion';
import { questionsSelector } from '../selectors';
import { archiveResult, onFollowPresenter } from '../reducers/votingSlice';
import { ablyRestClient } from '../realtime/ablyRestClient';
import type { PresentationLocalStore, PresentationState } from './types';

const usePresentationLocalStore = create<PresentationLocalStore>((set) => ({
  votedOnIds: [],
  showEndScreen: false,
  waitForNextSlide: false,
  hasResetVoting: false,
  hasVotedOnCurrentQuestion: false,
  localId: undefined,
  interactedOnIds: [],
  participantIdentity: { status: 'loading' },
  setParticipantIdentity: (nextState) =>
    set(() => {
      return { participantIdentity: nextState };
    }),
  followPresenter: () =>
    set((state) => {
      const presenterId = getPresentationStore().state?.slidePublicKey;
      const hasReachedEnd = Boolean(
        getPresentationStore().state?.hasReachedEnd,
      );

      if (!presenterId) return state;

      const hasVotedOnCurrentQuestion = state.votedOnIds.includes(presenterId);
      // follow presenter to new slide, check if it has been voted on
      return {
        localId: presenterId,
        hasVotedOnCurrentQuestion,
        waitForNextSlide: false,
        showEndScreen: hasReachedEnd,
      };
    }),
  goToSuccessPage: () =>
    set(() => {
      return {
        showEndScreen: true,
      };
    }),
  onVoteSuccess: (publicKey, partial) => {
    const presenterId = getPresentationStore().state?.slidePublicKey;
    const slideIndex = getPresentationStore().state?.slideIndex;
    const totalSlides = getPresentationStore().state?.totalSlides;
    const hasReachedEnd = Boolean(getPresentationStore().state?.hasReachedEnd);
    const isOnLastSlide = Boolean(
      totalSlides && slideIndex === totalSlides - 1,
    );
    set((state) => {
      if (partial) {
        const interactedOnIdsWithoutCurrentId = state.interactedOnIds.filter(
          (id) => id !== publicKey,
        );
        return {
          localId: presenterId,
          showEndScreen: hasReachedEnd,
          interactedOnIds: interactedOnIdsWithoutCurrentId,
        };
      }

      const hasAlreadyVotedOnQuestion = state.votedOnIds.includes(publicKey);
      if (hasAlreadyVotedOnQuestion) return state;
      if (presenterId !== state.localId) {
        // voter voted on out of sync slide -> follow presenter
        return {
          waitForNextSlide: false,
          localId: presenterId,
          votedOnIds: [...state.votedOnIds, publicKey],
          showEndScreen: hasReachedEnd,
        };
      } else {
        return {
          waitForNextSlide: true,
          votedOnIds: [...state.votedOnIds, publicKey],
          showEndScreen: isOnLastSlide,
        };
      }
    });
  },
  setSlideHasBeenInteractedWith: (publicKey) => {
    set((state) => {
      if (state.interactedOnIds.includes(publicKey)) return state;

      return {
        interactedOnIds: [...state.interactedOnIds, publicKey],
      };
    });
  },
  onSkipSlide: () =>
    set((state) => {
      const presenterId = getPresentationStore().state?.slidePublicKey;
      const slideIndex = getPresentationStore().state?.slideIndex;
      const totalSlides = getPresentationStore().state?.totalSlides;
      const hasReachedEnd = Boolean(
        getPresentationStore().state?.hasReachedEnd,
      );
      const isOnLastSlide = Boolean(
        totalSlides && slideIndex === totalSlides - 1,
      );
      // voter skipped and is out of sync -> follow presenter
      if (presenterId !== state.localId) {
        return {
          waitForNextSlide: false,
          localId: presenterId,
          showEndScreen: hasReachedEnd,
        };
      }
      // voter skipped the current slide
      return {
        waitForNextSlide: !isOnLastSlide,
        showEndScreen: isOnLastSlide,
      };
    }),

  onResetSeries: () =>
    set(() => ({
      votedOnIds: [],
      interactedOnIds: [],
      waitForNextSlide: false,
      showEndScreen: false,
      hasVotedOnCurrentQuestion: false,
      hasResetVoting: true,
    })),

  disableResetVoting: () =>
    set(() => ({
      hasResetVoting: false,
    })),
}));

const isAutoPaceQuestion = ({ type, steps }: Question) => {
  return (
    type === 'qfa' ||
    type === 'slide' ||
    type === 'quiz' ||
    type === 'quiz_open' ||
    type === 'quiz_leaderboard' ||
    (type === 'open' && steps > 1)
  );
};

export const usePresentationState = (): PresentationState => {
  const dispatch = useAppDispatch();
  const progress = usePresentationReadOnly((state) =>
    state.selectors.progress(),
  );
  const slidePublicKey = usePresentationReadOnly(
    ({ state }) => state?.slidePublicKey,
  );
  const slideStates = usePresentationReadOnly(
    ({ state }) => state?.slideStates,
  );
  const step = usePresentationReadOnly(({ state }) => state?.step ?? 0);
  const [hasWaitedForLoading, setHasWaitedForLoading] = useState(false);

  const {
    localId,
    waitForNextSlide,
    followPresenter,
    goToSuccessPage,
    onVoteSuccess,
    hasVotedOnCurrentQuestion,
    onSkipSlide,
    showEndScreen,
    onResetSeries,
    hasResetVoting,
    disableResetVoting,
    setSlideHasBeenInteractedWith,
    interactedOnIds,
    participantIdentity,
    setParticipantIdentity,
  } = usePresentationLocalStore();

  const questions = useAppSelector(questionsSelector);
  const question =
    questions.find((q) => q.public_key === localId) ?? questions[0]!;

  const hasNextSlide = questions.indexOf(question) < questions.length - 1;

  const onAutoPace = React.useCallback(() => {
    if (
      localId === undefined ||
      isAutoPaceQuestion(question) ||
      hasVotedOnCurrentQuestion ||
      waitForNextSlide ||
      !interactedOnIds.includes(localId)
    ) {
      dispatch(onFollowPresenter());
      followPresenter();
    }
  }, [
    localId,
    question,
    hasVotedOnCurrentQuestion,
    waitForNextSlide,
    interactedOnIds,
    dispatch,
    followPresenter,
  ]);

  useTimeout(() => {
    setHasWaitedForLoading(true);
  }, 3000);

  React.useEffect(() => {
    /* this effect runs for every instance of usePresentationState, which is uncenessary work. it shouldnt cause isseus as the value is a primitive,
    but we should look into running it only once at some point. */
    return subscribeToPresentationStore(({ state }) => state, onAutoPace);
  }, [onAutoPace]);

  useOnHasFinishedVotingOnSlide(onVoteSuccess);
  useOnSkipQuestion(onSkipSlide);

  React.useEffect(() => {
    const unsubscribe = dispatch(
      addListener({
        actionCreator: archiveResult,
        effect: () => onResetSeries(),
      }),
    );
    return unsubscribe;
  }, [dispatch, onResetSeries]);

  const currentSlideState =
    localId && slideStates ? slideStates[localId] : undefined;

  return {
    progress,
    question,
    isOutOfSync: Boolean(slidePublicKey) && slidePublicKey !== localId,
    followPresenter,
    goToSuccessPage,
    hasNextSlide,
    step,
    hasResetVoting,
    participantIdentity,
    setParticipantIdentity,
    showEndScreen,
    hasVotedOnCurrentQuestion,
    waitForNextSlide,
    disableResetVoting,
    isLoading: !hasWaitedForLoading && slidePublicKey === undefined,
    currentSlideState,
    setSlideHasBeenInteractedWith,
  };
};

export const usePresentationSubscription = (voteKey: string) => {
  const shouldSubscribe = usePresentationRealtimeStore(
    (state) => !!state.client,
  );

  useVotingSetupPresentationState({
    voteKey,
    shouldSubscribe,
    restClient: ablyRestClient,
  });
};
