'use client';
import { DeviceContext, useMatch } from '@mentimeter/ragnar-device';
import React from 'react';
import { useAppSelector } from '../../redux-hooks';
import { paceModeSelector, resultsSharingSelector } from '../../selectors';

export const useShouldRequestDesktopExperience = () => {
  const paceMode = useAppSelector(paceModeSelector);
  const resultsSharing = useAppSelector(resultsSharingSelector);
  const { initialized } = React.useContext(DeviceContext);
  const isDesktop = useMatch({ greaterThan: 2 });

  return {
    initialized,
    shouldRequestDesktopExperience:
      isDesktop && paceMode === 'presenter' && resultsSharing === 'on',
  };
};
