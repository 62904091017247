'use client';

import * as React from 'react';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import { ErrorLayout } from '../ui';
import { trackEvent } from '../utils/tracker';

const Closed = () => {
  trackEvent('event status', 'Presentation is closed');
  return (
    <ErrorLayout>
      <Box alignItems="stretch">
        <Text
          fontFamily="heading"
          fontWeight="semiBold"
          color="text"
          fontSize={5}
          as="h1"
        >
          This presentation is closed.
        </Text>

        <Text textAlign="center" my={2}>
          The presenter has turned off voting access for this presentation.
        </Text>
        <Box alignItems="center">
          <Button size="large" href="/">
            Back to the start page
          </Button>
        </Box>
      </Box>
    </ErrorLayout>
  );
};

export default Closed;
