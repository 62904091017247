import React from 'react';
import { useDroppable } from '@dnd-kit/core';
import type { VotingSDKT } from '@mentimeter/question-modules-types';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';
import type { RankableChoice } from './types';
import { UnrankedDropItem } from './UnrankedDropItem';

interface UnrankedDropSectionT {
  id: string;
  choices: RankableChoice[];
  sendToRanked: (item: RankableChoice) => void;
  translate: ReturnType<VotingSDKT['useTranslate']>;
}

export const UnrankedDropSection = ({
  id,
  choices,
  sendToRanked,
  translate,
}: UnrankedDropSectionT) => {
  const { setNodeRef } = useDroppable({
    id,
  });
  return (
    <>
      <Text as="h2" color="text" mb="space3" lineHeight="150">
        {translate('ranking.header_unranked')}
      </Text>
      <Box width="100%" as="ul" ref={setNodeRef} data-testid="unranked-list">
        {choices.map((item) => (
          <Box as="li" key={item.id} width="100%">
            <UnrankedDropItem
              choice={item}
              ariaLabel={`${item.label}, ${translate('ranking.screenreader_add')}`}
              sendToRanked={sendToRanked}
            />
          </Box>
        ))}
      </Box>
    </>
  );
};
