import { useVotingContext } from '@mentimeter/question-modules-contexts';
import { TextMarkupRenderer } from '@mentimeter/question-modules-shared';
import type { TextT } from '@mentimeter/ragnar-ui/text';
import { Text } from '@mentimeter/ragnar-ui/text';

interface VotingTitleT extends TextT {
  title?: string | undefined;
  titleStyled?: Record<string, unknown> | undefined;
}

export function VotingTitle({ title, titleStyled, ...props }: VotingTitleT) {
  const {
    ThemeStyled: { StringParser },
  } = useVotingContext();

  const isTitleStyledEmpty: boolean =
    Object.keys(titleStyled || {}).length === 0;
  if (!title && isTitleStyledEmpty) return null;

  return (
    <Text
      as="h1"
      data-testid="voting-title"
      color="text"
      fontSize="175"
      fontWeight="regular"
      lineHeight="snug"
      truncate={false}
      mb="space3"
      {...props}
    >
      {isTitleStyledEmpty ? (
        <StringParser
          disableLineBreaks={false}
          source={title || ''}
          allowBlankLines
        />
      ) : (
        <TextMarkupRenderer content={titleStyled || ''} hideColors />
      )}
    </Text>
  );
}
