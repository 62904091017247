import React from 'react';
import { ArrowLeftIcon } from '@mentimeter/ragnar-visuals';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import { CalloutCard } from '@mentimeter/ragnar-ui/callout-card';
import { useTranslate } from '../localization/Translate';
import { useAppSelector } from '../../redux-hooks';
import { qfaModerationEnabledSelector } from '../../selectors';

export const QfaFormContainer = ({
  goBack,
  children,
}: {
  goBack: () => void;
  children: React.ReactNode;
}) => {
  const translate = useTranslate();
  const qfaModerationEnabled = useAppSelector(qfaModerationEnabledSelector);

  return (
    <Box width="100%" flex="1 1 auto" px="space8">
      <Box width="100%" pb="space6">
        <Button
          size="large"
          onClick={goBack}
          variant="subtle"
          iconLeading={ArrowLeftIcon}
        >
          {translate('buttons.back')}
        </Button>
      </Box>

      <Box alignItems="stretch" width="100%" flex="1 1 auto">
        {qfaModerationEnabled && (
          <CalloutCard
            theme="neutral"
            mb="space10"
            compact
            description={translate('qfa.questions_are_moderated')}
          />
        )}
        {children}
      </Box>
    </Box>
  );
};
