import React from 'react';
import type { Extend } from '@mentimeter/ragnar-dsc';
import type { ClickableT } from '@mentimeter/ragnar-ui/clickable';
import { Clickable } from '@mentimeter/ragnar-ui/clickable';

export const NUMBER_PICKER_TAP_AREA = -20;
export interface NumberPickerButtonProps extends ClickableT {
  hasValue?: boolean;
  tapArea?:
    | {
        top?: ReturnType<Extend>['top'];
        right?: ReturnType<Extend>['right'];
        bottom?: ReturnType<Extend>['bottom'];
        left?: ReturnType<Extend>['left'];
      }
    | undefined;
}
export function NumberPickerButton({
  onClick,
  disabled,
  tapArea,
  children,
  hasValue = false,
  ...rest
}: NumberPickerButtonProps) {
  return (
    <Clickable
      onClick={onClick}
      borderRadius={3}
      bg={disabled ? 'disabledStrong' : hasValue ? 'secondary' : 'bg'}
      color={disabled ? 'onDisabledStrong' : hasValue ? 'onSecondary' : 'text'}
      p="space2"
      disabled={disabled}
      {...rest}
      extend={({ theme }) => {
        return {
          cursor: disabled ? 'not-allowed' : 'pointer',
          // transition to primary color instantly as it's a background color for other elements as well
          transition: `${hasValue ? '0s' : '0.2s'} ease`,
          ...(disabled && {
            opacity: 1,
            '@media (hover: hover)': {
              '&:hover': {
                opacity: 1,
              },
            },
          }),
          '&::after': {
            content: '""',
            position: 'absolute',
            top: `${NUMBER_PICKER_TAP_AREA}px`,
            right: `${NUMBER_PICKER_TAP_AREA / 2}px`,
            bottom: `${NUMBER_PICKER_TAP_AREA}px`,
            left: `${NUMBER_PICKER_TAP_AREA / 2}px`,
            ...tapArea,
          },
          '> svg g': {
            color: disabled
              ? theme.colors.onDisabledStrong
              : hasValue
                ? theme.colors.onSecondary
                : theme.colors.text,
          },
        };
      }}
    >
      {children}
    </Clickable>
  );
}
