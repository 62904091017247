import * as React from 'react';
import type { Question } from '@mentimeter/http-clients';
import type { UseTranslateT } from '@mentimeter/question-modules-types';
import { MultipleChoice } from '@mentimeter/voting-ui';
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Box } from '@mentimeter/ragnar-ui/box';

interface Props {
  maxVotes: Question['max_votes'];
  choices: Question['choices'];
  translate: ReturnType<UseTranslateT>;
  votes: string[];
  setVotes: React.Dispatch<React.SetStateAction<string[]>>;
}

export const ALLOW_SELECT_ALL_CHOICES = -1;

export const Option = ({
  maxVotes,
  choices,
  translate,
  votes,
  setVotes,
}: Props) => {
  const {
    ThemeStyled: { StringParser },
  } = useVotingContext();

  const numberOfVotesLeft = maxVotes - votes.length;
  const allowMultipleOptions =
    maxVotes > 1 || maxVotes === ALLOW_SELECT_ALL_CHOICES;

  const handleChange = (value: string) => {
    if (!allowMultipleOptions) {
      setVotes([value]);
      return;
    }

    if (votes.includes(value)) {
      // Remove vote
      setVotes(votes.filter((vote) => vote !== value));
      return;
    }

    if (numberOfVotesLeft === 0) return;

    setVotes([...votes, value]);
  };

  return (
    <>
      {allowMultipleOptions && (
        <Text color="textWeaker" mb="space4" lineHeight="relaxed">
          {maxVotes === ALLOW_SELECT_ALL_CHOICES
            ? translate('many_votes.choose_multiple')
            : translate('many_votes.choose_max_options', { maxVotes })}
        </Text>
      )}

      <Box width="100%" gap="space4">
        {choices.map(({ id, label, image }, index) => {
          const selected = votes.includes(id.toString());
          return (
            <MultipleChoice
              key={`${label}-${id}`}
              id={id}
              index={index}
              selected={selected}
              disabled={
                allowMultipleOptions && numberOfVotesLeft === 0 && !selected
              }
              name="choices"
              type={allowMultipleOptions ? 'checkbox' : 'radio'}
              value={id}
              onChange={handleChange}
              image={image}
              dismissButtonText={
                translate('buttons.close_image') || 'Close image'
              }
              showImageText={`Show image for ${label}`}
            >
              <Text style={{ wordBreak: 'break-word' }}>
                <StringParser source={label} disableLineBreaks />
              </Text>
            </MultipleChoice>
          );
        })}
      </Box>

      {allowMultipleOptions && maxVotes !== ALLOW_SELECT_ALL_CHOICES && (
        <Text as="p" color="textWeaker" mt="space4" lineHeight="relaxed">
          {numberOfVotesLeft}{' '}
          {numberOfVotesLeft === 1
            ? translate('many_votes.choice_left')
            : translate('many_votes.choices_left')}
        </Text>
      )}
    </>
  );
};
