'use client';

import * as React from 'react';
import { Text } from '@mentimeter/ragnar-ui/text';
import { List } from '@mentimeter/ragnar-ui/list';
import { Box, type BoxT } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import { MentimeterSymbol, StopSolidIcon } from '@mentimeter/ragnar-visuals';
import { PageLayout } from '../../ui';
import { Terms } from '../../ui/Components/Footer/Terms';

interface ListItemProps extends BoxT {
  text: string;
}
const ListItem = ({ text, ...rest }: ListItemProps) => {
  return (
    <Box as="li" flexDirection="row" alignItems="center" gap={3} {...rest}>
      <Box>
        <StopSolidIcon size={1} />
      </Box>
      <Text fontSize="100" lineHeight="150">
        {text}
      </Text>
    </Box>
  );
};

export const JoinPresentationConfirmation = ({
  identityMode,
  redirectUrl,
}: { identityMode: string | undefined; redirectUrl: string }) => {
  return (
    <PageLayout footer={<Terms />}>
      <Box
        flex="1"
        width="100%"
        alignItems="stretch"
        gap="space6"
        m="auto"
        maxWidth={[null, '600px']}
      >
        <Box width="100%" alignItems="center" mt="space4" mb="space0">
          <MentimeterSymbol width={40} height={40} />
        </Box>
        <Text
          fontFamily="heading"
          lineHeight="heading"
          fontSize="175"
          mb={2}
          color="text"
          as="h1"
        >
          Login required
        </Text>
        <List>
          <ListItem
            text="This session requires you to log in to join."
            mb={3}
          />
          <ListItem
            text={
              identityMode === 'identified'
                ? 'Your name will be shared with other participants and recorded with your responses and questions.'
                : 'Your name will not be recorded or shared with other participants.'
            }
          />
        </List>
        <Box alignItems="center">
          <Button variant="primary" href={redirectUrl} size="large">
            Log in
          </Button>
        </Box>
      </Box>
    </PageLayout>
  );
};
