import { useVotingContext } from '@mentimeter/question-modules-contexts';
import { TitleSectionWithImage } from '../../ui/Components/VotingTitle';

export function Content() {
  const { useQuestion } = useVotingContext();
  const {
    question_description,
    title_meta,
    image,
    question: title,
    question_styled,
  } = useQuestion();

  return (
    <TitleSectionWithImage
      description={question_description}
      title={title}
      titleStyled={question_styled}
      meta={title_meta}
      image={image}
    />
  );
}
