import type { Question } from '@mentimeter/http-clients';
import type { Slide as VotingSlide } from '@mentimeter/voting-schema/api-types-overrides';
import { imageToQuestionImage } from '../converters/slide/image-to-question-image';

export const getLegacyImageProperty = (
  question: VotingSlide,
  prop: keyof Question,
) => {
  const staticContent = question.staticContent;
  const interactiveContent = question.interactiveContents?.[0];

  const imageReference = staticContent.image;

  const image =
    interactiveContent?.image ??
    question.images?.find((i) => i.path === imageReference?.imagePath);

  if (!image) {
    return undefined;
  }

  if (prop === 'image') {
    return imageToQuestionImage(image);
  }

  if (prop === 'question_image_url' || prop === 'cropped_image_url') {
    return image.presets?.large?.url;
  }

  if (prop === 'image_alt_description') {
    return image.altText;
  }

  return undefined;
};
