import { useRouter } from 'next/navigation';
import * as React from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import { Text } from '@mentimeter/ragnar-ui/text';
import { resetIdentifier } from '../../utils/identifier';
import { trackEvent, useTrack } from '../../utils/tracker';
import { useAppSelector } from '../../redux-hooks';
import {
  getHasCustomTheme,
  getIsQfaActive,
  resultsSharingSelector,
  voteAgainEnabledSelector,
  voteKeySelector,
} from '../../selectors';
import { useSplitTrack } from '../../split.io';
import { SeriesLogo } from '../questions/SeriesLogo';
import { useTranslate } from '../localization/Translate';
import SignUpButton from './SignUpButton';
import type { FinishScreenProps } from './FinishScreenProps';
import { VotingScreenDefaultLayout } from './FinishScreenExperiment';
import { SendMeResultsButton } from './SendMeResultsButton';

export function FinishScreen({ qfa }: FinishScreenProps) {
  const trackSplit = useSplitTrack();
  const translate = useTranslate();
  const router = useRouter();
  const trackVoter = useTrack();

  React.useEffect(() => {
    trackEvent('event status', 'finished');
  }, []);

  React.useEffect(() => {
    trackSplit('End_screen.Visited');
    trackVoter({
      event: 'Viewed end screen',
    });
  }, [trackSplit, trackVoter]);

  const resultsSharing = useAppSelector(resultsSharingSelector);
  const showQfaButton = useAppSelector(getIsQfaActive);
  const voteKey = useAppSelector(voteKeySelector);
  const hasCustomTheme = useAppSelector(getHasCustomTheme);
  const voteAgainEnabled = useAppSelector(voteAgainEnabledSelector);

  const onSeeTheResultsClick = () => {
    trackSplit('End_screen.Button_clicked.Get_presentation_results');
    trackEvent('button', 'clicked', 'Get presentation results');
    trackVoter({
      event: 'Clicked get results',
    });
  };

  const voteAgain = () => {
    resetIdentifier();
    trackSplit('End_screen.Button_clicked.Vote_again_on_same');

    router.push(`/${voteKey}`);
  };

  return (
    <VotingScreenDefaultLayout>
      <SeriesLogo pt="space20" pb="space10" />
      <Box alignItems="center" width="100%">
        <Box width="100%" mb="space12" alignItems="center">
          <Text
            as="h1"
            color="text"
            fontSize="175"
            lineHeight="snug"
            textAlign="center"
            fontWeight="regular"
          >
            {translate('messages.thank_you')}
          </Text>
        </Box>
        <Box alignItems="stretch" gap="space2" width="100%">
          <SignUpButton hasCustomTheme={hasCustomTheme} variant="primary" />
          {showQfaButton && <Box alignItems="stretch">{qfa}</Box>}
          {resultsSharing === 'on' && (
            <SendMeResultsButton onClick={onSeeTheResultsClick} />
          )}
          {voteAgainEnabled && (
            <Button
              size="large"
              aria-label={translate('success.participate_again')}
              onClick={voteAgain}
              py="space4"
              px="space8"
            >
              {translate('success.participate_again')}
            </Button>
          )}
        </Box>
      </Box>
    </VotingScreenDefaultLayout>
  );
}
