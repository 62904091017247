import * as React from 'react';
import { Image } from '@mentimeter/ragnar-ui/image';
import { Box } from '@mentimeter/ragnar-ui/box';
import { useAppSelector } from '../../redux-hooks';
import { useTranslate } from '../localization/Translate';
import { themeLogoSelector } from '../../selectors';

export const SeriesLogo = ({ ...props }) => {
  const logoUrl = useAppSelector(themeLogoSelector);
  const translate = useTranslate();

  if (!logoUrl) return null;

  return (
    <Box
      alignItems="center"
      justifyContent="center"
      pb={['space10', 'space18']}
      {...props}
    >
      <Image
        src={logoUrl}
        alt={translate('aria.mentimeter_logo')}
        extend={() => ({ display: 'block' })}
        maxHeight="28px"
        width="auto"
      />
    </Box>
  );
};
