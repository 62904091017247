import { Box } from '@mentimeter/ragnar-ui/box';

export const Miro = ({
  boardId,
}: {
  boardId: string | null;
}) => {
  return (
    <Box
      flex={1}
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      bg="neutralWeakest"
      borderRadius={2}
    >
      {boardId && (
        <iframe
          width="100%"
          height="100%"
          src={`https://miro.com/app/live-embed/${boardId}?embedAutoplay=true`}
          frameBorder="0"
          scrolling="no"
          data-testid="miro-embed"
        />
      )}
    </Box>
  );
};
