import { createSelector } from '@reduxjs/toolkit';
import memoize from 'lodash/memoize';
import type { DSC } from '@mentimeter/ragnar-dsc';
import { designSystemConfig } from '@mentimeter/ragnar-dsc';
import { createUserTheme } from '@mentimeter/sfinx-themes';
import type { Question } from '@mentimeter/http-clients';
import { themes } from '@mentimeter/ragnar-colors';
import { createVotingSeriesCompatibilityLayer } from '@mentimeter/compatibility/voting';
import type { SeriesWithSlideDeck as VotingSeriesWithSlideDeck } from '@mentimeter/voting-schema/api-types-overrides';
import { ALL_QUIZ_QUESTION_TYPES } from '../constants/questionTypes';
import { VOTING_THEME_OVERRIDES } from '../constants/voting-theme-overrides';
import { getOpaqueTheme } from '../utils/theme-enhancers';
import type { RootState } from '../redux-store';
import type { SeriesOrSlideDeck } from '../types';

let last: SeriesOrSlideDeck | null = null;
let cached: ReturnType<typeof createVotingSeriesCompatibilityLayer> = undefined;

/*
 * createVotingSeriesCompatibilityLayer always returns a new object. which means that the selectors which use this function
 * will be unstable: the identities of nested objects will be different on each call; which violates the principles of
 * redux selectors.
 */
const getStableCompatLayer = (
  seriesOrSlideDeck: SeriesOrSlideDeck,
): VotingSeriesWithSlideDeck => {
  if (last === seriesOrSlideDeck && cached) {
    return cached;
  }
  if (seriesOrSlideDeck.series) {
    const result = createVotingSeriesCompatibilityLayer(
      /*
       * createVotingSeriesCompatibilityLayer actually modifies the passed-in object by adding a `_cache` property,
       * which happens to be not serializable. redux does not like that. in order to keep the object in the state immutable,
       * we clone the object in the state before passing it into the createVotingSeriesCompatibilityLayer function.
       */
      JSON.parse(JSON.stringify(seriesOrSlideDeck.series)),
    )!;
    cached = result;
    last = seriesOrSlideDeck;
    return result;
  }
  if (seriesOrSlideDeck.slideDeck) {
    const result = createVotingSeriesCompatibilityLayer(
      JSON.parse(JSON.stringify(seriesOrSlideDeck.slideDeck)),
    )!;
    cached = result;
    last = seriesOrSlideDeck;
    return result;
  }
  throw new Error('No series or slideDeck found in state');
};

const getSeriesFromState = (state: RootState) => {
  return getStableCompatLayer(state.series);
};

export const commentsEnabledSelector = (state: RootState) =>
  getSeriesFromState(state).comments_enabled;
export const languageSelector = (state: RootState) =>
  getSeriesFromState(state).language;
export const paceModeSelector = (state: RootState) =>
  getSeriesFromState(state).pace.mode;
export const participationIdentityModeSelector = (state: RootState) =>
  getSeriesFromState(state).participation_identity_mode;
export const qfaIntercomEnabledSelector = (state: RootState) =>
  getSeriesFromState(state).qfa_intercom_enabled;
export const qfaModerationEnabledSelector = (state: RootState) =>
  Boolean(getSeriesFromState(state).qfa_moderation_enabled);
export const questionsSelector = (state: RootState) =>
  getSeriesFromState(state).questions;
export const reactionsSelector = (state: RootState) =>
  getSeriesFromState(state).reactions;
export const resultsSharingSelector = (state: RootState) =>
  getSeriesFromState(state).results_sharing;
export const themeLogoSelector = (state: RootState) =>
  getSeriesFromState(state).theme.logo?.presets?.small?.url;
export const voteAgainEnabledSelector = (state: RootState) =>
  getSeriesFromState(state).vote_again_enabled;
export const voteIdSelector = (state: RootState) =>
  getSeriesFromState(state).vote_id?.toString();
export const voteKeySelector = (state: RootState) =>
  getSeriesFromState(state).vote_key;

const matchAnyQuestionKey = (question: Question, key: string) => {
  return question.public_key === key || question.id === key;
};

export const getHasCustomTheme = (state: RootState) =>
  !getSeriesFromState(state).theme.is_public;

const getTheme = (state: RootState) => getSeriesFromState(state).theme;

export const getDSCWithTheme: (state: RootState) => DSC = createSelector(
  [getTheme],
  (theme) => {
    if (theme.config_id === 'business-light')
      return {
        ...designSystemConfig,
        ...themes.light,
        pictogramColor: undefined,
      };
    if (theme.config_id === 'business-dark')
      return {
        ...designSystemConfig,
        ...themes.dark,
        pictogramColor: undefined,
      };
    const { background_color, text_color, line_color, bar_color } =
      getOpaqueTheme(theme);
    const userTheme = createUserTheme({
      userColors: {
        background_color,
        text_color,
        line_color,
        bar_color,
      },
    });

    return {
      pictogramColor: bar_color[0],
      ...designSystemConfig,
      ...userTheme,
      ...VOTING_THEME_OVERRIDES,
    };
  },
);

/***************************************************************
 * Quiz
 *
 * Quiz slides means all type of question/slide types related to
 * the quiz, including for example the leaderboard. This could be
 * questions you can answer but also static slide types such
 * as the leaderboard.
 *
 * Quiz questions means all quiz questions, excluding for example
 * the leaderboard. These are only the slides/questions that you
 * can actually answer.
 */

export const getQuizQuestions: (appState: RootState) => Question[] =
  createSelector([questionsSelector], (questions) =>
    questions.filter((q) =>
      ALL_QUIZ_QUESTION_TYPES.some((qt) => q.type === qt),
    ),
  );

export const getNumberOfQuizQuestions: (appState: RootState) => number =
  createSelector([getQuizQuestions], (questions) => {
    return questions.length;
  });

export const getQuizQuestionPositionById: (
  appState: RootState,
) => (questionId: string) => number = createSelector(
  [getQuizQuestions],
  (questions) =>
    memoize(
      (questionId: string) =>
        questions.findIndex((q) => matchAnyQuestionKey(q, questionId)) + 1,
    ),
);

/***************************************************************
 * QFA
 */
export const getIsQfaActive = (state: RootState) =>
  getSeriesFromState(state).qfa_active;

export const getEmojiFilterEnabled = (state: RootState) => {
  return (
    getSeriesFromState(state).profanity_lang &&
    getSeriesFromState(state).profanity_lang.includes('ascii_emoji')
  );
};
