import type { UseTranslateT } from '@mentimeter/question-modules-types';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Label } from '@mentimeter/ragnar-ui/label';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import { TextInputItem } from '@mentimeter/ragnar-ui/input/text-input';

export interface VoteOptionProps {
  id: number;
  title?: string;
  value?: number | undefined;
  pointsLeft?: number;
  pool?: number;
  step?: number;
  onChange: (arg0: number, arg1: number) => void;
  translate: ReturnType<UseTranslateT>;
}

export const VoteOption = ({
  id,
  title,
  value = 0,
  pointsLeft = 100,
  pool = 100,
  step = 10,
  onChange,
  translate,
}: VoteOptionProps) => {
  function isNumeric(n: unknown) {
    return !isNaN(parseFloat(n as string)) && isFinite(n as number);
  }

  // Make sure it's numeric and valid
  function sanitize(input: unknown) {
    const temp = parseInt(input as string, 10);
    if (!isNumeric(temp)) return 0;
    const newValue = Math.abs(temp);
    const maxPointsLeft = pointsLeft + value;
    if (maxPointsLeft < newValue) return maxPointsLeft;
    return newValue;
  }

  function onClick(id: number, value: number) {
    const newValue = sanitize(value);
    onChange(id, newValue);
  }

  return (
    <Box as="fieldset" width="100%" gap="space3">
      <Box
        as="legend"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        mb="space3" // gap should take care of this, however, browsers treat fieldset and legend differently than regular div and might interfere with the gap
      >
        <Label
          htmlFor={`points-input-${id}`}
          color="text"
          fontSize={2}
          fontWeight="semiBold"
          mb="space0"
          style={{
            height: title ? '100%' : '3rem',
            whiteSpace: 'normal',
          }}
        >
          {/* eslint-disable-next-line menti-react-jsx/no-possibly-undefined-in-jsx */}
          {title}
        </Label>
      </Box>

      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        gap="space4"
        width="100%"
      >
        <Button
          size="large"
          aria-label={translate('buttons.decrease_by', { step })}
          disabled={value < step}
          variant="outline"
          onClick={() => onClick(id, value - step)}
        >
          -{step}
        </Button>
        <TextInputItem
          autoComplete="off"
          id={`points-input-${id}`}
          name={`points-input-${id}`}
          type="number"
          onChange={(e) => {
            onClick(id, parseInt(e.target.value, 10));
          }}
          value={value.toString()}
        />
        <Button
          size="large"
          aria-label={translate('buttons.increase_by', { step })}
          disabled={value > pool - step || pointsLeft < step}
          variant="outline"
          onClick={() => onClick(id, value + step)}
        >
          +{step}
        </Button>
      </Box>

      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text fontSize={2} color="textWeaker">
          {translate('prioritisation.points_left', { pointsLeft })}
        </Text>
      </Box>
    </Box>
  );
};
