import { createComponent } from '@mentimeter/ragnar-react';
import React from 'react';
import type { IRenderer } from '@mentimeter/ragnar-react';
import { Box } from '@mentimeter/ragnar-ui/box';

const keyframes = () => ({
  '0%': {
    transform: 'translateY(0)',
  },
  '50%': {
    transform: 'translateY(8px)',
  },
  '100%': {
    transform: 'translateY(0)',
  },
});

const rule1 = (
  { duration = 1200, delay = 0 }: { duration?: number; delay?: number },
  renderer: IRenderer,
) => ({
  animation: `${renderer.renderKeyframe(
    keyframes,
    {},
  )} ${duration}ms ${delay}ms infinite`,
});

const rule2 = (
  { duration = 1200, delay = 300 }: { duration?: number; delay?: number },
  renderer: IRenderer,
) => ({
  animation: `${renderer.renderKeyframe(
    keyframes,
    {},
  )} ${duration}ms ${delay}ms infinite`,
});

const rule3 = ({ duration = 1200, delay = 600 }, renderer: IRenderer) => ({
  animation: `${renderer.renderKeyframe(
    keyframes,
    {},
  )} ${duration}ms ${delay}ms infinite`,
});
const Animation = createComponent(rule1, 'div');
const Animation2 = createComponent(rule2, 'div');
const Animation3 = createComponent(rule3, 'div');

export const Loader = () => {
  return (
    <Box flexDirection="row" mb={4}>
      <Animation>
        <Box width="6px" height="6px" bg="#196CFF" borderRadius={3} mr={2} />
      </Animation>
      <Animation2>
        <Box width="6px" height="6px" bg="#196CFF" borderRadius={3} mr={2} />
      </Animation2>
      <Animation3>
        <Box width="6px" height="6px" bg="#196CFF" borderRadius={3} mr={2} />
      </Animation3>
    </Box>
  );
};
