/* eslint-disable menti-react/filename-convention--jsx */
import type { QuestionChoice } from '@mentimeter/http-clients';
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import {
  ClientErrorCodes,
  GameStateEnums,
  useQuizVoterGameState,
  type VoterConfig,
} from '@mentimeter/quiz';
import { LoaderIcon } from '@mentimeter/ragnar-visuals';
import React from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Clickable } from '@mentimeter/ragnar-ui/clickable';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Countdown } from '../../ui/Components/Quiz/states/Countdown';
import { Error } from '../../ui/Components/Quiz/states/Error';
import { GetReady } from '../../ui/Components/Quiz/states/GetReady';
import { Lobby } from '../../ui/Components/Quiz/states/Lobby';
import { Results } from '../../ui/Components/Quiz/states/Results';
import { WaitForResults } from '../../ui/Components/Quiz/states/WaitForResults';

const VotingForm = ({
  choices,
  submitChoiceVote,
}: {
  choices: QuestionChoice[];
  submitChoiceVote: (id: number) => void;
}) => {
  const {
    ThemeStyled: { StringParser },
  } = useVotingContext();
  const [idVoted, setIdVoted] = React.useState<number | null>(null);

  return (
    <Box width="100%" mt="space8">
      {choices.map((c) => {
        const isSelected = idVoted === c.id;
        return (
          <Clickable
            minHeight="80px"
            bg={isSelected ? 'secondaryWeak' : 'bg'}
            mb="space4"
            type="submit"
            key={c.id}
            py="space4"
            px="space6"
            borderRadius="4xl"
            aria-label={c.label}
            width="100%"
            justifyContent="center"
            borderWidth={isSelected ? '2px' : '1px'}
            borderColor={isSelected ? 'secondary' : 'borderWeak'}
            borderStyle="solid"
            disabled={isSelected}
            onClick={() => {
              setIdVoted(c.id);
              submitChoiceVote(c.id);
            }}
          >
            <Text
              textAlign="left"
              fontWeight="semiBold"
              color={isSelected ? 'onSecondaryWeak' : 'text'}
            >
              <StringParser disableLineBreaks source={c.label} />
            </Text>
          </Clickable>
        );
      })}
    </Box>
  );
};

const App = ({ config }: { config: VoterConfig }) => {
  const { useQuestion, useTheme, useQuiz } = useVotingContext();
  const { totalCount, currentIndex } = useQuiz();
  const theme = useTheme();
  const {
    question,
    question_styled,
    question_description,
    choices,
    time_based_scoring,
    image,
  } = useQuestion();
  const {
    updatePlayer,
    submitChoiceVote,
    player,
    gameState,
    timeOffset = 0,
    getPlayerColor,
    connected,
  } = useQuizVoterGameState(config, 'quiz-choices');

  const playerColor = getPlayerColor(theme.fillColors);

  React.useLayoutEffect(() => {
    // A11y: Focus on heading when step is changed
    document.querySelector('h1')?.focus();
  }, [gameState?.gameState]);

  switch (gameState?.gameState) {
    case GameStateEnums.LOBBY: {
      return (
        <Lobby
          connected={Boolean(connected)}
          current={currentIndex + 1}
          total={totalCount}
          player={player}
          playerColor={playerColor}
          updatePlayer={updatePlayer}
          timeBasedScoring={time_based_scoring}
        />
      );
    }
    case GameStateEnums.GET_READY: {
      return <GetReady timeBasedScoring={time_based_scoring} />;
    }
    case GameStateEnums.COUNTDOWN: {
      return (
        <Countdown
          timeBasedScoring={time_based_scoring}
          question={question}
          questionStyled={question_styled}
          description={question_description}
          imageUrl={image?.presets.medium.url ?? undefined}
          timeOffset={timeOffset}
          startAt={gameState.gameStateData.startAt}
          endAt={gameState.gameStateData.endAt}
          playerColor={playerColor}
        >
          <VotingForm choices={choices} submitChoiceVote={submitChoiceVote} />
        </Countdown>
      );
    }
    case GameStateEnums.QUESTION: {
      return (
        <WaitForResults
          playerColor={playerColor}
          elapsedTime={gameState.gameStateData.elapsedTime}
          player={player}
        />
      );
    }
    case GameStateEnums.RESULT: {
      return (
        <Results
          playerColor={playerColor}
          player={player}
          result={
            gameState.gameStateData.score?.question?.correctAnswer ?? null
          }
        />
      );
    }
    case GameStateEnums.ERROR: {
      return <Error code={gameState.errorCode} />;
    }
    default: {
      return (
        <Box
          alignItems="center"
          justifyContent="center"
          data-testid="quiz-loading"
        >
          <Error preLoading code={ClientErrorCodes.NO_CONNECTION} />
        </Box>
      );
    }
  }
};

export function Content() {
  return null;
}

export function Interactive() {
  const { useQuestion, usePresentation, getIdentifier } = useVotingContext();
  const { voteKey } = usePresentation();
  const { public_key } = useQuestion();
  const identifier = getIdentifier();

  const config = React.useMemo(() => {
    return {
      voteKey,
      questionPublicKey: public_key,
      identifier,
    };
  }, [identifier, public_key, voteKey]);

  const content = React.useMemo(() => {
    if (!config.identifier || !config.questionPublicKey || !config.identifier) {
      return <LoaderIcon />;
    }
    return <App config={config} />;
  }, [config]);

  return (
    <Box minHeight="40vh" width="100%" alignItems="center">
      {content}
    </Box>
  );
}
