import { useVotingContext } from '@mentimeter/question-modules-contexts';
import type { Player } from '@mentimeter/quiz';
import { LoaderIcon } from '@mentimeter/ragnar-visuals';
import React from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Avatar } from '../ui/Avatar';
import { PlayerForm } from '../ui/PlayerForm';
import { ScaleIn } from '../ui/Transitions';
import { AnnouncedHeading } from '../ui/AnnouncedHeading';

export const Lobby = ({
  player,
  playerColor,
  updatePlayer,
  timeBasedScoring,
  current,
  total,
  connected,
}: {
  current: number;
  total: number;
  player: Player | undefined;
  playerColor: string;
  updatePlayer: (name: string) => void;
  timeBasedScoring: boolean;
  connected: boolean;
}) => {
  const { useTranslate } = useVotingContext();
  const translate = useTranslate();

  return (
    <>
      {!connected && <Text mb={3}>Connection lost...</Text>}
      {player ? (
        <>
          <ScaleIn>
            <Box my="space4">
              <Avatar
                color={playerColor}
                name={player.name || player.emojiName}
                emojiShortName={player.emojiShortname}
              />
            </Box>
          </ScaleIn>
          {player.name ? (
            <>
              <Text textAlign="center" color="textWeaker" mt="space8">
                {translate('state.question_of', {
                  quizQuestionPosition: current,
                  numOfQuizQuestions: total,
                })}
              </Text>
              <AnnouncedHeading
                mt="space2"
                textAlign="center"
                fontSize="175"
                color="text"
                fontWeight="regular"
              >
                {translate('state.get_ready_play', { name: player.name })}
              </AnnouncedHeading>
              <Text textAlign="center" my="space3" color="text">
                {translate(
                  timeBasedScoring
                    ? 'state.answer_fast'
                    : 'state.max_points_correct_answer',
                )}
              </Text>
            </>
          ) : (
            <PlayerForm
              defaultName={player?.name || ''}
              placeholder={player.emojiName}
              onSubmit={updatePlayer}
            />
          )}
        </>
      ) : (
        <LoaderIcon />
      )}
    </>
  );
};
