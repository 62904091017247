import * as React from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Button } from '@mentimeter/ragnar-ui/button';
import { SeriesLogo } from '../questions/SeriesLogo';
import { Footer, PageLayout } from '../../ui';
import { useTranslate } from '../localization/Translate';

interface WelcomeBackProps {
  name: string;
  onContinue: () => void;
}

export const WelcomeBack = ({ name, onContinue }: WelcomeBackProps) => {
  const translate = useTranslate();

  return (
    <PageLayout footer={<Footer context="Home" />}>
      <Box
        flex="1"
        width="100%"
        gap="space2"
        alignItems="center"
        m="auto"
        maxWidth={[null, '600px']}
      >
        <SeriesLogo />
        <Text fontSize="175" width="100%" color="text">
          {translate('identified_responses.welcome_back')}
        </Text>
        <Text fontSize="100">
          {translate('identified_responses.you_are_already_in_session', {
            name,
          })}
        </Text>
        <Button
          mt="space3"
          type="button"
          variant="primary"
          onClick={onContinue}
          size="large"
        >
          {translate('identified_responses.continue')}
        </Button>
      </Box>
    </PageLayout>
  );
};
