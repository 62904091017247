import React from 'react';
import { MoveDotsIcon } from '@mentimeter/ragnar-visuals';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Clickable } from '@mentimeter/ragnar-ui/clickable';
import type { RankableChoice } from './types';

interface DropItemT {
  choice: RankableChoice;
  isDragging?: boolean;
  sendToRanked?: (choice: RankableChoice) => void;
  ariaLabel?: string | undefined;
}

export const DropItem = ({
  choice,
  isDragging = false,
  sendToRanked,
  ariaLabel,
}: DropItemT) => {
  return (
    <Clickable
      bg="bg"
      onClick={sendToRanked ? () => sendToRanked(choice) : undefined}
      width="100%"
      minHeight="5rem"
      borderRadius="4xl"
      alignItems="center"
      py="space4"
      px="space6"
      height="100%"
      flexDirection="row"
      justifyContent="space-between"
      aria-label={ariaLabel}
      extend={({ theme }) => ({
        outline: isDragging ? `2px solid ${theme.colors.secondary}` : 'none',
      })}
    >
      <Box flexDirection="row" flex={1}>
        <Text color="text" style={{ userSelect: 'none' }} textAlign="left">
          {choice.label}
        </Text>
      </Box>

      <Box width="80px" alignItems="flex-end">
        {isDragging && <MoveDotsIcon size={2} />}
      </Box>
    </Clickable>
  );
};
