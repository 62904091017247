import type { VotingSeries } from '@mentimeter/http-clients';
import type { SlideDeck as VotingSlideDeck } from '@mentimeter/voting-schema/api-types-overrides';
import { convertKeysToSnakeCase } from '../../../utils/convert-keys-to-snake-case';
import {
  liveChatSettingsToSeriesCfaProperties,
  participationIdentityModeSettingsToSeriesVoteAgainEnabled,
  participationModeToSeriesPaceMode,
  participationSettingsToSeriesParticipationIdentityMode,
  qaSettingsToSeriesQaProperties,
} from '../../../backward-compatibility/converters/slide-deck';
import { getOrSetCache, type WithCache } from '../../../utils/get-or-set-cache';

export const votingSlideDeckPropertyToSeriesProperty = (
  target: VotingSlideDeck,
  prop: keyof VotingSeries,
) => {
  /**
   * Ignore invalid properties potentially produced by SWR
   * and/or our own Proxy implementation.
   */
  if ((prop as string) === 'then') {
    return undefined;
  }

  // As support for slide deck properties become available, they will appear here.
  switch (prop) {
    case 'comments_enabled':
      return liveChatSettingsToSeriesCfaProperties(target.liveChatSettings)
        .comments_enabled;

    case 'language':
      return target.languageSettings?.presentationLanguage;

    case 'name':
      return target.name;

    case 'pace': {
      return {
        mode: participationModeToSeriesPaceMode(target.participationSettings),
      };
    }

    case 'participation_identity_mode':
      return participationSettingsToSeriesParticipationIdentityMode(
        target.participationSettings,
      );

    case 'qfa_active':
      return qaSettingsToSeriesQaProperties(target.qaSettings).qfa_active;

    case 'qfa_intercom_enabled':
      return qaSettingsToSeriesQaProperties(target.qaSettings)
        .qfa_intercom_enabled;

    case 'qfa_moderation_enabled':
      return qaSettingsToSeriesQaProperties(target.qaSettings)
        .qfa_moderation_enabled;

    case 'results_sharing':
      return target.ownershipSettings?.resultsSharing;

    case 'reactions':
      return target.reactionSettings?.reactionsAllowed;

    case 'theme': {
      return getOrSetCache(
        target as WithCache<VotingSlideDeck>,
        'theme',
        JSON.stringify(target.legacyThemeSettings),
        convertKeysToSnakeCase(target.legacyThemeSettings),
      );
    }

    case 'theme_id':
      return target.themeId;

    case 'vote_again_enabled':
      return participationIdentityModeSettingsToSeriesVoteAgainEnabled(
        target.participationSettings,
      );

    case 'vote_key':
      return target.participationSettings?.participationKey;

    default:
      // @ts-expect-error [MX]: TODO: decide on how to handle default case
      return target[prop];
  }
};
