import { useRef, useEffect, type PropsWithChildren } from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';

export const InteractionObserver = ({
  children,
  onInteraction,
}: PropsWithChildren<{ onInteraction: () => void }>) => {
  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const element = elementRef.current;
    if (!element) return;

    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab') {
        onInteraction();
      }
    };

    element.addEventListener('mousedown', onInteraction);
    element.addEventListener('keydown', onKeyDown);

    return () => {
      element.removeEventListener('mousedown', onInteraction);
      element.removeEventListener('keydown', onKeyDown);
    };
  }, [onInteraction]);

  return (
    <Box width="100%" ref={elementRef}>
      {children}
    </Box>
  );
};
