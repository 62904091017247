import * as React from 'react';
import { useRagnar } from '@mentimeter/ragnar-react';
import { alpha } from '@mentimeter/ragnar-colors';
import { useTimeout } from '@mentimeter/react-hooks';
import { Button } from '@mentimeter/ragnar-ui/button';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';

export const ChangeSlideNotification = ({
  onClick,
}: {
  onClick: () => void;
}) => {
  const { theme } = useRagnar();
  const [isVisible, setIsVisible] = React.useState(false);

  useTimeout(() => setIsVisible(true), isVisible ? 0 : 1);

  return (
    <Box
      bg={theme.colors.secondaryWeak}
      zIndex={theme.zIndex.notification}
      p="space5"
      flexDirection="row"
      justifyContent="center"
      borderRadius="xl"
      extend={({ theme }) => ({
        boxShadow: `0 10px 20px 5px ${alpha(theme.colors.primary, 0.15)}`,
        transform: isVisible ? 'translateY(0)' : 'translateY(-100%)',
        transition: 'transform 0.5s ease',
        opacity: isVisible ? 1 : 0,
      })}
      position="fixed"
      borderWidth={1}
      borderColor={theme.colors.borderSecondaryWeakest}
      borderStyle="solid"
      mt="space2"
    >
      <Box flexDirection="row" alignItems="center" gap="space4">
        <Text>Facilitator changed slide</Text>
        <Button p="space0" variant="subtle" onClick={onClick}>
          Skip ahead
        </Button>
      </Box>
    </Box>
  );
};
