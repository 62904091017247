import React from 'react';
import { TrailList } from '@mentimeter/voting-ui';
import type { TranslationFn } from '@mentimeter/question-modules-types';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Box } from '@mentimeter/ragnar-ui/box';
import { useExpandableGroups } from '../useExpandableGroups';
import type { GroupWithShape } from '../types';
import { ResponsesGroup } from '../ResponsesGroup';
import { ResponseWithResult } from './ResponseWithResult';

interface ResultListProps {
  groups: GroupWithShape[];
  translate: TranslationFn;
}

export const GroupedResultsView = ({ groups, translate }: ResultListProps) => {
  const { handleGroupClick, getIsExpanded } = useExpandableGroups();

  const groupsWithoutUpvotes = groups.filter(
    (group) => group.total_upvotes === 0,
  );

  const groupsWithUpvotes = groups.filter((group) => group.total_upvotes > 0);

  const maxVoteCount = Math.max(...groups.map((group) => group.total_upvotes));

  return (
    <Box alignItems="stretch" width="100%">
      <Text color="text" fontSize="125" fontWeight="regular" my="space4">
        {translate('open_ended.groups_with_votes_label')}
      </Text>
      <TrailList
        items={groupsWithUpvotes}
        renderItem={(group) => {
          const tagLabel =
            group.total_upvotes === maxVoteCount
              ? translate('open_ended.top_voted')
              : undefined;
          return (
            <ResponsesGroup
              key={group.id}
              group={group}
              tagLabel={tagLabel}
              groupShape={group.shape}
              isExpanded={getIsExpanded(group.id)}
              totalUpvotes={group.total_upvotes}
              handleGroupClick={handleGroupClick}
              // eslint-disable-next-line react/no-unstable-nested-components -- Do not define components during render.
              responseRenderer={(response) => (
                <ResponseWithResult
                  id={response.id}
                  text={response.text}
                  nrOfUpvotes={response.upvotes}
                  mb="space2"
                />
              )}
              translate={translate}
            />
          );
        }}
      />
      <Text color="text" fontSize="125" fontWeight="regular" my="space4">
        {translate('open_ended.groups_without_votes_label')}
      </Text>
      <TrailList
        items={groupsWithoutUpvotes}
        animationDelay={600}
        renderItem={(group) => {
          return (
            <ResponsesGroup
              key={group.id}
              group={group}
              tagLabel={undefined}
              groupShape={group.shape}
              isExpanded={getIsExpanded(group.id)}
              totalUpvotes={group.total_upvotes}
              handleGroupClick={handleGroupClick}
              // eslint-disable-next-line react/no-unstable-nested-components -- Do not define components during render.
              responseRenderer={(response) => (
                <ResponseWithResult
                  id={response.id}
                  text={response.text}
                  nrOfUpvotes={response.upvotes}
                  mb="space2"
                />
              )}
              translate={translate}
            />
          );
        }}
      />
    </Box>
  );
};
