'use client';

import { Provider as ReduxProvider } from 'react-redux';
import React from 'react';
import { getRegionByVoteKey } from '@mentimeter/region';
import { setClientFallbackRegion } from '@mentimeter/http-clients';
import { createStore } from '../../redux-store';
import { identifierClient } from '../../utils/identifier';
import type { SeriesOrSlideDeck } from '../../types';

export function VotingWrapper({
  seriesOrSlideDeck,
  voteKey,
  children,
}: {
  seriesOrSlideDeck: SeriesOrSlideDeck;
  voteKey: string;
  children: React.ReactNode;
}) {
  const store = React.useMemo(() => {
    const newStore = createStore({ series: seriesOrSlideDeck });
    return newStore;
  }, [seriesOrSlideDeck]);

  React.useEffect(() => {
    const region = getRegionByVoteKey(voteKey);
    setClientFallbackRegion('voting', region);
    identifierClient.setRegion(region);
  }, [voteKey]);

  return (
    <ReduxProvider store={store} key="provider">
      {children}
    </ReduxProvider>
  );
}
