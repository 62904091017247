import { useState } from 'react';
import type { QuestionField } from '@mentimeter/http-clients';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Form } from '@mentimeter/ragnar-ui/form';
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import { VotingConfirmationModal } from '@mentimeter/voting-ui';
import type { QuestionWithSlide } from '@mentimeter/voting-schema/api-types-overrides';
import type { SubmitVoteBody } from '@mentimeter/question-modules-types';
import { SubmitVoteFormButton } from '../../ui/Components/SubmitVoteFormButton';
import {
  QuickFormCheckboxField,
  QuickFormDateField,
  QuickFormDropdownField,
  QuickFormTextField,
} from './QuickFormField';

const CHECKBOX_FIELD_TYPE = 'checkbox';
const DATE_FIELD_TYPE = 'date';

/**
 * either an input value or a label of an option
 */
export type QuickFormValue = string;

type QuickFormValues = Record<QuestionField['id'], Array<QuickFormValue>>;

function getToday() {
  const isoToday = new Date().toISOString();
  return isoToday.substring(0, isoToday.indexOf('T'));
}

function getInitialState(fields: Array<QuestionField>) {
  const values: QuickFormValues = {};
  fields.forEach(({ id, type }) => {
    if (type === CHECKBOX_FIELD_TYPE) {
      values[id] = [];
    } else if (type === DATE_FIELD_TYPE) {
      values[id] = [getToday()];
    } else {
      values[id] = [''];
    }
  });
  return values;
}

function createSubmitVoteBody(
  question: QuestionWithSlide,
  rawVotes: QuickFormValues,
): SubmitVoteBody {
  if (question.isMigrated) {
    const interactiveContentChoiceIds = Object.entries(rawVotes).map(
      ([rawVoteChoiceId, value]) => {
        const choice = question.interactiveContents[0]!.choices.find(
          (c) =>
            c.legacyQuestionMetadataFieldId?.toString() === rawVoteChoiceId,
        );
        return {
          interactiveContentChoiceId: choice!.interactiveContentChoiceId,
          value,
        };
      },
    );

    return {
      isMigrated: true,
      slidePublicKey: question.slidePublicKey,
      interactiveContentId:
        question.interactiveContents[0]!.interactiveContentId,
      payload: { type: 'quick-form', choices: interactiveContentChoiceIds },
      partial: false,
    };
  }

  const vote = question.fields.map((field) => {
    const { id: fieldId } = field;
    return { field_id: fieldId, value: rawVotes[fieldId] ?? [] };
  });

  return {
    isMigrated: false,
    questionPublicKey: question.public_key,
    payload: { type: 'metadata', vote },
    partial: false,
  };
}

export function Interactive() {
  const { useQuestion, useActions, useTranslate } = useVotingContext();
  const { vote: submitVote } = useActions();
  const translate = useTranslate();

  const question = useQuestion();
  const { fields } = question;

  const [values, setValues] = useState<QuickFormValues>(
    getInitialState(fields),
  );
  const [showModal, setShowModal] = useState(false);

  const handleSetValue = (id: number, value: string) => {
    setValues((prevValues) => {
      return { ...prevValues, [id]: [value] };
    });
  };

  const handleSelectOption = (id: number, option: string) => {
    setValues((prevValues) => {
      return { ...prevValues, [id]: [option] };
    });
  };

  const handleToggleCheckbox = (
    id: number,
    option: string,
    checked: boolean,
  ) => {
    setValues((prevValues) => {
      const prevSelectedOptions = prevValues[id] || [];
      if (checked) {
        return { ...prevValues, [id]: [...prevSelectedOptions, option] };
      }
      return {
        ...prevValues,
        [id]: prevSelectedOptions.filter((value) => value !== option),
      };
    });
  };

  const validate = () => {
    return fields.every((field) => {
      if (field.is_required) {
        const value = values[field.id];
        if (Array.isArray(value)) {
          if (field.type === 'dropdown') {
            return value[0] !== '';
          }
          return value.length > 0;
        }
        return Boolean(value);
      }
      return true;
    });
  };

  const handleSubmit = () => {
    if (!validate()) {
      setShowModal(true);
      return;
    }
    submitVote(createSubmitVoteBody(question, values));
  };

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      width="100%"
    >
      <Box gap="space6" width="100%">
        {fields.map(({ id, type, is_required, label, options }) => {
          const value = values[id] || [];

          switch (type) {
            case 'text':
            case 'email':
              return (
                <QuickFormTextField
                  key={id}
                  fieldId={id}
                  required={is_required}
                  label={label}
                  type={type}
                  value={value[0]}
                  onChange={handleSetValue}
                />
              );
            case 'date':
              return (
                <QuickFormDateField
                  key={id}
                  fieldId={id}
                  required={is_required}
                  label={label}
                  value={value[0]}
                  onChange={handleSetValue}
                />
              );
            case 'dropdown':
              return (
                <QuickFormDropdownField
                  key={id}
                  fieldId={id}
                  required={is_required}
                  label={label}
                  options={options}
                  onChange={handleSelectOption}
                  translate={translate}
                />
              );
            case 'checkbox':
              return (
                <QuickFormCheckboxField
                  key={id}
                  fieldId={id}
                  required={is_required}
                  label={label}
                  optionLabels={options}
                  selectedOptionLabels={value}
                  onChange={handleToggleCheckbox}
                />
              );
            default:
              return null;
          }
        })}
      </Box>
      <SubmitVoteFormButton />
      <VotingConfirmationModal
        id="skip-vote-modal-metadata"
        showModal={showModal}
        onConfirm={() => setShowModal(false)}
        onDismiss={() => setShowModal(false)}
        title={translate('buttons.retry')}
        bodyText={translate('messages.required_fields')}
        confirmButtonText={translate('buttons.ok')}
        dismissButtonText={translate('buttons.cancel')}
      />
    </Form>
  );
}
