/* eslint-disable menti-react/filename-convention--jsx */
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import React from 'react';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Box } from '@mentimeter/ragnar-ui/box';
import { TitleSectionWithImage } from '../../ui/Components/VotingTitle';
import { Miro } from './Miro';

export const getBoardIdFromUrl = (
  url: string | null | undefined,
): null | string => {
  if (!url) return null;
  const pattern =
    /^(?:https:\/\/)?(?:www\.)?miro\.com\/(app)\/(board)\/([0-9a-zA-Z_\-=]{12})(?:\/?([^?]+)?(.*))?$/;
  const matched = url.match(pattern);
  if (!matched) return null;
  return matched[3] || null;
};

export function Content() {
  const { useQuestion } = useVotingContext();

  const question = useQuestion();
  const miroUrl = question.module_custom_data?.miro_url;
  const audienceBoard = question.module_custom_data?.audience_board || false;

  const boardId = getBoardIdFromUrl(miroUrl);
  return (
    <Box width="100%">
      <TitleSectionWithImage
        meta={question?.title_meta}
        title={question?.question}
        description={question?.question_description}
      />
      {audienceBoard && (
        <Box width="100%" mb="space6">
          <Box width="100%" mb="space2" display={[undefined, 'none']}>
            <Text as="p" color="textWeaker" lineHeight="relaxed" mb="space2">
              We recommend using a device with a larger screen, such as a
              desktop or tablet.
            </Text>
          </Box>
          <Box width="100%" height={400}>
            <Miro boardId={boardId} />
          </Box>
        </Box>
      )}
    </Box>
  );
}
